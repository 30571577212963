import { Component, Input, OnInit } from '@angular/core';
import { AdnSetting, ComplianceMetrics } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calculateBonus, 
         calculateDivisionInput, 
         division, 
         formatNumber, 
         formatPercentageV2, 
         truncateToTwoDecimals } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-bonus-card',
  templateUrl: './bonus-card.component.html',
  styleUrls: ['./bonus-card.component.scss']
})
export class BonusCardComponent implements OnInit {
  @Input() title!: string;
  @Input() amount!: number;
  advance: number  = 0;
  @Input() amount20k: number = 0;
  @Input() meta20k: number = 0;
  meta20kString: string = '0';
  amount20kString: string = '0';
  iconBonus:string = '';
  increase: string ='';
  valueString: string = '0';
  amountBonus: number = 0;
  ponderadoCobranza: number = 0;
  ponderadoComercial: number = 0;
  setting!: AdnSetting;

  meta20kNumber: number = 0;
  amount20kNumber: number = 0;
  constructor(private adnSimulatorService:AdnSimulatorService) { 
    this.setting = this.adnSimulatorService.getSetting();
    this.adnSimulatorService.metrics$.subscribe((data) => {
      this.ponderadoCobranza =(this.calculateWeightCobranza(data)/40)*100;;
      this.ponderadoComercial =((this.calculateWeightComercial(data)/60)*100);
      const compliance = (this.ponderadoComercial>this.setting.salesRate && this.ponderadoCobranza>this.setting.collectionRate)?1:0;
      const avance = this.advance>=90?1:0;
      const divisionTemp = division(this.amount20k,this.meta20k)*100;
      const bonus = calculateBonus(compliance,avance,divisionTemp);
      this.valueString = truncateToTwoDecimals(bonus);
    });
  }

  ngOnInit(): void {
    this.valueString = formatPercentageV2(this.amount);
    this.meta20kString = formatNumber(this.meta20k.toString());
    this.amount20kString = formatNumber(this.amount20kString.toString());
    this.getBonus();
  }

  getBonus() {
    this.adnSimulatorService.amount20kSubject$.subscribe((data) => {
      this.amount20k = data;
      this.amount20kString = formatNumber(data.toString());
      this.amount20kNumber = data;
      this.advance =  calculateDivisionInput(data,this.meta20k);
      this.iconBonus= this.advance>=90?'/assets/icons/adn/check-circle.svg':'/assets/icons/adn/x-close.svg';
      const compliance = (this.ponderadoComercial>this.setting.salesRate && this.ponderadoCobranza>this.setting.collectionRate)?1:0;
      const avance = this.advance>=90?1:0;
      const divisionTemp = division(this.amount20k,this.meta20k)*100;
      const bonus = calculateBonus(compliance,avance,divisionTemp);
      this.valueString = truncateToTwoDecimals(bonus);
    });
  }

  calculateWeightComercial(metrics: ComplianceMetrics): number {
    return (
      metrics.complianceNetDisbursement.compliance * metrics.complianceNetDisbursement.weight +
      metrics.complianceActiveClientStock.compliance * metrics.complianceActiveClientStock.weight +
      metrics.complianceLiabilitiesCapture.compliance * metrics.complianceLiabilitiesCapture.weight +
      metrics.compliancePriceManagement.compliance * metrics.compliancePriceManagement.weight
    );
  }

  calculateWeightCobranza(metrics: ComplianceMetrics): number {
    return (
      metrics.compliancePise9Mora.compliance * metrics.compliancePise9Mora.weight +
      metrics.complianceResolution0Days.compliance * metrics.complianceResolution0Days.weight
    );
  }
}