import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalSummaryComponent } from '../modal-summary/modal-summary.component';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { Subscription } from 'rxjs';
import { calculateBonus, 
         calculateDivisionInput, 
         division, 
         formatNumberWithCommasNoDecimals,
         convertToNumeric,
         formatPercentageV2,
         obtenerRango,
         calcularRvFronts,
         calculateDivision,
         denominatorDecelerator,
         calculateMultiplier} from '@shared/utils/adn-simulator.utils';
import { AdnSetting, ComplianceMetrics, DeceleratorsData } from '@shared/interfaces/frontend/adn';
import { INCREASE, PayoffCurveCollection, PayoffCurveCommercial, RV_DATALAYER } from '@shared/constants/adn-simulator.constants';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'milab-summary-simulator-card',
  templateUrl: './summary-simulator-card.component.html',
  styleUrls: ['./summary-simulator-card.component.scss']
})
export class SummarySimulatorCardComponent implements OnInit {
  @Input() title!: string;
  @Input() description!: string;
  @Input() amountDistributed: number=0;

  modalRef!: NgbModalRef;
  directRemunerationValue: number = 0;
  @Input() acceleratorPercentage: number = 0;
  @Input() deceleratorPercentage: number= 0;
  finalRv: string = '0';
  finalRvActual: number = 0;
  finalTotal: number = 0;
  bonus20k: number = 0;
  amount20k: number = 0;

  @Input() meta20k: number = 0;
  @Input() factorAucencia: number = 0;
  factorAucenciaString: string = '0';

  increaseSummary: string ='';
  summaryColor: string = INCREASE.colorIncrement;
  iconSummary: string =INCREASE.iconArrowUp;
  
  @Input() finalRvInitial: number = 0;
  ponderadoComercialTemp: number = 0;
  ponderadoCobranzaTemp: number = 0;

  advanceTemp: number = 0;
  valueString: string = '0';
  rvCobranza: number = 0;
  rvComercial: number = 0;
  porcentageComercial: number = 0;
  porcentageCobranza: number = 0;
  dataDecelerators!: DeceleratorsData;  
  collectionMultiplier: number = 0;
  setting!: AdnSetting;
  constructor(private modalService: NgbModal,
              private adnSimulatorService:AdnSimulatorService,
              private dataLayerService: DataLayerService) {
                this.setting = this.adnSimulatorService.getSetting();
                this.adnSimulatorService.metrics$.subscribe((value) => {
                  this.ponderadoComercialTemp = ((this.calculateWeightComercial(value)/60)*100);;
                  this.ponderadoCobranzaTemp = (this.calculateWeightCobranza(value)/40)*100;  ;         
                  this.updateDirectRemunerationValue(this.ponderadoComercialTemp,this.ponderadoCobranzaTemp);
                });
              }

  private subscription: Subscription = new Subscription();
  ngOnInit(): void {
    this. getBonus();
    this.dataDecelerators = this.adnSimulatorService.getDataDecelerators();
    this.getAchievementPassBalance();
    this.factorAucenciaString= (this.factorAucencia*100).toFixed(0);
  }

  private updateDirectRemunerationValue(ponderadoComercial:number,ponderadoCobranza:number): void {
      const comercialWeight = 0.6;
      const collectionWeight = 0.4;
      this.rvComercial = this.calculateRvComercial(ponderadoComercial, ponderadoCobranza,this.amountDistributed, comercialWeight, this.setting.salesRate);
      this.rvCobranza =this.calculateRvCobranza(ponderadoComercial, ponderadoCobranza,this.amountDistributed, collectionWeight, this.setting.collectionRate);
      this.bonus20k = this.calculateBonus20k(this.amount20k, this.meta20k);

      this.finalRvActual = ((this.rvComercial+this.rvCobranza)*this.factorAucencia);
      const calculeFinal = (this.finalRvActual+this.bonus20k);
      this.finalTotal = calculeFinal;
      this.finalTotal = parseFloat(formatPercentageV2(calculeFinal));
      this.calculeIncremented(this.finalTotal);
    }

    calculateWeightComercial(metrics: ComplianceMetrics): number {
      return (metrics.complianceNetDisbursement.compliance * metrics.complianceNetDisbursement.weight +
              metrics.complianceActiveClientStock.compliance * metrics.complianceActiveClientStock.weight +
              metrics.complianceLiabilitiesCapture.compliance * metrics.complianceLiabilitiesCapture.weight +
              metrics.compliancePriceManagement.compliance * metrics.compliancePriceManagement.weight);
    }
  
    calculateWeightCobranza(metrics: ComplianceMetrics): number {
      return (metrics.compliancePise9Mora.compliance * metrics.compliancePise9Mora.weight +
              metrics.complianceResolution0Days.compliance * metrics.complianceResolution0Days.weight);
    }

  openModal() {
    this.dataLayerService.simulatorRVSelect(RV_DATALAYER.actionPrincipal,RV_DATALAYER.selectOptionName+RV_DATALAYER.eventSummary);
    this.modalRef = this.modalService.open(ModalSummaryComponent, { windowClass: 'modal-summary' });
    this.modalRef.componentInstance.directVariableAmount = this.directRemunerationValue;
    this.modalRef.componentInstance.acceleratorPercentage = this.acceleratorPercentage;
    this.modalRef.componentInstance.deceleratorPercentage = this.deceleratorPercentage;
    this.modalRef.componentInstance.finalRv = this.finalRvActual;
    this.modalRef.componentInstance.finalTotal = this.finalTotal;
    this.modalRef.componentInstance.bonus20k = this.bonus20k;
    this.modalRef.componentInstance.ponderadoComercial = this.ponderadoComercialTemp;
    this.modalRef.componentInstance.ponderadoCobranza = this.ponderadoCobranzaTemp;
    this.modalRef.componentInstance.amountDistributed = this.amountDistributed;
    this.modalRef.componentInstance.rvCobranza = this.rvCobranza;
    this.modalRef.componentInstance.rvComercial = this.rvComercial;
    this.modalRef.componentInstance.factorAucencia = this.factorAucenciaString;
    this.modalRef.componentInstance.setting = this.setting;

  }

  calculeIncremented(finalValue: number): void {
    this.finalTotal = finalValue;
    const increaseTemp = (finalValue - this.finalRvInitial);
    const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
    if (increaseTemp > 0) {
      this.increaseSummary = 'S/ '+formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.summaryColor = INCREASE.colorIncrement;
      this.iconSummary = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseSummary = 'S/ '+formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.summaryColor = INCREASE.colorDecrement;
      this.iconSummary = INCREASE.iconArrowDown;
    } else {
      this.increaseSummary = '';
    }
  }

  getBonus() {
    this.adnSimulatorService.amount20kSubject$.subscribe((data) => {
      this.amount20k = data;
      this.bonus20k = this.calculateBonus20k(data, this.meta20k);
      const calculatefinal = (this.finalRvActual + this.bonus20k);
      this.calculeIncremented(parseFloat(formatPercentageV2(calculatefinal)));
    });
  }

  private calculateBonus20k(amount20k: number, meta20k: number): number {
    const advanceTemp = calculateDivisionInput(amount20k, meta20k);
    const compliance = (this.ponderadoComercialTemp>this.setting.salesRate && this.ponderadoCobranzaTemp>this.setting.collectionRate)?1:0;
    const avance = advanceTemp >= 90 ? 1 : 0;
    const divisionTemp = division(amount20k, meta20k) * 100;
    return calculateBonus(compliance, avance, divisionTemp);
  }
  

    private calculateRvComercial(ponderadoComercial: number, ponderadoCobranza: number, amountDistributed: number, weight: number, threshold: number): number {
      if (ponderadoComercial > threshold && ponderadoCobranza > threshold) {
        const rangeComercial = obtenerRango(ponderadoComercial, PayoffCurveCommercial);
        if (rangeComercial) {
          const porcentageComercial = calcularRvFronts(ponderadoComercial, rangeComercial);
          this.porcentageComercial = porcentageComercial;
          return convertToNumeric(formatPercentageV2((porcentageComercial / 100) * weight * amountDistributed));
        }
      }
      return 0;
    }
  
    private calculateRvCobranza(ponderadoComercial: number, ponderadoCobranza: number, amountDistributed: number, commercialWeight: number, threshold: number): number {
      if (ponderadoComercial > threshold && ponderadoCobranza > threshold) {
        const rangeCobranza = obtenerRango(ponderadoCobranza, PayoffCurveCollection);
        if (rangeCobranza) {
          const porcentageCobranza = calcularRvFronts(ponderadoCobranza, rangeCobranza);
          this.porcentageCobranza = porcentageCobranza;
          return convertToNumeric(formatPercentageV2((porcentageCobranza/100) * commercialWeight * amountDistributed*(this.collectionMultiplier/100)));
        }
      }
      return 0;
    }
    
    getAchievementPassBalance() {
        this.adnSimulatorService.achievementPassBalanceSubject$.subscribe((achievement) => {
          const passMonth1  = division(achievement,this.dataDecelerators.metaPaseRec);
          const passSummary = (this.dataDecelerators.passMonth2+this.dataDecelerators.passMonth3 +passMonth1);
          const baseAverage = calculateDivision(passSummary,denominatorDecelerator(this.dataDecelerators.passMonth2,this.dataDecelerators.passMonth3))*100;
          this.collectionMultiplier = calculateMultiplier(baseAverage);     
          const comercialWeight = 0.6;
          const collectionWeight = 0.4;
          this.rvComercial = this.calculateRvComercial(this.ponderadoComercialTemp, this.ponderadoCobranzaTemp,this.amountDistributed, comercialWeight, this.setting.salesRate);
          this.rvCobranza =this.calculateRvCobranza( this.ponderadoComercialTemp, this.ponderadoCobranzaTemp,this.amountDistributed, collectionWeight, this.setting.collectionRate);
          this.finalRvActual = ((this.rvComercial+this.rvCobranza)*this.factorAucencia);
          const calculeFinal = (this.finalRvActual+this.bonus20k);
          this.finalTotal = parseFloat(formatPercentageV2(calculeFinal));
          this.calculeIncremented(this.finalTotal);
        });
      }
}
