import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalInformativeComponent } from '../modal-informative/modal-informative.component';
import { blurInformation } from '@shared/data/adn/modal.data.json';
import { listBlurData } from '@shared/data/adn/blur.data.json';
import { AdnModal, BlurData } from '@shared/interfaces/frontend/adn';
import { AdnUserProfile } from '@shared/models/UserProfileModel';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';

@Component({
  selector: 'milab-section-blur',
  templateUrl: './section-blur.component.html',
  styleUrls: ['./section-blur.component.scss']
})
export class SectionBlurComponent implements OnInit {
  @Input() listBlur: BlurData[]  = [];

  modalRef!: NgbModalRef;
  isMobile: boolean = window.innerWidth < 768;

  blurInformation!: AdnModal;
  activeVariableId: number = 0;

  LIST_BLUR = listBlurData;
  NOT_LIST_BLUR = [];
  user!: AdnUserProfile;
  loader: boolean = false;
  constructor(private modalService: NgbModal,private localStorageService: LocalStorageService, private adnSimulatorService: AdnSimulatorService) { }

  ngOnInit(): void {
    this.blurInformation = blurInformation;
    this.onResize();
    this.validUser();
  }

  validUser(){
        const adnUserProfile = this.localStorageService.get('adnUserProfile');
        if (adnUserProfile) {
          this.user = JSON.parse(adnUserProfile) as AdnUserProfile;
          this.adnSimulatorService.getBlur(parseInt(this.user.codeAdn)).subscribe({
            next: (response) => {
            this.listBlur = response.map(item => ({
              ...item, 
              dateDisbursement: this.formatDate(item.dateDisbursement)
            }));
            this.loader = true;
          },
          error: (err) => {
            this.loader = false;
          }
        });
        } 
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth < 768;
  }
  openModal() {
    this.modalRef = this.modalService.open(ModalInformativeComponent, { windowClass: 'modal-informative' });
    this.modalRef.componentInstance.modalItem = this.blurInformation;
    this.modalRef.result.then((on) => {
    }).catch((error) => {
      console.log(error);
    });
  }

  activeTable(id: number): void {
    this.activeVariableId = this.activeVariableId === id ? 0 : id;
  }

  isTableActive(id: number): boolean {
    return this.activeVariableId === id;
  }

  formatDate(dateString: string): string {
    if (!dateString) return 'No aplica'; 
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Fecha inválida'; 
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear(); 
  
    return `${day}/${month}/${year}`;
  }

}
