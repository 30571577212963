import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isPwaOrWeb } from '@shared/utils/general.utils';
import { combineLatest, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SEOService } from './seo.service';

interface IStoreEvent {
  id: number;
  name: string;
  deliveryMethods: string;
  categories: string;
  paymentMethods: string;
}

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  constructor(private seoService: SEOService, private router: Router, private activatedRoute: ActivatedRoute) {
    //DO NOT DELETE THIS COMMENT
  }

  initDataLayerConfig(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        mergeMap((event) => combineLatest([of(event), of(this.activatedRoute)])),
        map(([event, route]) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return {
            event,
            route,
          };
        }),
        filter(({ route }) => route.outlet === 'primary'),
        mergeMap(({ event, route }) => combineLatest([of(event), route.data]))
      )
      .subscribe(([event, data]) => {
        const { pageName } = data;
        const { urlAfterRedirects } = event as NavigationEnd;
        this.pushVirtualPage(urlAfterRedirects, pageName || urlAfterRedirects.substring(1));
      });
  }

  pushVirtualPage(url: string, title: string): void {
    this.pushDataLayer('virtualPage', {
      pagePath: `/yevo-mibanco${url}`,
      pageName: this.seoService.generateTitle(title),
      Plataforma: isPwaOrWeb(),
    });
  }

  pushRegisterEvent(event: any, position: string): void {
    const target = event.currentTarget as HTMLElement;
    this.pushVirtualEvent(
      `${target.getAttribute('data-layer-section')} - Registro`,
      `Seleccionar botón: ${target.innerText} - ${position}`
    );
  }

  pushRegisterAttempt(): void {
    this.pushVirtualEvent('Registro', 'Intención de Registro');
  }

  pushRegisterSuccess(): void {
    this.pushVirtualEvent('Registro', 'Registro Exitoso');
  }

  pushRegisterSuccessId(userID: number): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Registro',
      label: 'Registro Exitoso',
      userID: `${userID}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushLoginEvent(event: any): void {
    const target = event.currentTarget as HTMLElement;
    this.pushVirtualEvent(
      `${target.getAttribute('data-layer-section')} - Inicio de sesión`,
      `Seleccionar botón: ${target.innerText}`
    );
  }

  pushLoginAttempt(): void {
    this.pushVirtualEvent('Inicio de sesión', 'Intención de inicio');
  }

  pushLoginSuccess(): void {
    this.pushVirtualEvent('Inicio de sesión', 'Inicio exitoso');
  }

  pushStartSearchEvent(event: any): void {
    const target = event.currentTarget as HTMLElement;
    this.pushVirtualEvent('Directorio de negocios', `Seleccionar botón: ${target.innerText}`);
  }

  pushCategoryEvent(event: any): void {
    const target = event.currentTarget as HTMLElement;

    this.pushVirtualEvent('Directorio de negocios - Categorías', `Seleccionar: ${target.innerText}`);
  }

  pushSearchEvent(hasResults: boolean, search: string): void {
    this.pushVirtualEvent(
      'Directorio de negocios - Buscador',
      `${hasResults ? 'Con resultado' : 'Sin resultado'} - ${search}`
    );
  }

  pushDirectoryDetail(businessName: string): void {
    this.pushVirtualEvent('Directorio de negocios - Productos o servicios', `Seleccionar botón: ${businessName}`);
  }

  pushDirectoryGoToStoreEvent(event: any): void {
    const target = event.currentTarget as HTMLElement;
    this.pushVirtualEvent(
      `Directorio de negocios - ${target.getAttribute('data-layer-section')}`,
      `Seleccionar: ${target.innerText.trim()}`
    );
  }

  pushDirectorySocialNetworkEvent(event: any, businessName: string): void {
    const target = event.currentTarget as HTMLElement;
    this.pushVirtualEvent(
      `Directorio de negocios - ${businessName}`,
      `Redes Sociales - ${target.getAttribute('data-layer-section')}`
    );
  }

  pushGoToStore(event: any, businessName: string): void {
    const target = event.currentTarget as HTMLElement;
    this.pushVirtualEvent(
      `Directorio de negocios - ${businessName}`,
      `Seleccionar botón: - ${target.getAttribute('data-layer-section')}`
    );
  }

  pushBusinessOption(businessName: string, type: string, option: string): void {
    this.pushVirtualEvent('Cuenta personal - Directorio de negocios', `${businessName} - ${type}: ${option}`);
  }
  pushBusisnesPublishIntent(): void {
    this.pushVirtualEvent('Cuenta personal - Directorio de negocios', `Intención de publicación`);
  }

  pushBusisnesPublishSuccess(businessName: string): void {
    this.pushVirtualEvent('Cuenta personal - Directorio de negocios', `Publicación exitosa - ${businessName}`);
  }

  pushTrainingBanner(bannerName: string): void {
    this.pushVirtualEvent('Capacítate con Yevo - Banner principal', `${bannerName}`);
  }

  pushTrainingTab(selectedTab: string): void {
    this.pushVirtualEvent('Capacítate con Yevo - Filtro temas', `${selectedTab}`);
  }

  pushTrainingModule(section: string, moduleName: string): void {
    this.pushVirtualEvent(`Capacítate con Yevo - ${section}`, `${moduleName}`);
  }

  pushTrainingAdBanner(buttonName: string): void {
    this.pushVirtualEvent('Capacítate con Yevo - Card préstamo', `${buttonName}`);
  }

  pushTrainingOption(label: string): void {
    this.pushVirtualEvent('Cuenta personal - Capacítate con Yevo', `Menú: ${label}`);
  }

  pushTrainingKnowMoreOption(label: string): void {
    this.pushVirtualEvent('Cuenta personal - Capacítate con Yevo', `Seleccionar modulo: ${label}`);
  }

  pushTrainingVideoClick(videoTitle: string): void {
    this.pushVirtualEvent('Cuenta personal - Capacítate con Yevo', `${videoTitle} - Reproducir video`);
  }

  pushTrainingFeedbackEvent(videoTitle: string, feelOrComment: string): void {
    this.pushVirtualEvent('Cuenta personal - Capacítate con Yevo', `${videoTitle} - ${feelOrComment}`);
  }

  pushKnowMoreEvent(event: any): void {
    const target = event.currentTarget as HTMLElement;
    this.pushVirtualEvent(
      'Yevo para mi negocio - Quiero saber más',
      `Seleccionar botón: ${target.getAttribute('data-layer-section')}`
    );
  }

  pushQueryAttempt(label: string): void {
    this.pushVirtualEvent('¿Tienes una duda? - Tabs informativos', label);
  }

  pushFaqDetail(section: string, question: string): void {
    this.pushVirtualEvent('¿Tienes una duda? - Acordeon informativos', `${section} - ${question}`);
  }

  pushFaqCommunitiesButton() {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: '¿Tienes una duda? - Comunidades',
      label: 'Seleccionar: Únete a las comunidades',
      Plataforma: isPwaOrWeb(),
    });
  }

  pushFaqContactMethod(label: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: '¿Tienes una duda? - Cóntactanos',
      label: `Seleccionar: ${label}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushContactAttempt(): void {
    this.pushVirtualEvent('Contáctanos', 'Intención de contacto');
  }

  pushContactEvent(type: string, service: string): void {
    this.pushVirtualEvent('Contáctanos - Formulario', `Envío satisfactorio - ${type} - ${service}`);
  }

  pushTermsConditions(name: string): void {
    this.pushVirtualEvent('Términos y Condiciones', `Seleccionar botón: ${name}`);
  }

  pushFooterSocialNetwork(socialNetwork: string): void {
    this.pushVirtualEvent('Footer - Redes sociales', socialNetwork);
  }

  pushFooterMenu(option: string): void {
    this.pushVirtualEvent('Footer - Menú', option);
  }

  // TODO: Used in old intranet. Remove it when old intranet is deleted from project.
  pushDasboardEvent(label: string): void {
    this.pushVirtualEvent('Cuenta personal - Dashboard', label);
  }

  pushFinancingEvent(label: string): void {
    this.pushVirtualEvent('Cuenta personal - Financiamiento', label);
  }

  pushSearchResultEvent(result: string, query: string): void {
    this.pushVirtualEvent('Directorio de negocios - Buscador', `${result} - ${query}`);
  }

  pushPublishBusinessButtonEvent(buttonLabel: string): void {
    this.pushVirtualEvent('Cuenta personal - Directorio de negocios', `Seleccionar botón: ${buttonLabel}`);
  }

  pushPaginationEvent(pageName: string, numberPage: number): void {
    this.pushVirtualEvent(`${pageName} - Paginación`, `Página: ${numberPage}`);
  }

  // TODO: Remove it when old intranet is deleted from project.
  pushStartOnboardingTourEvent(): void {
    this.pushVirtualEvent('Cuenta personal - Dashboard', 'Seleccionar: Ver tutorial nuevamente');
  }

  // TODO: Remove it when old intranet is deleted from project.
  pushTourStepsEvent(stepNumber: number, buttonName?: string): void {
    const label = buttonName ? `Seleccionar botón: ${buttonName}` : 'Visualizar';
    this.pushVirtualEvent(`Cuenta personal - Onboarding - Paso ${stepNumber}`, label);
  }

  pushUserSession(userId: number | null, businessName: string | null): void {
    const layerInformation: any = {
      userID: userId || '(not available)',
      loginStatus: userId ? 'Sesión Iniciada' : 'Sesión no Iniciada',
      empresa: businessName || '(not available)',
      Plataforma: isPwaOrWeb(),
    };
    this.dataLayer.push(layerInformation);
  }

  pushMiTientaAfilliationIntent(): void {
    this.pushVirtualMiTiendaEvent(
      'Cuenta personal - Afiliación miTienda',
      'Seleccionar: Quiero crear mi tienda virtual, Plataforma: ' + isPwaOrWeb()
    );
  }

  pushMiTiendaPlayVideo(): void {
    this.pushVirtualMiTiendaEvent(
      'Cuenta personal - Afiliación miTienda',
      'Reproducir video, Plataforma: ' + isPwaOrWeb()
    );
  }

  pushMiTientaRegisterIntent(): void {
    this.pushVirtualEvent('Cuenta personal - Registro mi tienda', 'Intención de registro');
  }

  pushMiTientaRegisterSuccess(): void {
    this.pushVirtualEvent('Cuenta personal - Registro mi tienda', 'Registro exitoso');
  }

  pushMiTientaRegisterWelcome(label: string): void {
    this.pushVirtualEvent('Cuenta personal - Directorio de negocios', `Felicitaciones: ${label}`);
  }

  /**
   * Push an event after user click on top banner located on Directory view.
   * @param buttonLabel Label of clicked button
   */
  pushRequestLoanFromDirectory(buttonLabel: string): void {
    this.pushVirtualEvent('Banner directorio - Registro', `Seleccionar botón: ${buttonLabel}`);
  }

  /**
   * Push an event after user click on "Mi Perfil" item on dashboard's side nav
   * @param buttonLabel Label of clicked button
   */
  pushGoToMyProfile(buttonLabel: string): void {
    this.pushVirtualEvent('Cuenta personal - Dashboard', `Seleccionar botón: ${buttonLabel}`);
  }

  /**
   * Push an event after user click on Financing Ad Banner (located on Asesory view)
   */
  pushClickFinancingAd(): void {
    this.pushVirtualEvent('Cuenta personal - Capacítate con Yevo', 'Seleccionar botón: Averigualo aquí');
  }

  // **********************  NOVEDADES ***************************************
  /**
   * Push an event when user filter News by specific month
   * @param month Month selected on filter
   */
  pushFilterNewsByMonth(month: string): void {
    this.pushVirtualEvent('Yevo novedades - Filtro', `Seleccionar filtro: ${month}`);
  }

  /**
   * Push an event when user click on a Newsletter card
   * @param section Name of section where card belong
   * @param cardName Title of card
   */
  pushClickOnNewsletterCard(section: 'Lo más visto' | 'Últimas ediciones', cardName: string): void {
    this.pushVirtualEvent(`Yevo novedades - ${section}`, cardName);
  }

  /**
   * Push an event when user use tiny-pagination (using arrows or swipe)
   * @param currentPage Number of current page/item
   */
  pushClickNewsletterTinyPaginator(currentPage: number) {
    this.pushVirtualEvent('Yevo novedades - Avance', currentPage.toString());
  }

  selectedCardNewsletter(categoria: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Intranet - YevoNovedades`,
      label: `${categoria} - ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedTabsNewsletter(option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Intranet - YevoNovedades`,
      label: `Filtrar por: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** END NOVEDADES ***************************************

  /**
   * Push an event when user click on a navbar option/link
   * @param optionName Navbar's links title
   * @param navbarLevel Number of level where is located the item selected. 1 = Root. 2 = My business dropdown, 3 = Our service group
   */
  pushClickNavbarOption(optionName: string, navbarLevel: 1 | 2 | 3): void {
    optionName = optionName.trim();
    const actionTemplate = 'Banner - Menu';
    /**
     * In order to avoid add more logic to header components and its children.
     * We are using the name of each navbar item to identify in which level of navbar
     * they are. So we can create custom message depending on which one was selected, and,
     * we keep the logic in this service.
     * ! Keep in mind that this will fail if navbar items title change since we are
     * ! using harcoded labels here.
     */
    if (navbarLevel === 3) {
      this.pushVirtualEvent(`${actionTemplate} - Yevo para mi negocio`, `Conoce nuestros servicios - ${optionName}`);
      return;
    }
    if (navbarLevel === 2) {
      this.pushVirtualEvent(`${actionTemplate} - Yevo para mi negocio`, `${optionName} - (not available)`);
      return;
    }
    if (navbarLevel === 1) {
      this.pushVirtualEvent(`${actionTemplate} - ${optionName} `, '(not available)');
    }
  }

  /**
   * Push an event when user click on Submit button from Register view
   * @param stepNumber Number of step where component was clicked
   */
  pushSubmitFromRegisterSteps(stepNumber: number) {
    this.pushVirtualEvent(`Crea tu cuenta - Paso ${stepNumber}`, 'Avance satisfactorio');
  }

  pushSubmitFromRegisterStepsTwo(userID: number) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Crea tu cuenta - Paso 2',
      label: 'Avance satisfactorio',
      userID: `${userID}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  /**
   * Push an event when user click on checkboxes' acceptance
   * @param checkboxLabel Checkbox's label
   */
  pushAcceptanceOf(checkboxLabel: string) {
    this.pushVirtualEvent('Crea tu cuenta - Paso 2', checkboxLabel);
  }

  pushLoginFromRegister() {
    this.pushVirtualEvent('Crea tu cuenta - Paso 1', 'Inicia Sesión aquí');
  }

  /**
   * Push an event when user click on location dropdown filter
   * @param geolocationSelected Group of geolocations selected
   */
  pushFilterLocationOnDirectory(geolocationSelected: string[]) {
    const optionKey = defineFilterCriteria();
    const geolocationsToString = geolocationSelected.join(' - ');
    this.pushVirtualEvent('Directorio de negocios - Filtro', `${optionKey} - ${geolocationsToString}`);

    function defineFilterCriteria(): string {
      let optionKey = '(not available)';
      if (geolocationSelected.length === 1) {
        optionKey = 'Departamento';
      } else if (geolocationSelected.length === 2) {
        optionKey = 'Provincia';
      } else if (geolocationSelected.length === 3) {
        optionKey = 'Distrito';
      }
      return optionKey;
    }
  }

  /**
   * Push an event when user click on Virtual Store switch
   * @param switchStatus true: Virtual stores filter ON. false: Virtual stores filter OFF
   */
  pushFilterVirtualStore(switchStatus: boolean) {
    const label = switchStatus ? 'Activar' : 'Desactivar';
    this.pushVirtualEvent('Directorio de negocios - Switch', label);
  }

  /**
   * Push an even when user click on service-card component
   * @param cardTitle Title of card
   */
  pushClickOurServicesDashboard(cardTitle: string) {
    this.pushVirtualEvent('Cuenta personal - Dashboard', `Seleccionar: ${cardTitle}`);
  }

  /**
   * Push an event when user click on any intranet-card component
   * @param sectionName Section identifier.
   * @param cardTitle Title of card
   */
  pushClickIntranetCard(sectionName: 'AllyBenefits' | 'KnowledgeBenefits' | 'OurServices', cardTitle: string) {
    let action = '';
    if (sectionName === 'AllyBenefits') {
      action = 'Beneficios de nuestros aliados para ti';
    } else if (sectionName === 'KnowledgeBenefits') {
      action = 'Beneficios para potenciar tus conocimientos';
    } else if (sectionName === 'OurServices') {
      action = 'Servicios de Mibanco para ti';
    }
    this.pushVirtualEvent(action, `Seleccionar: ${cardTitle}`);
  }

  pushProductClick(store: IStoreEvent): void {
    this.dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: 'listatiendas', // Value defined by business
          products: [
            {
              id: store.id,
              name: store.name,
              price: 0,
              brand: store.deliveryMethods,
              category: store.categories,
              variant: store.paymentMethods,
              quantity: 1,
            },
          ],
          Plataforma: isPwaOrWeb(),
        },
      },
    });
  }

  seedCashStepSuccessfull(stepNumber: number): void {
    this.pushVirtualEvent('Mi efectivo semilla', `Avance Paso ${stepNumber}`);
  }

  seedCashStepSuccessfullLineOfBusiness(stepNumber: number, Business: string ): void {
    this.pushVirtualEvent('Mi efectivo semilla', `Avance Paso ${stepNumber}: ${Business}`);
  }
  
  seedCashShowLoginModal(): void {
    this.pushVirtualEvent('Mi efectivo semilla', 'Ingresa a tu cuenta Yevo');
  }

  seedCashLoginSuccess(): void {
    this.pushVirtualEvent('Mi efectivo semilla', 'Seleccionar: Ingresar');
  }

  seedCashStep4WithoutRegistry(): void {
    this.pushVirtualEvent('Mi efectivo semilla', 'Avance paso 4 - Sin registro');
  }

  oldSeedCashEmailEvent(stepNumber: number, Business: string, email: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Mi Efectivo Semilla`,
      label:  `Avance Paso ${stepNumber}: ${Business}`,
      correo: `${email}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  
  private pushVirtualEvent(action: string, label: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action,
      label,
      Plataforma: isPwaOrWeb(),
    });
  }

  private pushVirtualMiTiendaEvent(action: string, label: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'Tienda Virtual',
      action,
      label,
      Plataforma: isPwaOrWeb(),
    });
  }

  private pushVirtualVideoCoursePreviewEvent(action: string, label: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'Pre visualización cursos y videos externos',
      action,
      label,
      Plataforma: isPwaOrWeb(),
    });
  }

  private pushDataLayer(event: string, values: any): void {
    this.dataLayer.push({
      event: event,
      ...values,
    });
  }

  private get dataLayer() {
    return (window as { [key: string]: any })['dataLayer'];
  }

  // FINANCING INTERNAL AND EXTERNAL MARKS
  pushFinancingSolicitude() {
    this.pushVirtualEvent(
      'Cuenta personal - Financiamiento',
      'Financiamiento Externo - Seleccionar: Quiero una solicitud'
    );
  }

  pushFinancingRemarketing() {
    this.pushVirtualEvent(
      'Cuenta personal - Financiamiento',
      'Financiamiento Externo - Paso 1 - Seleccionar Botón: Continuar'
    );
  }

  pushFinancingInputAmount(isExternal: boolean, actionButton: string) {
    let financingType = isExternal ? 'Financiamiento Externo' : 'Financiamiento Interno';
    this.pushVirtualEvent(
      'Cuenta personal - Financiamiento',
      `${financingType} - Paso 2 - ${actionButton} monto a solicitar`
    );
  }

  pushFinancingDerivation(isExternal: boolean, tipoDerivacion: string, amount: number) {
    let financingString = isExternal ? 'Financiamiento Externo' : 'Financiamiento Interno';

    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Financiamiento',
      label: financingString + ' - Paso 3 - ' + tipoDerivacion,
      dimension8: `${amount}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushFinancingSolicitudeIntention(isExternal: boolean, tipoDerivacion: string) {
    let label = isExternal ? 'Financiamiento Externo' : 'Financiamiento Interno';
    this.pushVirtualEvent(
      'Cuenta personal - Financiamiento',
      label + ' - Paso 4 - Intención solicitud - ' + tipoDerivacion
    );
  }

  pushFinancingSolicitudRefer(referStatus: boolean) {
    let financingString = referStatus ? 'Si, un amigo o familiar' : 'No, ninguno';
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Lab',
      action: 'Landing de Financiamiento - Paso Final',
      Plataforma: isPwaOrWeb(),
      label: '¿Te recomendaron?: ' + financingString + '',
    });
  }

  pushFinancingSolicitudeSuccess(isExternal: boolean, tipoDerivacion: string) {
    let financingString = isExternal ? 'Financiamiento Externo' : 'Financiamiento Interno';
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Financiamiento',
      Plataforma: isPwaOrWeb(),
      label: financingString + ' - Paso 4 - Solicitud Exitosa - ' + tipoDerivacion + '',
    });
  }

  pushFinancingSuccessRegisterIntentionThanks(label: string) {
    this.pushVirtualEvent('Página de gracias', `Opción: ${label}`);
  }

  pushRegisterIntentionThanks() {
    this.pushVirtualEvent('Cuenta personal - Financiamiento', 'Intención registro');
  }

  pushRegisterSuccessThanks() {
    this.pushVirtualEvent('Cuenta personal - Financiamiento', 'Registro Exitoso');
  }

  pushKnowMoreThanks() {
    this.pushVirtualEvent('Cuenta personal - Financiamiento', 'Registro - Conoce más de Yevo');
  }

  pushLoginSolicitudeMessage() {
    this.pushVirtualEvent('Cuenta personal - Financiamiento', 'Iniciar sesión');
  }

  pushThanksPageEvent(pageType: string, selectedOption: string, state: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Página de gracias - ${pageType}`,
      label: `${selectedOption} - ${state}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushFinancingYapeStep1() {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco-Yevo',
      action: 'AlianzaMibancoYape',
      label: 'AvancePaso1',
      Plataforma: isPwaOrWeb(),
    });
  }
  pushFinancingYapeAmountApproved(amountApproved: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco-Yevo',
      action: 'AlianzaMibancoYape',
      label: 'Solicitarmonto',
      dimension8: amountApproved,
      Plataforma: isPwaOrWeb(),
    });
  }
  pushFinancingYapeSendSolitude() {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco-Yevo',
      action: 'AlianzaMibancoYape',
      label: 'Enviarsolicitud',
      Plataforma: isPwaOrWeb(),
    });
  }

  // END FINANCING INTERNAL AND EXTERNAL MARKS

  // NEW HOME MARKS
  pushHomeLoginEvent(section: string, contentName: string) {
    this.pushVirtualEvent(`Home - Inicio de sesión - ${section}`, `Seleccionar botón: ${contentName}`);
  }

  pushHomeRegisterEvent(section: string, selectedCard: string) {
    this.pushVirtualEvent(`Home - Registro - ${section}`, `Seleccionar botón: ${selectedCard}`);
  }

  pushHomeSeeDirectoriesEvent() {
    this.pushVirtualEvent('Home - Ver directorio de negocio', '(not available)');
  }

  pushHomeDirectoryCardEvent(selectedCard: string) {
    this.pushVirtualEvent('Home - Card directorio de negocio', selectedCard);
  }

  pushHomeOurServicesEvent(selectedService: string) {
    this.pushVirtualEvent('Home - Card nuestros servicios', selectedService);
  }

  pushHomeFinancingSolicitudeEvent() {
    this.pushVirtualEvent('Home - Solicitar financiamiento', '(not available)');
  }
  // ********************** EVENT COURSE ***************************************
  pushTrainingCourseEvent(label: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - Cursos virtuales',
      label: label,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushTrainingCourseStartEvent(course: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - Cursos virtuales',
      label: `${course} - Iniciar curso`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushTrainingCourseStartButtonEvent(label: string, boton: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - Cursos virtuales',
      label: `${label} - Botón: ${boton}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushTrainingCourseLessonEvent(curso: string, seccion: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - Cursos virtuales',
      label: `${curso} - Módulo: ${seccion}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushTrainingCourseAdvancedEvent(curso: string, description: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - Cursos virtuales',
      label: `${curso} - ${description}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushTrainingCourseFinishEvent(curso: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - Cursos virtuales',
      label: `${curso} - TERMINAR CURSO`,
      Plataforma: isPwaOrWeb(),
    });
  }

  loadTrainingCourse(curso: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - Cursos virtuales',
      label: `${curso} - YEVO- CURSOS`,
      Plataforma: isPwaOrWeb(),
    });
  }

  buttonStartTrainingCourse(curso: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - ¿Quieres aprender más?',
      label: `Empezar curso: ${curso}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectCourseDetail(curso: string, detalleCurso: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - ¿Quieres aprender más?',
      label: `Ver detalle - ${curso}: ${detalleCurso}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  buttonShowMoreCourses() {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - ¿Quieres aprender más?',
      label: 'Ver más cursos',
      Plataforma: isPwaOrWeb(),
    });
  }

  pushVizualizationSectionEvent(curso: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Asesórate con Yevo - ¿Quieres aprender más?',
      label: `Visualizar sección: ${curso}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** END EVENT COURSE ***************************************

  pushTrainingCourseSelectedEvent(label: string, action: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Cursos virtuales - video: ${action}`,
      label: label,
      Plataforma: isPwaOrWeb(),
    });
  }
  // END NEW HOME MARKS

  // VIDEOS & COURSES PREVIEW MARKS
  pushVideoCoursePreviewButtonEvent(course: string, button: string) {
    this.pushVirtualVideoCoursePreviewEvent(
      `Pre Capacítate con Yevo - ${course}`,
      `Seleccionar: ${button}, Plataforma: ${isPwaOrWeb()}`
    );
  }

  pushVideoCoursePreviewPlayVideoEvent(course: string) {
    this.pushVirtualVideoCoursePreviewEvent(
      `Pre Capacítate con Yevo - ${course}`,
      `Reproducir video, Plataforma: ${isPwaOrWeb()}`
    );
  }

  pushVideoCoursePreviewModalLoginEvent(course: string) {
    this.pushVirtualVideoCoursePreviewEvent(
      `Pre Capacítate con Yevo - ${course}`,
      `Iniciar sesión, Plataforma: ${isPwaOrWeb()}`
    );
  }

  pushVideoCoursePreviewModuleSelectionEvent(course: string) {
    this.pushVirtualVideoCoursePreviewEvent(
      `Pre Capacítate con Yevo - ${course}`,
      `Selecionar modulo, Plataforma: ${isPwaOrWeb()}`
    );
  }

  pushVideoCoursePreviewModalEvent(course: string) {
    this.pushVirtualVideoCoursePreviewEvent(
      `Pre Capacítate con Yevo - ${course}`,
      `Visualización de modal, Plataforma: ${isPwaOrWeb()}`
    );
  }

  pushVideoButtonPreviewSession(video: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'Pre visualización cursos y videos externos',
      action: `Pre Capacítate con Yevo - ${video}`,
      label: 'Seleccionar: Ver más videos',
      Plataforma: isPwaOrWeb(),
    });
  }

  pushVideoButtonPreviewSessionModal(video: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'Pre visualización cursos y videos externos',
      action: `Pre Capacítate con Yevo - ${video}`,
      label: `Modal de videos externos: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // END VIDEOS & COURSES PREVIEW MARKS

  // ********************** EVENT CALENDAR ***************************************
  pushCalendarSelectedEvent() {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Calendario',
      label: 'Seleccionar calendario',
      Plataforma: isPwaOrWeb(),
    });
  }

  pushCalendarSelectedDayEvent(evento: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Calendario',
      label: `Seleccionar evento: ${evento}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushCalendarSelectedModalEvent(option: string, event: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Calendario',
      label: `${option}: ${event}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  // ********************** END EVENT CALENDAR *************************************

  // ********************** EVENT PRE-APROVED ***************************************
  pushPreapproveModalDisclaimer(label: string, isPreapproved: boolean = true) {
    const preapprovedLabel = isPreapproved ? 'preaprobado' : 'aprobado';
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Financiamiento',
      label: `Modal de ${preapprovedLabel} digital - Aviso Obligatorio${' -' + label}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushPreapproveModalLocationEvent(label: string, isPreapproved: boolean = true) {
    const preapprovedLabel = isPreapproved ? 'preaprobado' : 'aprobado';
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Financiamiento',
      label: `Modal de ${preapprovedLabel} digital - ${label}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushPreapproveModalLocationButtonEvent(label: string, button: string, flowTypeLabel: string = '') {
    const text = button
      ? `Modal de ${flowTypeLabel} - ${label} - ${button}`
      : `Modal de ${flowTypeLabel} - ${label}`;
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Financiamiento',
      label: text,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushPreapproveStepSuccessfull(derivacion: string, stepNumber: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${derivacion}`,
      label: `Avance Paso - ${stepNumber}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushPreapproveUploaddocument(financing: string, state: string, file: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Cuenta personal - Financiamiento`,
      label: `${financing} - ${state} - ${file}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  // ********************** END EVENT PRE-APROVED ***************************************

  // ********************** START EVENT CDE ***************************************

  pushCDEModalDisclaimer(label: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Financiamiento',
      label: `Modales deuda - ${label}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushCDEModalLocationEvent() {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Financiamiento',
      label: `Modales Deuda - Ubicación del negocio`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushCDEModalLocationButtonEvent(button: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Financiamiento',
      label: `Modales Deuda - Ubicación del negocio - ${button}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushCDEStepSuccessfull(derivacion: string, stepNumber: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${derivacion}`,
      label: `Avance Paso - ${stepNumber}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushCDEUploaddocument(financing: string, state: string, file: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Cuenta personal - Financiamiento`,
      label: `${financing} - ${state} - ${file}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** END EVENT CDE *****************************************

  // ********************** EVENT ENTREPRENEUR WOMAN ***************************************
  pushEntrepreneurWomanModalOpened(): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Cuenta personal - Financiamiento`,
      label: `Modal de crédito mujer - Filtro de Sexo`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushEntrepreneurWomanModalSelection(option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Cuenta personal - Financiamiento`,
      label: `Modal de crédito mujer - Filtro de Sexo - ${option}`,
      sexo: option,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushEntrepreneurWomanAmountRequested(amount: number): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Cuenta personal - Financiamiento`,
      label: `Crédito Mujer - Ingresar monto a solicitar`,
      dimension8: `${amount}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushEntrepreneurWomanStepSuccessfull(stepNumber: number, employmentSituation: string = ''): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `crédito mujer`,
      label: `Avance Paso - ${stepNumber}${employmentSituation ? ' - ' + employmentSituation : ''}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushEntrepreneurWomanGeolocationModalOpened(option: string = ''): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Cuenta personal - Financiamiento`,
      label: `Modal de crédito mujer - Ubicación del negocio ${option ? '- ' + option : ''}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** END ENTREPRENEUR WOMAN ***************************************

  // ********************** EVENT NEW DASHBOARD ***************************************
  buttonBannerNewDashboard(codigo: string, button: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - ${codigo}`,
      label: `Seleccionar: ${button}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCourseNewDashboard(codigo: string, curso: string, selected: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - ${codigo}`,
      label: `${curso} - ${selected}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedAllCourseNewDashboard(): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - 2`,
      label: `Seleccionar: Ver todos los cursos`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCardVideoNewDashboard(codigo: string, name: string, selected: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - ${codigo}`,
      label: `${name} - ${selected}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedBenefitsCardNewDashboard(codigo: string, name: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - ${codigo}`,
      label: `Seleccionar: ${name}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedEventCalendarNewDashboard(name: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - 5`,
      label: `Seleccionar evento: ${name}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedEventCalendarModalNewDashboard(button: string, name: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - 5`,
      label: `${button}: ${name}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedMonthEventCalendarNewDashboard(month: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - 5`,
      label: `Filtro de evento: ${month}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedExternalLinkNewDashboard(codigo: string, name: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - ${codigo}`,
      label: `Enlace: ${name}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** END EVENT NEW DASHBOARD ***************************************

  // ********************** EVENT ALLIED INTRANET ***************************************

  selectedAlliedCardCategory(cardCategory: string, cardDescription: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Intranet - Aliados',
      label: `${cardCategory} - ${cardDescription}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedAlliedBanner(bannerDescription: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Intranet - Aliados',
      label: `Seleccionar banner: ${bannerDescription}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  // ********************** END EVENT ALLIED INTRANET ***********************************

  // ********************** EVENT DASHBOARD 3.0 ***************************************
  selectedHeaderDashboard3(opcion: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - Header`,
      label: `Seleccionar: ${opcion}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedSubHeaderDashboard3(option: string, title: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - Header`,
      label: `${option} - ${title}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedSubAndHeaderExternal(option: string, title: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Web Externa - Header`,
      label: `${option} - ${title}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectCalendarAlertDashboard3(option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - 6`,
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedLinkDashboard3(option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - 7`,
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCardCommunityDashboard3(title: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - 8`,
      label: `Ir a: ${title}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCardBannerDashboard3(title: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - 4`,
      label: `Seleccionar: ${title}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCardCourseDashboard3(name: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - 2`,
      label: `${name} - ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCardVideoDashboard3(name: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - 9`,
      label: `${name} - ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedButtonAllDashboard3(codigo: string, title: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - ${codigo}`,
      label: `Seleccionar: ${title}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** EVENT COURSES PUBLIC ***************************************
  selectedButtonRegisterCoursesPublic(code: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Capacitaciones Externa - ${code}`,
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedButtonAllpublic(code: string, title: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Capacitaciones Externa - ${code}`,
      label: `Seleccionar: ${title} - ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCardCoursepublic(code: string, name: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Capacitaciones Externa - ${code}`,
      label: `${name} - ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedEventCalendarpublic(
    code: string,
    name: string,
    view: string = 'Capacitaciones Externa',
    button: string = 'Seleccionar evento'
  ): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${view} - ${code}`,
      label: `${button}: ${name}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** EVENT PUBLIC COMMUNITY ***************************************
  publicCommunityRegisterAttempt(option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Comunidades Interna - 1',
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  publicCommunitySeidorCardClicked(selectedOption: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Comunidades Externa - 2',
      label: `Ir a: ${selectedOption}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** END PUBLIC COMMUNITY ***************************************

  // ********************** EVENT SAVINGS ACCOUNT ***************************************
  savingAccountEvent(event: string, account: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuentas de Ahorro',
      label: `${event}${account}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  savingAccountSendSolicitude(account: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Cuentas de Ahorro - ${account}`,
      label: `Solicitud enviada`,
      Plataforma: isPwaOrWeb(),
    });
  }
  savingAccountModalEvent(detail: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Cuenta personal - Financiamiento',
      label: `Modal Cuenta de Ahorros - ${detail} - ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  savingAccountBackEvent(detail: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'cuenta ahorro negocios',
      label: `Retroceso ${detail}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** END EVENT SAVINGS ACCOUNT ***************************************

  // ********************** EVENT ALLIED  ***************************************
  selectedNewAlliedOptions(codigo: string, option: string, isLogged: boolean) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Aliados ${isLogged ? 'Interna' : 'Externa'} - ${codigo}`,
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedAlliedFilters(filterOption: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Aliados Interna - 3`,
      label: `Filtrar por: ${filterOption}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedAlliedButtons(alliedDetail: string, button: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Aliados Interna - 4`,
      label: `${alliedDetail}: ${button}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedAlliedMainPageOptions(sectionCode: number, optionSelected: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Aliados Interna - ${sectionCode}`,
      label: optionSelected === '' ? '' : `Seleccionar: ${optionSelected}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  registerWithAllied() {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Aliados Externa',
      label: 'Seleccionar: Registrarme',
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedBannerAllied(option: string, isLogged: boolean) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Aliados ${isLogged ? 'Interna' : 'Externa'} - 3`,
      label: `Seleccionar banner: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCuponAllied(detail: string, option: string, isLogged: boolean) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Aliados ${isLogged ? 'Interna' : 'Externa'} - 4`,
      label: `${detail}: ${isLogged ? option : 'Registrarme'}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedMoreAllied(option: string, isLogged: boolean) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Aliados ${isLogged ? 'Interna' : 'Externa'} - 5`,
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** START SIMULATOR ********************************
  redirectoToPrincipalFlows(option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `simulador de préstamos - página de gracias`,
      label: `Seleccionar: ${option}`,
      plataforma: isPwaOrWeb(),
    });
  }

  clickCalculateSimulation(option: string, amount: number) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `simulador de préstamos - ${option}`,
      label: `Avance Paso - 1`,
      dimension8: `${amount}`,
      plataforma: isPwaOrWeb(),
    });
  }

  clickSimulatorBanner(subsection: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Cuenta personal - Financiamiento`,
      label: `${subsection}: Derivación a simulador`,
      plataforma: isPwaOrWeb(),
    });
  }
  // ********************** END SIMULATOR ********************************

  // ********************** PWA ********************************
  publicPWA(user: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `APP - PWA`,
      label: `Usuario: ${user}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  publicWEB(user: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `APP - WEB`,
      label: `Usuario: ${user}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  // ********************** END PWA ********************************

  clickCommunity(community: string, option: string, valueOption: string, numberOption: number): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Comunidad ${community} - ${numberOption}`,
      label: `${valueOption}: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  clickCommunityExt(numberOption: number, label: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Comunidades Externa - ${numberOption}`,
      label: `${label}: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  clickCommunityInternal(numberOption: number, label: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Comunidades Interna - ${numberOption}`,
      label: `${label}: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushLevel(label: string, level: string): void {
    this.dataLayer.push({
      [label]: level,
    });
  }

  // #################### START CREDIT HEALTH #####################
  creditHealthStatus(status: string): void {
    this.dataLayer.push({ estadoCrediticio: status, Plataforma: isPwaOrWeb() });
  }

  creditHealthStep1(option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Espiral crediticio - Paso 1',
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  creditHealthStep2And3(option: string, step: 2 | 3): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Espiral crediticio - Paso ${step}`,
      label: `Comenzar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  creditHealthModals(modalDetail: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Espiral crediticio - Modales',
      label: `${modalDetail}: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  creditHealClaimTrophy(step: number): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Espiral crediticio - Paso ${step}`,
      label: 'Recibir trofeo',
      Plataforma: isPwaOrWeb(),
    });
  }
  // #################### END CREDIT HEALTH #####################

  // #################### CREDIT HEALTH NEW #####################

  creditHealthStep2(recurso: string, detalle: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Espiral crediticio - Paso 2',
      label: `Seleccionar ${recurso}: ${detalle}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  creditHealthCongratulations(option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Espiral crediticio - Felicitaciones',
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  creditHealthStep2Video(detalle: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Espiral crediticio - Paso 2',
      label: `Vista previa: ${detalle}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  creditHealtModalCongratulation(): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Espiral crediticio - Paso 3',
      label: 'Recibir trofeo',
      Plataforma: isPwaOrWeb(),
    });
  }

  // #################### END CREDIT HEALTH #####################

  // #################### SMS ACTIVATION #####################
  activationSmsRegistrationEvent(email: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Crea tu cuenta - Paso 3',
      label: `Activación SMS: ${option}`,
      userID: ``,
      Plataforma: isPwaOrWeb(),
    });
  }
  editPhoneRegistrationEvent(email: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco-Yevo',
      action: 'Crea tu cuenta - Paso 3',
      label: `Cambio de número: ${option}`,
      userID: ``,
      Plataforma: isPwaOrWeb(),
    });
  }
  accountActivatedBySmsEvent(email: string, option: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco-Yevo',
      action: 'Crea tu cuenta - Paso 3',
      label: `Tu cuenta está activa: ${option}`,
      userID: ``,
      Plataforma: isPwaOrWeb(),
    });
  }

  // #################### LANDING REMITTANCES #####################


  virtualEventEnlaceREM(option: string, codeNumber: number ) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Lab',
      action: `Landing Remesas - ${codeNumber}`,
      label: `${option}`,
      segmento: 'Taxis y Bodegas',
      Plataforma: isPwaOrWeb(),
    });
  }

  // #################### LANDING REFERS #####################

  virtualEventEnlaceRE(option: string, codeNumber: string | number) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Lab',
      action: `Programa de Referidos - ${codeNumber}`,
      label: `${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  
  virtualEventEnlaceRefer(type: string) {
    let financingString = '';
    if(type === 'final') { financingString = 'Paso Final'};
    if(type === 'thanks') { financingString = 'Página de Gracias'};

    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Lab',
      action: `Landing de Financiamiento - ${financingString}`,
      label: `Seleccionar: Copiar enlace`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushEnlacedRecommend(enlaced: string) {
    this.dataLayer.push({ enlaceReferido: enlaced });
  }

  pushEnlacedReverseflow(enlaced: string) {
    this.dataLayer.push({ codigoSoli: enlaced });
  }

  // #################### END SMS ACTIVATION #################

  virtualEventEM(option: string, codeNumber: number) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Comunidad Emprende Mujer - ${codeNumber}`,
      label: `${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  virtualEventBodegueros(option: string, codeNumber: number) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Comunidad de Bodegueros - ${codeNumber}`,
      label: `${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** CHILD PHENOMENON ********************************
  selectedTapsOptionsChildPhenomenon(section: string, option: string, isLogged: boolean) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `FEN ${isLogged ? 'Interno' : 'Externo'} - ${section}`,
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedFilterOptionsChildPhenomenon(landing: string, section: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${landing} - ${section}`,
      label: `Filtrar por: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedVideoChildPhenomenon(isLogged: boolean, section: string, location: string, select: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `FEN ${isLogged ? 'Interno' : 'Externo'} - ${section}`,
      label: `${location}: ${select}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedMonthCalendarChildPhenomenon(landing: string, section: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${landing} - ${section}`,
      label: `Filtrar por mes: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedEventCalendarChildPhenomenon(landing: string, section: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${landing} - ${section}`,
      label: `Seleccionar evento: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedEventCalendarModalChildPhenomenon(landing: string, section: string, button: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${landing} - ${section}`,
      label: `${button}: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedRegisterChildPhenomenon(landing: string, section: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${landing} - ${section}`,
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectButtonMoreEventCalendarChildPhenomenon(isLogged: boolean, section: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${isLogged ? 'FEM Interno' : 'FEM Externo'} - ${section}`,
      label: `Seleccionar evento: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectButtonMoreEventCardCalendarChildPhenomenon(isLogged: boolean, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${isLogged ? 'FEM Interno' : 'FEM Externo'} - Entérate de los eventos que tenemos para ti`,
      label: `Seleccionar evento: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectButtonsModalEventCardCalendarChildPhenomenon(isLogged: boolean, event_name: string, button_select: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${isLogged ? 'FEM Interno' : 'FEM Externo'} - Entérate de los eventos que tenemos para ti`,
      label: `${button_select}: ${event_name}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** END CHILD PHENOMENON ********************************
  //price comparator

  virtualEventPriceComparator(
    option: string,
    codeNumber: number,
    mercado: string,
    producto: string,
    presentacion: string
  ) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Comunidad de Bodegueros - ${codeNumber}`,
      label: `${option}`,
      mercado: `${mercado}`,
      producto: `${producto}`,
      presentacion: `${presentacion}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** COURSES ********************************
  selectedStartCourse(title: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Asesórate con Yevo - Cursos virtuales`,
      label: `${title}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedTapsOptionsCourse(title: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Asesórate con Yevo - ${title}`,
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedLessonsCourse(title: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Asesórate con Yevo - ${title}`,
      label: `Temario: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedVideoCourse(title: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Cuenta personal - Capacítate con Yevo`,
      label: `${title} - Reproducir video`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedContinueCourse(title: string, detail: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Asesórate con Yevo - ${title}`,
      label: `${detail}: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCertificateCourse(title: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Asesórate con Yevo - ${title}`,
      label: `Certificación: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********************** END COURSES ********************************

  // ********************** EVENT DIAGNOSTIC DIGITAL ***************************************
  selectedTypeDiagnosticDigital(type: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Diagnóstico digital - ${type}`,
      label: `Seleccionar: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedOptionDiagnosticDigital(type: string, option: string, step: number) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Diagnóstico digital - ${type}`,
      label: `Paso ${step}: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCardDiagnosticDigital(type: string, detail: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Recomendaciones - ${type}`,
      label: `${detail}: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }
  // ********************** END EVENT DIAGNOSTIC DIGITAL ***************************************

  // *********************** EVENT NEW HOME EXTERNAL ***************************************

  clickBtnBannerNewHomeExternal(data: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Home Externo - 1',
      label: `Seleccionar: ${data}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCardNewHomeExternal(data: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Home Externo - 2',
      label: `Seleccionar: ${data}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  clickBtnServicesFinancialNewHomeExternal(data: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Home Externo - 3',
      label: `Seleccionar: ${data}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedCardCommunityNewHomeExternal(data: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Home Externo - 4',
      label: `Seleccionar: ${data}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  clickBtnCoursesNewHomeExternal(data: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Home Externo - 5',
      label: `Seleccionar: ${data}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  clickBtnAlliesNewHomeExternal(data: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Home Externo - 6',
      label: `Seleccionar: ${data}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  clickFABNewHomeExternal(data: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: 'Home Externo - 7',
      label: `Seleccionar: ${data}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // *********************** END EVENT NEW HOME EXTERNAL ***************************************

  // *********** YAPE EVENTS *************

  yapeEvent(label: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Flujo Yape`,
      label: `${label}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  yapeRegisterEvent(status: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Flujo Yape - Página de gracias`,
      label: `Crear mi cuenta: ${status}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********* END YAPE EVENTS ***********

  // ********** EVENT NEW HOME ***********

  categoryNewHome(category: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - Header`,
      label: `${category}: ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  categoryBannerNewHome(category: string, option: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Dashboard - Header`,
      label: `${category}: Banner - ${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********** END EVENT NEW HOME ***********
  // *********** Downloads files EVENTS *************

  selectedOptionDownloads(label: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Capacítate con Yevo - Herramientas`,
      label: `'Filtrar herramientas: ${label}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  selectedDownloads(opcion: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `Capacítate con Yevo - Herramientas`,
      label: `Descargar: ${opcion}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********* END Doownloads files EVENTS ***********

  pushProgramReferredUser(code: string, level: string) {
    this.dataLayer.push({ codeReferred: code, levelReferred: level });
  }

  pushEnlacedReferred(enlaced: string) {
    this.dataLayer.push({ enlacedReferred: enlaced });
  }
  // ********* Interactions on thank you page ***********
  interactionsOnThank(type: string, detail: string, opcion: string, state: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${type} - ${detail}`,
      label: `${opcion} - ${state}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  pushThanksPageEventGeneral(pageType: string, detail: string, selectedOption: string, state: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: ` ${pageType} - ${detail}`,
      label: `${selectedOption} - ${state}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********* END Interactions on thank you page ***********

  // ********* Credit Health ***********

  creditStatus(option: string): void {
    this.pushDataLayer('virtualEvent', {
      estadoCrediticio: `${option}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  // ********* End Credit Health ***********

  // ********* New Flow Taxi ***********
  taxiAndStoreEvent(action: string, label: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Yevo',
      action: `${action}`,
      label: `${label}`,
      segmento: 'Taxis y Bodegas',
      Plataforma: isPwaOrWeb(),
    });
  }

  valueEvent(label: string, value: string,): void {
    this.dataLayer.push({
      [label]: value,
    });
  }
  // ********* End New Flow Taxi ***********

    /* ==================== LOGIN ==================== */
    selectedButtonLogin(state: string) {
      this.pushDataLayer('virtualEvent', {
        category: 'MiBanco - MibancoLab',
        action: 'Home Externo - Inicio de sesión',
        label: `${state}`,
        Plataforma: 'WEB',
      });
    }
  
    selectedButtonTermLogin(state: string) {
      this.pushDataLayer('virtualEvent', {
        category: 'MiBanco - MibancoLab',
        action: 'Home Externo - Inicio de sesión',
        label: `Seleccionar: ${state}`,
        Plataforma: 'WEB',
      });
    }
    /* ==================== LOGIN END ==================== */
  // ********* NEW SEED ***********
  stepNewSeedCashEvent(steps: string, label: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Lab',
      action: `Mi Efectivo Semilla - Paso ${steps}`,
      label: `${label}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  newSeedCashEmailEvent(steps: string, label: string, email: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Lab',
      action: `Mi Efectivo Semilla - Paso ${steps}`,
      label: `${label}`,
      correo: `${email}`,
      Plataforma: isPwaOrWeb(),
    });
  }

  genericMilab(action: string,label: string) {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Lab',
      action: `${action}`,
      label: `${label}`,
      Plataforma: isPwaOrWeb(),
    });
  }
    
 // ================== POINTS PROGRAMS ===================

  programPointsEvent(action: string, label: string): void {
    this.pushDataLayer('virtualEvent', {
      category: 'MiBanco - Lab',
      action: `Programa de Puntos - ${action}`,
      label: `${label}`,
      Plataforma: isPwaOrWeb(),
    });
  }

    /* ==================== SIMULATOR ADN  ==================== */
    simulatorRVSelect(action: string,label: string): void {
      this.pushDataLayer('virtualEvent', {
        category: 'MiBanco Lab - Calculadora RV',
        action: `${action}`,
        label: `${label}`,
        Plataforma: isPwaOrWeb(),
      });
    }
  }
    /* ==================== LOGIN END ==================== */ 