<div class="summary-card" (click)="openModal()">
    <div class="summary-card__title">{{ title }}</div>
    <div class="summary-card__amount"> <span>s/ </span>{{ finalTotal }}</div>
    <div class="increase-wrapper" *ngIf="increaseSummary.length > 0">
        <milab-increase-card 
        [increase]="increaseSummary"
        [icon]="iconSummary"
        [color]="summaryColor">
      </milab-increase-card>
    </div>
    <div class="summary-card__action">
     <button >Ver resumen <img src="/assets/icons/adn/chevron-right.svg" alt=""></button>
    </div>
</div>