import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RV_DATALAYER } from '@shared/constants/adn-simulator.constants';
import { AdnUserProfile } from '@shared/models/UserProfileModel';
import { AuthAdnService } from '@shared/services/auth-adn.service';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'milab-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() user!: AdnUserProfile;
  @Output() closeSidebar: EventEmitter<void> = new EventEmitter();

  nameAdn:string = 'MEJIA OBREGON MARIA ELENE';
  codeAdn:string = '12539';
  
  cards = [
    { 
      order: 0,
      icon: 'assets/img/adn/icons/announcement-white.svg', 
      selectedIcon:'assets/img/adn/icons/announcement-black.svg', 
      title: 'Noticias', 
      isSelected: false,
      selectedColor: '#141518',
      url: 'noticias',
      notification:true 
    },
    { order: 1,
      icon: 'assets/img/adn/icons/calculate-white.svg', 
      selectedIcon:'assets/img/adn/icons/calculate-black.svg', 
      title: 'Simulador RV', 
      isSelected: false,
      selectedColor: '#141518',
      url: 'simulador',
      notification:false 
    },

  ];
  constructor(private authAdnService: AuthAdnService,
              private router: Router,
              private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
    this.setSelectedCardFromUrl();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setSelectedCardFromUrl();
      }
    });
  }

  setSelectedCardFromUrl() {
    const currentUrl = this.router.url;
    this.cards.forEach(card => {
      card.isSelected = currentUrl.includes(card.url);
    });
  }

  onCardClick(data: any): void {
    this.cards.forEach((card, i) => {
      card.isSelected = i === data.order;
    });

    this.dataLayerService.simulatorRVSelect(RV_DATALAYER.actionSidebar,RV_DATALAYER.selectOptionName+data.title);

    this.router.navigate([`/adn-intranet/${data.url}`]);
    this.doCloseSidebar();
  }

  closeSession(): void {
    this.dataLayerService.simulatorRVSelect(RV_DATALAYER.actionSidebar,RV_DATALAYER.selectOptionName+RV_DATALAYER.eventoCloseSession);
    this.authAdnService.adnCloseSession();
  }

  doCloseSidebar() {
    this.closeSidebar.emit();
  }
}
