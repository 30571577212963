<div class="multipliers-card">
  <div class="multipliers-card__title color-dark">{{ title }}</div>
  <div class="multipliers-card_content">
    <div>
      <div class="multipliers-card_content__amount">
        <div class="multipliers-card_content__amount-value color-dark">{{ deceleratorPercent }}%</div>
      </div>
      <div class="increase-wrapper" *ngIf="increaseDecelerator.length > 0">
        <milab-increase-card [increase]="increaseDecelerator" [icon]="iconDecelerator" [color]="deceleratorColor">
        </milab-increase-card>
      </div>
      <div class="multipliers-card_content__description">
        {{ description2 }} <span>{{this.achievementAverage}}%</span>
      </div>
    </div>
  </div>
</div>
