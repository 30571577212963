import { ChangeDetectorRef, Component,Input } from '@angular/core';
import { INCREASE, RV_DATALAYER } from '@shared/constants/adn-simulator.constants';
import { InputItem, managementItem, VariableDirect } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { calculateMoraCompliance, 
         calculateResolutionCompliance, 
         complianceColorValid, 
         convertToNumeric, 
         convertToNumericWithCommas, 
         convertToPercentageV2, 
         divideByHundred, 
         division, 
         formatPercentage } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-variable-collection-card',
  templateUrl: './variable-collection-card.component.html',
  styleUrls: ['./variable-collection-card.component.scss']
})
export class VariableCollectionCardComponent {
  @Input() listVariablePise9: VariableDirect[] = [];
  @Input() variableItem: string = '';
  @Input() listVariableResolutions: VariableDirect[] = [];
  @Input() managementItem!: managementItem;
  @Input() managementItemTwo!: managementItem;
  variablesDirectGestion: any;

  pise9Achievement:number = 0;
  constructor(private adnSimulatorService: AdnSimulatorService,private cdr: ChangeDetectorRef,private dataLayerService: DataLayerService) { }

   onManagementPise9Change(event: InputItem): void {
    const variable = this.listVariablePise9.find(item => item.id === event.key);
    this.dataLayerService.simulatorRVSelect(RV_DATALAYER.actionTabValues,`${RV_DATALAYER.eventInputPiseNine}: ${event.value.toString()}`);
    if (variable && variable.metas) {
      if (variable.id === 'pise9') {
        this.managementItem.valueOne = event.value;
        this.adnSimulatorService.setPise9Mora(event.value);
        const logro = division(event.value,convertToNumeric(convertToNumericWithCommas(this.managementItem.valueTwo)));
        variable.logro = parseFloat(convertToPercentageV2(logro));
        const [minPise9, pise9100, pise9125, pise9150] = variable.metas.content[0].map(parseFloat);

        const dataCumplimiento = calculateMoraCompliance(
          convertToNumeric(logro),
          divideByHundred(minPise9),
          divideByHundred(pise9100),
          divideByHundred(pise9125),
          divideByHundred(pise9150)
        );
        this.updateIncrease(variable, dataCumplimiento);
        this.adnSimulatorService.updateCompliancePise9Mora(convertToNumeric(dataCumplimiento));
        variable.cumplimiento = dataCumplimiento.toString().concat('%');
        variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimiento));
      }
      this.cdr.detectChanges();
    }
   }

   onManagementResolutionsChange(event: InputItem): void {
    const variable = this.listVariableResolutions.find(item => item.id === event.key);
    this.dataLayerService.simulatorRVSelect(RV_DATALAYER.actionTabValues,`${RV_DATALAYER.eventInputResolution}: ${event.value.toString()}`);
    if (variable && variable.metas) {
      if (variable.id === 'resolucion') {
        this.managementItemTwo.valueOne = event.value;
        this.adnSimulatorService.setResolutionBalance(event.value);
        const logro = division(event.value,convertToNumeric(convertToNumericWithCommas(this.managementItemTwo.valueTwo)));
        variable.logro = parseFloat(convertToPercentageV2(logro));
        const [minResolution0, resolution0100, resolution0125, resolution0150] = variable.metas.content[0].map(parseFloat);
        const dataCumplimiento = calculateResolutionCompliance(
          convertToNumeric(logro),
          divideByHundred(minResolution0),
          divideByHundred(resolution0100),
          divideByHundred(resolution0125),
          divideByHundred(resolution0150)
        );
        this.updateIncrease(variable, dataCumplimiento);
        this.adnSimulatorService.updateComplianceResolution0Days(convertToNumeric(dataCumplimiento));
        variable.cumplimiento = dataCumplimiento.toString().concat('%');
        variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimiento));
      }
      this.cdr.detectChanges();
    }
   }

   private updateIncrease(variable: VariableDirect, dataCumplimiento: number): void {
    const increase = dataCumplimiento - variable.cumplimientoInit;
    if (increase > 0) {
      variable.increase = formatPercentage(increase);
      variable.increaseColor = INCREASE.colorIncrement;
      variable.increaseIcon = INCREASE.iconArrowUp;
    } else if (increase < 0) {
      variable.increase = formatPercentage(increase);
      variable.increaseColor = INCREASE.colorDecrement;
      variable.increaseIcon = INCREASE.iconArrowDown;
    } else {
      variable.increase = '';
    }
  }

}
