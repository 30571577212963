import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { DashboardService } from '@shared/services/dashboard.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { LoaderService } from '@shared/services/loader.service';
import { PwaService } from '@shared/services/pwa.service';
import { SEOService } from '@shared/services/seo.service';
import { Subscription } from 'rxjs';
import { filter,first, take, timeout } from 'rxjs/operators';
import { LocalStorageService } from './shared/services/local-storage.service';
import { ModalTermsConditionsPoliciesComponent } from '@shared/components/modal-terms-conditions-policies/modal-terms-conditions-policies.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SessionTimeoutService } from '@shared/services/session-timeout.service';
import { ModalWarningTimeComponent } from '@shared/components/modal-warning-time/modal-warning-time.component';
import { AuthAdnService } from '@shared/services/auth-adn.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  subscription$ = new Subscription();
  isLogged: boolean = false;
  isOne: boolean = false;
  user: any = null;
  modalRef!:NgbModalRef;
  
  _modalOptionsNormalNewTerms: NgbModalOptions = {
    centered: true,
    windowClass: 'modal-contents modal-sms modal-terms-conditions-new',
    backdrop: 'static',
    keyboard: false,
  };

  constructor(
    seoService: SEOService,
    public dataLayerService: DataLayerService,
    public authService: AuthService,
    private router: Router,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private dashboard: DashboardService,
    private ngZone: NgZone,
    private localStorageService: LocalStorageService,
    private pwaService: PwaService,
    private modalService: NgbModal,
    private sessionTimeoutService: SessionTimeoutService,
    private authAdnService: AuthAdnService,
  ) {
    seoService.initSEOConfig();
    dataLayerService.initDataLayerConfig();
  }

  ngOnInit() {
    this.localStorageService.remove('reloaded');
    this.validateSession();
    this.validateRouterInit();
    this.validateIsLogged();
    this.validateUserProfile();
    this.isInstalledPWA();
    this.permissionInstallPWA();
    this.openModalTermsAndConditions();
    this.sessionTimeoutService.startMonitoring();

    this.sessionTimeoutService.sessionWarning$.subscribe((logoutTime) => {
      const adnUserProfile = this.localStorageService.get('adnUserProfile');
      if (adnUserProfile) {
        this.showModal(logoutTime);
      }
    });
  }

    showModal(logoutTime: number){
      this.modalRef = this.modalService.open(ModalWarningTimeComponent, { windowClass: 'modal-warning-time' });
      this.modalRef.componentInstance.logoutTime = logoutTime;
      this.modalRef.result.then((on) => {
        if (on) {
          this.authAdnService.adnCloseSession();
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  permissionInstallPWA() {
    if (RegExp(/iPhone|iPad|iPod/i).exec(navigator.userAgent)) {
      const link = document.getElementById('PWA-manifest');
      link?.remove();
    }
  }

  isInstalledPWA() {
    if (this.isLogged) {
      this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
        this.isLoggedPWA(user?.userId!);
      });
    } else {
      this.isLoggedPWA(null);
    }
  }

  isLoggedPWA(user: any) {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.pwaService.changePWA(true);
      if (this.localStorageService.get('isInstalledPWA') == null) {
        this.localStorageService.set('isInstalledPWA', 'true');
        this.getIP(user);
        this.isPwaOrWeb(false, user);
      } else {
        this.isPwaOrWeb(false, user);
      }
    } else {
      this.pwaService.changePWA(false);
      this.isPwaOrWeb(true, user);
    }
  }

  getIP(user: any) {
    fetch('https://api.ipify.org/?format=json', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        this.saveUserInstallPWA(user, data.ip);
      })
      .catch((error) => {
        this.saveUserInstallPWA(user, null);
      });
  }

  saveUserInstallPWA(user: any, ip: any) {
    const body = {
      userId: user,
      eventName: 'DownloadYevoApp',
      ipAddress: ip,
      userAgent: navigator.userAgent,
    };

    this.pwaService.registerPWA(body).subscribe();
  }

  isPwaOrWeb(web: boolean, user: any) {
    if (web && sessionStorage.getItem('validatePWA') == null) {
      this.dataLayerService.publicWEB(user);
    } else if (!web && sessionStorage.getItem('validatePWA') == null) {
      this.dataLayerService.publicPWA(user);
    }
    sessionStorage.setItem('validatePWA', 'true');
  }

  /**
   * Validate if the user has a valid session
   */
  validateSession() {
    if (this.localStorageService.get('refresh_token') != null) {
      this.dashboard.verifyToken().subscribe();
    }
    if (!this.isNewTab()) {
      if (this.isLogged && this.localStorageService.get('refresh_token') == null) {
        this.authService.logout();
      }
    }

    this.localStorageService.set('reloaded', 'yes');
  }

  validateRouterInit() {
    this.subscription$.add(
      this.router.events.subscribe((event) => {
        if (event instanceof RouteConfigLoadStart) {
          this.loaderService.setIsLoadingLazyPage(true);
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loaderService.setIsLoadingLazyPage(false);
        }
      })
    );
  }

  validateUserProfile() {
    this.subscription$.add(
      this.router.events
        .pipe(
          first(
            (event) =>
              event instanceof NavigationEnd &&
              (function getRoute(r: ActivatedRoute): ActivatedRoute {
                return r.firstChild ? getRoute(r.firstChild) : r;
              })(this.activatedRoute).outlet === 'primary'
          )
        )
        .subscribe(() =>
          this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
            this.dataLayerService.pushUserSession(user?.userId!, user?.businessName!);
          })
        )
    );
  }

  validateIsLogged() {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLogged = true;

        let page = this.localStorageService.get('activePageUrl');

        const params = window.location.search
          .slice(1)
          .split('&')
          .map((p) => p.split('='))
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

        if (page === '/') {
          page = '/nuevo-intranet/dashboard';
        }

        if (page != window.location.pathname) {
          page = window.location.pathname == '/' ? '/nuevo-intranet/dashboard' : window.location.pathname;
        }

        this.ngZone.run(() => {
          this.router.navigate([page], { queryParams: params, queryParamsHandling: 'merge' });
        });
      } else {
        this.isLogged = false;
      }
    });
  }

  isNewTab() {
    return history.length == 1;
  }

  openModalTermsAndConditions() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
      if (this.isLogged && !event.url.includes('/terminos-y-condiciones')) {
        this.authService.getUserProfile().subscribe((data) => {
          const user: any = JSON.parse(this.localStorageService.get('userProfile') ?? '{}');

          if (!user?.modalViewed) {
            setTimeout(() => {
              this.modalService.dismissAll();
              this.modalService
                .open(ModalTermsConditionsPoliciesComponent, this._modalOptionsNormalNewTerms)
                .result.then();
            }, 2000);
          }
        });
      }
    });
  }
}