import { Injectable, NgZone } from "@angular/core";
import { AuthAdnService } from "./auth-adn.service";
import { LocalStorageService } from "./local-storage.service";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class SessionTimeoutService {
    private inactivityTime: number = 10 * 60 * 1000; // 10 minutos
    private warningTime: number = 9 * 60 * 1000; 
    private timeoutId: any;
    private warningId: any
    private logoutTime!: number;
    sessionWarning$ = new Subject<number>();
  
    constructor(private localStorageService: LocalStorageService, private ngZone: NgZone,private authAdnService: AuthAdnService) {}
  
    startMonitoring(): void {
      this.resetTimer();
      this.setupListeners();
    }
  
    private setupListeners(): void {
      const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];
      events.forEach(event => document.addEventListener(event, this.resetTimer.bind(this)));

      window.addEventListener('beforeunload', this.handleUnload.bind(this));
    }
  
    private resetTimer(): void {
      this.logoutTime = Date.now() + this.inactivityTime;
      this.clearTimer();
      this.warningId = setTimeout(() => this.sessionWarning$.next(this.logoutTime), this.warningTime);
      this.timeoutId = setTimeout(() => this.logout(), this.inactivityTime);
    }
  
    private clearTimer(): void {
      if (this.timeoutId) {clearTimeout(this.timeoutId);}
      if (this.warningId) clearTimeout(this.warningId);
    }
  
    private logout(): void {
      this.ngZone.run(() => {
        const adnUserProfile = this.localStorageService.get('adnUserProfile');
        if (adnUserProfile) {
            this.authAdnService.adnCloseSessionTimeout();
            this.removeListeners();
        }
      });
    }

    openModal(){
      
    }

    private removeListeners(): void {
      const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];
      events.forEach(event => document.removeEventListener(event, this.resetTimer.bind(this)));
    
      window.removeEventListener('beforeunload', this.handleUnload.bind(this));
    }

    private handleUnload(event: Event): void {
      this.authAdnService.adnCloseSession();
    }
  }