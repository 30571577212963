import { Injectable } from '@angular/core';
import { AdnData, SimulationResult } from '@shared/interfaces/frontend/adn';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdnCentralizedDataService {
  private readonly INITIAL_KEY = 'initialData';
  private readonly CURRENT_KEY = 'currentData';

  private currentDataSubject = new BehaviorSubject<AdnData | null>(null);
  currentData$: Observable<AdnData | null> = this.currentDataSubject.asObservable();

  private readonly INITIAL_KEY_DATA = 'initialSimulationData';
  private readonly CURRENT_KEY_DATA = 'currentSimulationData';

  private simulatorDataSubject = new BehaviorSubject<SimulationResult | null>(null);
  simulatorData$: Observable<SimulationResult | null> = this.simulatorDataSubject.asObservable();

  constructor() {
    const storedData = this.getFromLocalStorage(this.CURRENT_KEY);
    const storedSimulatorData = this.getSimulatorFromLocalStorage(this.CURRENT_KEY_DATA);
    if (storedData) {
      this.currentDataSubject.next(storedData);
    }

    if (storedSimulatorData) {
      this.simulatorDataSubject.next(storedSimulatorData);
    }
  }

  setInitialSimulatorData(data: SimulationResult): void {
    this.saveSimulatorToLocalStorage(this.INITIAL_KEY_DATA, data);
    this.saveSimulatorToLocalStorage(this.CURRENT_KEY_DATA, data);
    this.simulatorDataSubject.next(data);
  }

  resetSimulatorToInitialData(): void {
    const initialData = this.getSimulatorFromLocalStorage(this.INITIAL_KEY_DATA);
    if (initialData) {
      this.simulatorDataSubject.next(initialData);
      this.saveSimulatorToLocalStorage(this.CURRENT_KEY_DATA, initialData);
    } else {
      console.warn('No initial data found to reset.');
    }
  }

  clearSimulatorDataOnLogout(): void {
    localStorage.removeItem(this.INITIAL_KEY_DATA);
    localStorage.removeItem(this.CURRENT_KEY_DATA);
    this.simulatorDataSubject.next(null); 
  }

  private saveSimulatorToLocalStorage(key: string, data: SimulationResult): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.error(`Error saving ${key} to localStorage:`, error);
    }
  }
  private getSimulatorFromLocalStorage(key: string): SimulationResult | null {
    try {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error(`Error retrieving ${key} from localStorage:`, error);
      return null;
    }
  }

  setInitialData(data: AdnData): void {
    this.saveToLocalStorage(this.INITIAL_KEY, data);
    this.saveToLocalStorage(this.CURRENT_KEY, data);
    this.currentDataSubject.next(data);
  }

  updateCurrentData(updates: Partial<AdnData>): void {
    const currentData = this.currentDataSubject.value || { achievements: [], targets: [] };
    const updatedData = { ...currentData, ...updates };
    this.saveToLocalStorage(this.CURRENT_KEY, updatedData);
    this.currentDataSubject.next(updatedData);
  }

  resetToInitialData(): void {
    const initialData = this.getFromLocalStorage(this.INITIAL_KEY);
    if (initialData) {
      this.currentDataSubject.next(initialData);
      this.saveToLocalStorage(this.CURRENT_KEY, initialData);
    } else {
      console.warn('No initial data found to reset.');
    }
  }

  private saveToLocalStorage(key: string, data: AdnData): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.error(`Error saving ${key} to localStorage:`, error);
    }
  }

  private getFromLocalStorage(key: string): AdnData | null {
    try {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error(`Error retrieving ${key} from localStorage:`, error);
      return null;
    }
  }
}
