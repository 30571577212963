<div class="content-variable">
  <div class="content-variable_action">
    <div class="content-variable_action__title">RV por variables directas</div>
    <a class="p-link" (click)="openModal()">¿Cómo se calcula?</a>
  </div>
  <div >
    <div class="content-variable__content mb-4">
      <div class="d-flex justify-content-end rv_flag_container">
        <div class="rv_flag" [ngClass]="{'active': flagActivateRV, 'inactive': !flagActivateRV}">
          <p class="rv_flag__description">{{ flagActivateRV ? 'Activaste RV 🔥' : 'Aún no activas RV 😥' }}</p>
        </div>
      </div>
      <milab-cumplirme-card
        class="simulador_section__card"
        title="Cumplimiento RV"
        [weightedComplianceInitialComercial]="weightedComplianceComercialInitial"
        [weightedComplianceInitialCobranza]="weightedComplianceCobranzaInitial"
        [amountDistributed]="amountDistributed"
        (flagActivateRV)="handleFlagActivateRV($event)"
      >
      </milab-cumplirme-card>
    </div>    
  </div>
</div>

<div class="section-variable-card pd-0-20 d-none d-md-block">
  <milab-variables-card 
    [listVariable]="listVariable" 
    variableItem="Variable comerciales"
    [managementItem]="managementVarCommercial"
    [amount]="amount">
  </milab-variables-card>
</div>

<div class="section-variable-card mt-3 pd-0-20 d-none d-md-block">
  <milab-variable-collection-card 
  [listVariablePise9]="listCollectionVariablesPise9"
  [listVariableResolutions]="listCollectionVariablesResolutions" 
  [managementItem]=" managementVarCollectionPise"
  [managementItemTwo]="managementVarCollectionResolution"
  variableItem="Variable de cobranza">
  </milab-variable-collection-card>
</div>

<div class="variables-card-mobile d-block d-lg-none">
  <milab-tab-options
    id="tap-taxis"
    tabNameOne="Var. Comerciales"
    tabNameTwo="Var. de Cobranza"
   (tabChangeButtonEvent)="tabOptions($event)">
</milab-tab-options>

  <div *ngIf="isVarComercer">
    <milab-variable-card-mobile 
      [listVariable]="gestionPreciosList"
      [amount]="amount">
    </milab-variable-card-mobile>

    <div>
      <milab-variable-collection-card-mobile 
        [variable]="listVariable[3]" 
        [managementItem]="managementVarCommercial">
      </milab-variable-collection-card-mobile>
    </div>
  </div>

  <div *ngIf="!isVarComercer">
    <div >
      <milab-variable-collection-card-mobile
        [variable]="listCollectionVariablesPise9[0]"
        [managementItem]="managementVarCollectionPise">
      </milab-variable-collection-card-mobile>
    </div>
    <div>
      <milab-variable-collection-card-mobile
        [variable]="listCollectionVariablesResolutions[0]"
        [managementItem]="managementVarCollectionResolution">
      </milab-variable-collection-card-mobile>
    </div>
  </div>
</div>