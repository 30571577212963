<div class="bonus-card">
    <div class="bonus-card__title">{{ title }}</div>
    <div class="bonus-card__amount">
      <div class="bonus-card__amount-value color-dark"><span>S/ </span>{{ valueString }}</div>
    </div>
    <milab-increase-card *ngIf="increase.length>0" [increase]="increase"></milab-increase-card>
    <div class="bonus-card__cump">
        <div class="t-12-400 font-lato color-dark">Desembolsos 0-20k ≥ 90%</div>
        <img *ngIf="iconBonus.length>0"
             class="bonus-card__amount-icon" 
             [src]="iconBonus" 
             alt="icon">
    </div>
    <div class="t-12-400 font-lato color-dark">Logro (S/): 
      <span class="t-12-700 font-lato " [ngStyle]="{'color': advance>90 ? '#00B33C' : '#F4323F'}">
        {{ amount20kNumber | number:'1.0-0'  }}
      </span> / {{ meta20kString }}
    </div>
  </div>