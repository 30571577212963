import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { INCREASE, RV_DATALAYER } from '@shared/constants/adn-simulator.constants';
import { InputItem, managementItem, VariableDirect } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { calculateCompliance, 
         calculateComplianceStok, 
         calculateDivisionInput, 
         calculateLiabilitiesManagementCompliance, 
         calculatePriceManagementCompliance, 
         complianceColorValid, 
         convertToNumeric, 
         convertToNumericWithCommas, 
         formatPercentage } from '@shared/utils/adn-simulator.utils';
@Component({
  selector: 'milab-variables-card',
  templateUrl: './variables-card.component.html',
  styleUrls: ['./variables-card.component.scss']
})
export class VariablesCardComponent implements OnInit, OnChanges{
  @Input() listVariable: VariableDirect[] = [];
  @Input() variableItem: string = '';
  @Input() managementItem!: managementItem;
  @Input() amount: number = 0;
  variablesDirectGestion: any;
  currentValue: any= { value: 0, cumplimiento: 0 };
  isMobile: boolean = window.innerWidth <= 768;
  constructor(private adnSimulatorService: AdnSimulatorService,
              private cdr: ChangeDetectorRef,
              private dataLayerService: DataLayerService) { }
  ngOnChanges(changes: SimpleChanges): void {
   if (changes.amount && changes.amount.currentValue) {
    if(!this.isMobile){
      this.updateLogroFromOutside('neto', changes.amount.currentValue);
    }
     this.amount= 0;
   }
  }

  ngOnInit(): void {
    this.updateIsMobile();
    window.addEventListener('resize', this.updateIsMobile);
  }
  onLogroChange(event: InputItem): void {
    const variable = this.listVariable.find(item => item.id === event.key);
    if (variable && variable.metas) {
      variable.logro = event.value;
      this.dataLayerService.simulatorRVSelect(RV_DATALAYER.actionTabValues,`${ event.name}: ${event.value.toString()}`);

      if (variable.id === 'neto') {
        const [minDisbursement, disbursement100, disbursement150, disbursement200] = variable.metas.content[0].map(convertToNumericWithCommas);
        const dataCumplimiento = calculateCompliance(
          convertToNumeric(event.value),
          minDisbursement,
          disbursement100,
          disbursement150,
          disbursement200
        );

        this.updateIncrease(variable, dataCumplimiento);
        this.adnSimulatorService.updateComplianceNetDisbursement(convertToNumeric(dataCumplimiento));
        variable.cumplimiento = dataCumplimiento.toString().concat('%');
        variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimiento));
      }
      if (variable.id === 'stocks') {
        const [minClients, clients100, clients150, clients200] = variable.metas.content[0].map(convertToNumericWithCommas);
        const dataCumplimientoStock = calculateComplianceStok(
          convertToNumeric(event.value),
          minClients,
          clients100,
          clients150,
          clients200
        );
        this.updateIncrease(variable, dataCumplimientoStock);
        this.adnSimulatorService.updateComplianceActiveClientStock(convertToNumeric(dataCumplimientoStock));
        variable.cumplimiento = dataCumplimientoStock.toString().concat('%');
        variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimientoStock));
      }
      if (variable.id === 'pasivos') {
        const [minLiabilities, liabilities100, liabilities150, liabilities200,liabilities300,liabilities400] = variable.metas.content[0].map(convertToNumericWithCommas);
        const dataCumplimientoPasive = calculateLiabilitiesManagementCompliance(
          convertToNumeric(event.value),
          minLiabilities,
          liabilities100,
          liabilities150,
          liabilities200,
          liabilities300,
          liabilities400
        );
        this.updateIncrease(variable, dataCumplimientoPasive);
        this.adnSimulatorService.updateComplianceLiabilitiesCapture(convertToNumeric(dataCumplimientoPasive));
        variable.cumplimiento = dataCumplimientoPasive.toString().concat('%');
        variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimientoPasive));
      }
      this.cdr.detectChanges();
    }
   }

   public updateLogroFromOutside(id: string, value: number): void {
    const variable = this.listVariable.find(item => item.id === id);
    if (variable) {
      variable.logro = (parseFloat(variable.logro.toString()) + value);
      const [minDisbursement, disbursement100, disbursement150, disbursement200] = variable.metas.content[0].map(convertToNumericWithCommas);
      const dataCumplimiento = calculateCompliance(
        variable.logro,
        minDisbursement,
        disbursement100,
        disbursement150,
        disbursement200
      );
      this.updateIncrease(variable, dataCumplimiento);
      variable.cumplimiento = dataCumplimiento.toString().concat('%');
      variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimiento));
      if (variable.id === 'neto') {
        this.adnSimulatorService.updateComplianceNetDisbursement(convertToNumeric(dataCumplimiento));
      }
  
      this.cdr.detectChanges();
    }
  }

  onManagementValueChange(event: InputItem): void {
    const taza = calculateDivisionInput(event.value,convertToNumeric(this.managementItem.valueTwo));
    if (event.key === 'gestionPrecios') {
      this.dataLayerService.simulatorRVSelect(RV_DATALAYER.actionTabValues,`${ RV_DATALAYER.eventInputGestionPrice}: ${event.value.toString()}`);
      this.managementItem.valueOne = event.value;
      const variable = this.listVariable.find(item => item.id === event.key);
      if (variable) {
        variable.logro = taza;
        const [minPrices, prices100, prices125, prices150] = variable.metas.content[0].map(parseFloat);
        const dataCumplimientoGestionPrecios = calculatePriceManagementCompliance(
          taza,
          minPrices,
          prices100,
          prices125,
          prices150
        );
        this.updateIncrease(variable, dataCumplimientoGestionPrecios);
        this.adnSimulatorService.updateCompliancePriceManagement(convertToNumeric(dataCumplimientoGestionPrecios));
        variable.cumplimiento = dataCumplimientoGestionPrecios.toString().concat('%');
        variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimientoGestionPrecios));
        this.cdr.detectChanges();
      }
    }
  }

  private updateIncrease(variable: VariableDirect, dataCumplimiento: number): void {
    const increase = dataCumplimiento - variable.cumplimientoInit;
    if (increase > 0) {
      variable.increase = formatPercentage(increase);
      variable.increaseColor = INCREASE.colorIncrement;
      variable.increaseIcon = INCREASE.iconArrowUp;
    } else if (increase < 0) {
      variable.increase = formatPercentage(increase);
      variable.increaseColor = INCREASE.colorDecrement;
      variable.increaseIcon = INCREASE.iconArrowDown;
    } else {
      variable.increase = '';
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.updateIsMobile);
  }

  updateIsMobile = (): void => {
    this.isMobile = window.innerWidth <= 768;
  }
}


