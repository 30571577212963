import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InputItem } from '@shared/interfaces/frontend/adn';

@Component({
  selector: 'milab-management-collections-card',
  templateUrl: './management-collections-card.component.html',
  styleUrls: ['./management-collections-card.component.scss']
})
export class ManagementCollectionsCardComponent implements OnInit, OnChanges {
  @Input() itemLabelOne: string = '';
  @Input() itemLabelTwo: string = '';
  @Input() valueOne!: number;
  @Input() key: string = '';
  @Input() valueTwo: string = '';

  @Output() managementValueChange = new EventEmitter<InputItem>();
  item:InputItem = { key: '', value: 0 ,name:''};
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.valueOne && changes.valueOne.currentValue){
      this.item = { key: this.key, value: this.valueOne,name:'' };
    }
  }

  ngOnInit(): void {
    this.item = { key: this.key, value: this.valueOne,name:'' };
  }
 
  onValueChangeManagement(event: InputItem) {
    this.managementValueChange.emit(event);
  }

}
