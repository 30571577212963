import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AdnAchievement, AdnModal, AdnTarget,managementItem, VariableDirect } from '@shared/interfaces/frontend/adn';
import {variablesDirectGestionJson} from '@shared/data/adn/variables.json';
import { simulatorInformation} from '@shared/data/adn/modal.data.json';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalInformativeComponent } from '../modal-informative/modal-informative.component';
import { ModalDisbursementComponent } from '../modal-disbursement/modal-disbursement.component';
import { INCREASE } from '@shared/constants/adn-simulator.constants';

@Component({
  selector: 'milab-section-direct-variables',
  templateUrl: './section-direct-variables.component.html',
  styleUrls: ['./section-direct-variables.component.scss']
})
export class SectionDirectVariablesComponent implements OnInit, OnChanges{
  modalRef!: NgbModalRef;
  numberValue: string = '';
  variablesDirectGestion: any[] = [];
  headers: string[] = [];
  isTable: boolean = false;
  isRotated: boolean = false;
  isVarComercer: boolean = true;

  amount:number = 0;

  targets: AdnTarget[] = [];
  achievements: AdnAchievement[] = [];

  @Input() managementVarCommercial!: managementItem;
  @Input() managementVarCollectionResolution!: managementItem;
  @Input() managementVarCollectionPise!: managementItem;

  @Input() listVariable: VariableDirect[] = [];
  @Input() listCollectionVariablesPise9: VariableDirect[] = [];
  @Input() listCollectionVariablesResolutions: VariableDirect[] = [];

  @Input() directRemunerationValue: number = 0;
  @Input() weightedComplianceComercialInitial: number = 0;
  @Input() weightedComplianceCobranzaInitial: number = 0;
  @Input() amountDistributed: number = 0;
  @Input() pasivosMeta:any;

  weightedCompliance: number = 0;
  directRemunerationInitial: number = 0;
  weightedComplianceString: string = '0';
  imgComplianceWeight: string = '/assets/icons/adn/x-close.svg';

  gestionPreciosList: VariableDirect[] = [];

  increaseCompliance: string ='';
  increaseDistributed: string ='';
  complianceColor: string = INCREASE.colorIncrement;
  distributedColor: string = INCREASE.colorIncrement;
  iconCompliance: string =INCREASE.iconArrowUp;
  iconDistributede: string =INCREASE.iconArrowUp;
  flagActivateRV: boolean = false;
  simulatorInformation!:AdnModal;
  
  constructor(private modalService: NgbModal) {}
  ngOnInit(): void {
    this.simulatorInformation = simulatorInformation;
    this.loadVariables();
    this.gestionPreciosList = this.listVariable.filter(variable => variable.id != 'gestionPrecios');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listVariable) {
      this.gestionPreciosList = this.listVariable.filter(variable => variable.id != 'gestionPrecios');
    }
  }
  loadVariables(): void {
    this.variablesDirectGestion = variablesDirectGestionJson;
  }

  newAmount(){
   this.openModalDisbursement();
  }
  openModal() {
    this.modalRef = this.modalService.open(ModalInformativeComponent, { windowClass: 'modal-informative' });
    this.modalRef.componentInstance.modalItem = this.simulatorInformation;
    this.modalRef.result.then((on) => {
    }).catch((error) => {
      console.log(error);
    });
  }

  openModalDisbursement(){
    this.modalRef = this.modalService.open(ModalDisbursementComponent, { windowClass: 'modal-disbursement' });
    this.modalRef.result.then((on) => {
      if(on){
        this.amount = on;
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  tabOptions(event: string): void {
    if(event === 'Var. Comerciales'){
      this.isVarComercer = true;
  }else{
    this.isVarComercer = false;
  }
}

  activeTable(){
    this.isTable = !this.isTable;
    this.isRotated = !this.isRotated;
  }

  handleFlagActivateRV(value: boolean): void {
    this.flagActivateRV = value;
  }
}