import { ComplianceCurveData, ComplianceCurveRange } from "@shared/interfaces/frontend/adn";

export function calculateWeightedCompliance(weightedCompliance: number,rate:number): number {
  try {
      return weightedCompliance >= rate ? 1 : 0; 
  } catch (error) {
    return 0;
  }
}

export function calculateFactorAbsence(value: number): number {
  const maxValue = 30;
  const percentage = value / maxValue;
  const result = percentage > 1 ? 1 : percentage;
  return parseFloat(result.toFixed(2));
}
  
export function calculateDirectRemuneration(
  amountDistributed: number, 
  weightedCompliance: number, 
  correctCompliance: number  
): number {
  try {

    if (isNaN(amountDistributed) || isNaN(weightedCompliance) || isNaN(correctCompliance)) {
      return 0;
    }

    const result = (amountDistributed * weightedCompliance * correctCompliance) / 100;
    return parseFloat(result.toFixed(2));
  } catch (error) {
    return 0;
  }
}  

  export function complianceColor(O21: number, Q21: number, R21: number): string {
    try {
      if (O21 < Q21) return "red";
      if (O21 <= R21) return "yellow";
      return "green";
    } catch (error) {
      return "red";
    }
  }

  export function complianceColorValid(value: number): string {
      if (value < 30) return "red";
      if (value <= 100) return "yellow";
      return "green";
  }
  
  export function convertToNumericWithCommas(value: string): number {
    return parseFloat(value.replace(/,/g, ''));
  }
  export function formatNumberWithCommas(value: number): string {
    const parts = value.toFixed(2).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  export function formatNumberWithCommasNoDecimals(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  export function formatNumber(value: string): string {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  export function formatNumberValidations(value: string): string {
    if (!value) return '0';

    value = value.replace(/^,+|,+$/g, '');
    value = value.replace(/,{2,}/g, ',');

    value = value.replace(/,(\d+)/g, '$1');
    value = value.replace(/\.{2,}/g, '.');

    const parts = value.split('.');
    if (parts.length > 2) {
        value = `${parts[0]}.${parts.slice(1).join('')}`;
    }
    
    const [integerPart, decimalPart] = value.split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    value = decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;

    return value;
}


  export function formatPercentage(value: number): string {
    return `${value.toFixed(2)}%`;
  }
  export function formatPercentageV2(value: number): string {
    return `${value.toFixed(2)}`;
  }

  export function truncateToTwoDecimals(value: number): string {
    const valueStr = value.toString();
    const decimalIndex = valueStr.indexOf('.');

    if (decimalIndex === -1) {
        return `${value}.00`;
    }
    const integerPart = valueStr.substring(0, decimalIndex);
    const decimalPart = valueStr.substring(decimalIndex + 1, decimalIndex + 3);

    return `${integerPart}.${decimalPart.padEnd(2, '0')}`;
}

  export function extractNumber(inputString: string): number {
    const match = inputString.match(/-?\d+/);
    if (match) {
      return parseInt(match[0], 10);
    } else {
      return Number.MAX_SAFE_INTEGER;
    }
  }
  export function removePercentage(inputString: string):number {
    if (!inputString || typeof inputString !== "string") {
        return 0; 
    }
    
    const numbers = inputString.match(/\d+(\.\d+)?/g);
    return numbers ? parseFloat(numbers[0]) : 0; 
}

  export function convertToPercentageDecimal(value: number): string {
    return `${(value * 100).toFixed(2)}%`;
  }

  export function  convertToPercentage(value: number): string {
    return `${(value * 100)}%`;
  }
  export function convertToPercentageV2(value: number): string {
    return `${(value * 100).toFixed(2)}`;
  }
  export function convertToPercentageV3(value: number): string {
    return `${(value * 100).toFixed(3)}`;
  }

  export function calculateDivision(dividend: number, divisor: number): number {
    if (divisor === 0) {
      return 0; 
    }
    const result = dividend / divisor;
    return parseFloat(result.toFixed(2)); 
  }

  export function division(dividend: number, divisor: number): number {
    if (divisor === 0) {
      return 0; 
    }
    const result = dividend / divisor;
    return result; 
  }

  export function calculateDivisionInput(dividend: number, divisor: number): number {
    if (divisor === 0) {
      return 0;
    }
    const result = dividend / divisor;
    return parseFloat(convertToPercentageV2(result));
  }
  export function divideByHundred(value: number): number {
    return value / 100;
  }

  export function convertToNumeric(value: any): number {
    return parseFloat(value);
  }
  
  export function convertIntegerToString(value: number): string {
    return value.toString();
  }

  export function formatDateUtil(inputDate: string): string {
    let date: Date;

    if (/^\d{8}$/.test(inputDate)) {
        const year: number = parseInt(inputDate.substring(0, 4), 10);
        const month: number = parseInt(inputDate.substring(4, 6), 10) - 1; 
        const day: number = parseInt(inputDate.substring(6, 8), 10);
        date = new Date(year, month, day);
    } 

    else if (!isNaN(Date.parse(inputDate))) {
        date = new Date(inputDate);
    } 
    else {
        throw new Error("Formato de fecha no válido");
    }
    const months = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.", 
      "Jul.", "Ago.", "Sep.", "Oct.", "Nov.", "Dic."];

    const day: number = date.getUTCDate();
    const month: string = months[date.getUTCMonth()];
    const year: number = date.getUTCFullYear();

    return `${month} ${day}, ${year}`;
  }

  export function overachievementPercentage(porcentaje: number, autonomia: 'sin autonomia' | 'con autonomia'): number {
    if (porcentaje < 0) {
     return 0;
    }
  
    const rangos: { [key: number]: { sinAutonomia: number, conAutonomia: number } } = {
      100: { sinAutonomia: 100, conAutonomia: 100 },
      110: { sinAutonomia: 102, conAutonomia: 110 },
      120: { sinAutonomia: 105, conAutonomia: 112 },
      130: { sinAutonomia: 108, conAutonomia: 115 },
      150: { sinAutonomia: 110, conAutonomia: 117 },
      Infinity: { sinAutonomia: 113, conAutonomia: 120 }
    };
  
    let valor: number = 0;
  
    for (let limite of Object.keys(rangos).map(Number)) {
      if (porcentaje < limite) {
        valor = rangos[limite][autonomia === 'sin autonomia' ? 'sinAutonomia' : 'conAutonomia'];
        break;
      }
    }
  
    return valor;
  }

  export function calculateDecelerator(percentage: number): number {
    const response = 15;
    const ranges: { lower: number; upper: number; decelerator: number }[] = [
      { lower: 0, upper: 40, decelerator: 15 },
      { lower: 40, upper: 80, decelerator: 10 },
      { lower: 80, upper: 100, decelerator: 5 },
      { lower: 100, upper: 120, decelerator: 0 },
      { lower: 120, upper: 180, decelerator: -15 },
      { lower: 180, upper: 250, decelerator: -20 },
      { lower: 250, upper: Infinity, decelerator: -30 },
    ];
  
    for (const range of ranges) {
      if (percentage > range.lower && percentage <= range.upper) {
        return range.decelerator;
      }
    }

    return response;
  }

  export function calculateMultiplier(percentage: number): number {
    const response = 115;
    const ranges: { lower: number; upper: number; multiplier: number }[] = [
      { lower: 0, upper: 40, multiplier: 115 },
      { lower: 40, upper: 80, multiplier: 110 },
      { lower: 80, upper: 100, multiplier: 105 },
      { lower: 100, upper: 120, multiplier: 100 },
      { lower: 120, upper: 160, multiplier: 90 },
      { lower: 160, upper: 200, multiplier: 87.5 },
      { lower: 200, upper: Infinity, multiplier: 85 },
    ];
  
    for (const range of ranges) {
      if (percentage > range.lower && percentage <= range.upper) {
        return range.multiplier;
      }
    }
  
    return response;
  }


  export function calculateCompliance(O21: number, Q21: number, R21: number, S21: number, T21: number): number {
    const R19 = 1.0;
    const S19 = 1.5;
    const T19 = 2.0;
    if (O21 < Q21) return 0;
  
    if (O21 <= R21) return roundToTwoDecimals((O21 / R21) * 100);
  
    const ranges = [
      { x: [R21, S21], y: [R19, S19] },
      { x: [S21, T21], y: [S19, T19] }
    ];
  
    for (const range of ranges) {
      if (O21 <= range.x[1]) {
        return roundToTwoDecimals(forecast(O21, range.x, range.y) * 100);
      }
    }
  
    const lastRange = ranges[ranges.length - 1];
    const forecasted = forecast(O21, lastRange.x, lastRange.y);
    return roundToTwoDecimals(forecasted * 100);
  }
    
  export function forecast (x: number, xValues: number[], yValues: number[]): number {
    const [x1, x2] = xValues;
    const [y1, y2] = yValues;
  
    if (x2 === x1) throw new Error('Division by zero in forecast calculation');
  
    return y1 + ((x - x1) * (y2 - y1)) / (x2 - x1);
  };


  export function roundToTwoDecimals(value: number): number{return Math.round(value * 100) / 100;}
  

export function calculateComplianceStok(O25: number, Q25: number, R25: number, S25: number, T25: number): number{
  const Q23 =  0.3;
  const R23 =  1.0;
  const S23 = 1.5;
  const T23 = 2.0;
  
  if (O25 < Q25) return 0;

    if (O25 <= R25) {
      return roundToTwoDecimals(forecast(O25, [Q25, R25], [Q23, R23])* 100);
    }

    if (O25 <= S25) {
      return roundToTwoDecimals(forecast(O25, [R25, S25], [R23, S23])* 100);
    }

    if (O25 <= T25) {
      return roundToTwoDecimals(forecast(O25, [S25, T25], [S23, T23])* 100);
    }

    return roundToTwoDecimals(forecast(O25, [S25, T25], [S23, T23]) * 100);
  }

  export function calculateLiabilitiesManagementCompliance(
    O29: number,
    Q29: number,
    R29: number,
    S29: number,
    T29: number,
    U29: number,
    V29: number
  ): number{
    const R19 = 1;
    const S19 = 1.5;
    const T19 = 2;
    const U19 = 3;
    const V19 = 4;
  
    if (O29 < Q29) return 0;

    if (O29 <= R29) return roundToTwoDecimals((O29 / R29) * 100);
  
    const ranges = [
      { x: [R29, S29], y: [R19, S19] },
      { x: [S29, T29], y: [S19, T19] },
      { x: [T29, U29], y: [T19, U19] },
      { x: [U29, V29], y: [U19, V19] },
    ];
  
    for (const range of ranges) {
      if (O29 <= range.x[1]) {
        const forecasted = forecast(O29, range.x, range.y);
        return roundToTwoDecimals(forecasted * 100);
      }
    }
  
    const lastRange = ranges[ranges.length - 1];
    const forecasted = forecast(O29, lastRange.x, lastRange.y);
    return roundToTwoDecimals(forecasted * 100);
  }
  


  export function calculatePriceManagementCompliance(
    O33: number,
    Q33: number,
    R33: number,
    S33: number,
    T33: number
  ): number {
    const Q31 = 0;
    const R31 = 1;
    const S31 = 1.25;
    const T31 = 1.5;
  
  if (O33 === null || O33 === undefined) return 0;
  if (O33 <= Q33) return 0;

  const ranges = [
    { x: [Q33, R33], y: [Q31, R31] }, 
    { x: [R33, S33], y: [R31, S31] },
    { x: [S33, T33], y: [S31, T31] }
  ];

  for (const range of ranges) {
    if (O33 <= range.x[1]) {
      const interpolatedValue = forecast(O33, range.x, range.y);
      return roundToTwoDecimals(interpolatedValue*100);
    }
  }

  const lastRange = ranges[ranges.length - 1];
  const extrapolatedValue = forecast(O33, lastRange.x, lastRange.y);
  return roundToTwoDecimals(extrapolatedValue*100);

  }

  export function calculateMinValueBonus(BB44: number, H46: number): number {
    try {

      if (isNaN(BB44) || isNaN(H46)) {
        throw new Error("Invalid input values");
      }

      const result = (BB44 * H46) / 30;

      return Math.min(result, 450);
    } catch (error) {
      console.error("Error during calculation:", error);
      return 0;
    }
  }

  export function calculateBonus(BC41: number, BC42: number, BE36: number): number {
    try {
      if (isNaN(BC41) || isNaN(BC42) || isNaN(BE36)) {
        throw new Error("Invalid input values");
      }
  
      const result = (BC41 * BC42 * BE36 * 300)/100;
      return Math.min(result, 450);
    } catch (error) {

      console.error("Error during bonus calculation:", error);
      return 0;
    }
  }

  export function calculateMoraCompliance(
    O39: number,
    Q39: number,
    R39: number,
    S39: number,
    T39: number
  ): number {
    const Q31 = 0.3;
    const R31 = 1; 
    const S31 = 1.25;
    const T31 = 1.5;

    if (O39 === null || O39 === undefined || isNaN(O39)) return 0;
  
    if (O39 >= Q39) return 0;
  
    const ranges = [
      { x: [Q39, R39], y: [Q31, R31] },
      { x: [R39, S39], y: [R31, S31] },
      { x: [S39, T39], y: [S31, T31] },
    ];

    for (const range of ranges) {
      if (O39 <= range.x[0] && O39 > range.x[1]) {
        const interpolatedValue = forecast(O39, range.x, range.y);
        return roundToTwoDecimals(interpolatedValue * 100);
      }
    }

    if (O39 <= T39) {
      const lastRange = ranges[ranges.length - 1];
      const interpolatedValue = forecast(O39, lastRange.x, lastRange.y);
      return roundToTwoDecimals(interpolatedValue * 100);
    }
    return 0;
  }

  export function calculateResolutionCompliance(
    O43: number,
    Q43: number,
    R43: number,
    S43: number,
    T43: number
  ): number {
 
    const Q37 = 0.3;
    const R37 = 1;
    const S37 = 1.25;
    const T37 = 1.5;

    if (O43 < Q43) return 0;
  
    const ranges = [
      { x: [Q43, R43], y: [Q37, R37] },
      { x: [R43, S43], y: [R37, S37] },
      { x: [S43, T43], y: [S37, T37] },
    ];
  
    let lastCalculatedValue = 0;
    for (const range of ranges) {
      if (O43 >= range.x[0] && O43 <= range.x[1]) {
        const interpolatedValue = forecast(O43, range.x, range.y);
        return roundToTwoDecimals(interpolatedValue * 100);
      }
      lastCalculatedValue = forecast(range.x[1], range.x, range.y);
    }
    return roundToTwoDecimals(lastCalculatedValue * 100);
  }

  export function denominatorDecelerator(passMonth2:number, passMonth3 :number): number {
    if (isNumber(passMonth2) && isNumber(passMonth3)) {
      return 3;
    }

    if (isEmpty(passMonth2) || isEmpty(passMonth3)) {
      return 2;
    }

    return 1;
  }

  export function isNumber(value: any): boolean {
    return typeof value === 'number' && !isNaN(value);
  }

  export function isEmpty(value: any): boolean {
    return value === null || value === undefined || value === 0;
  }
  

  export function calcularRvFronts(cumplimiento:number,rango:ComplianceCurveRange): number {
    return forecast(cumplimiento, [rango.cumplimiento1, rango.cumplimiento2], [rango.curva1, rango.curva2]);
  }

  export function obtenerRango(cumplimiento: number, data: ComplianceCurveData[]): ComplianceCurveRange | null {

    const index = data.findIndex((_, i) => 
      i < data.length - 1 && cumplimiento <= data[i].cumplimiento && cumplimiento > data[i + 1].cumplimiento
    );
  
    if (cumplimiento >= data[0].cumplimiento) return { cumplimiento1: data[0].cumplimiento, cumplimiento2: data[0].cumplimiento, curva1: data[0].curva, curva2: data[0].curva };
    if (cumplimiento <= data[data.length - 1].cumplimiento) return { cumplimiento1: data[data.length - 1].cumplimiento, cumplimiento2: data[data.length - 1].cumplimiento, curva1: data[data.length - 1].curva, curva2: data[data.length - 1].curva };
  
    if (index === -1) return null;
  
    return [data[index], data[index + 1]].map(d => ({
      cumplimiento1: d.cumplimiento,
      cumplimiento2: d.cumplimiento,
      curva1: d.curva,
      curva2: d.curva
    })).reduce((a, b) => ({
      cumplimiento1: a.cumplimiento1,
      cumplimiento2: b.cumplimiento2,
      curva1: a.curva1,
      curva2: b.curva2
    }));
  }