<section class="decelerators">
  <h2 class="decelerators__title">Multiplicador RV</h2>
  <div class="row">
    <div class="col-12 col-md-7 pl-des">
      <!-- **** MOBILE *** -->
      <div class="recover card d-block d-md-none">
        <div class="recover__header t-16-700">Pase a Recuperaciones PE* (S/)</div>
        <div class="row align-items-center my-3">
          <div class="col-6 t-14-400">Logro:</div>
          <div class="col-6 text-right">
            <input
              class="recover__input t-14-700 text-right"
              type="text"
              [value]="achievement"
              (input)="setAchievement($event, true)"
              (blur)="onBlurSetAchievementPassBalance()"
              (keypress)="onKeyPress($event)"
              milabNumericmask
            />
          </div>
        </div>
        <div class="row color-primary">
          <div class="col-6 t-14-400">Cumplimiento:</div>
          <div class="col-6 t-16-700 text-right" [ngStyle]="{'color': compliance>120 ? '#F4323F' : '#00B33C'}">{{ compliance.toFixed(2) }}%</div>
        </div>
        <div class="row mr-1 d-flex justify-content-end">
          <div class="increase-wrapper" *ngIf="variable.increase && variable.increase.length > 0">
            <milab-increase-card
              [increase]="variable.increase"
              [icon]="variable.increaseIcon"
              [color]="variable.increaseColor">
            </milab-increase-card>
          </div>
        </div>
        <div class="goals">
          <p class="t-16-700 my-3">Metas a lograr</p>
          <div class="row goals__cnt">
            <div class="goals__cnt-first col-6" style="color: #fff">
              <p class="t-14-700">100%</p>
            </div>
            <div class="goals__cnt-second col-6">
              <p class="t-14-400">{{ dataDecelerators.metaPaseRec | amountNumericFormatter }}</p>
            </div>
          </div>
        </div>
        <div class="row m-0 mt-3">
          <p class="t-12-400">* PE: Muestra el saldo de pase a recuperaciones del peor escenario a la fecha.</p>
        </div>
      </div>
      <!-- ******************************** -->

      <!--******* DESKTOP *******-->
      <div class="recover card d-none d-md-block">
        <div class="recover__header t-16-700">
          <div class="col-3 t-14-700 text-right"></div>
          <div class="col-3 t-14-700 text-center">Logro</div>
          <div class="col-4 t-14-700 text-center">% Pase a recuperaciones</div>
          <div class="col-2 t-14-700 text-center">Límite</div>
        </div>
        <div class="row align-items-center my-3 my-md-0">
          <div class="col-3 mt-3" >
            <p class="t-14-700">Pase a Recuperaciones</p>
            <p class="t-14-700">PE* (S/)</p>
          </div>
          <div class="col-3 text-center">
            <input
              class="recover__input t-14-700 text-right"
              type="text"
              [value]="achievement"
              (input)="setAchievement($event, true)"
              (blur)="onBlurSetAchievementPassBalance()"
              (keypress)="onKeyPress($event)"
              milabNumericmask
            />
          </div>
          <div class="col-3 color-primary d-flex flex-column">
            <div class="t-16-700 text-center" [ngStyle]="{'color': compliance>120 ? '#F4323F' : '#00B33C'}">
              {{ compliance.toFixed(2) }}%
            </div>
            <div class="increase-wrapper mx-auto" *ngIf="variable.increase && variable.increase.length > 0">
              <milab-increase-card
                [increase]="variable.increase"
                [icon]="variable.increaseIcon"
                [color]="variable.increaseColor"
              >
              </milab-increase-card>
            </div>
          </div>
          <div class="col-3 goals__cnt">
            <div class="goals__cnt-first" style="color: #fff">
              <p class="t-12-700 text-center">100%</p>
            </div>
            <div class="goals__cnt-second">
              <p class="t-12-400 text-center">{{ dataDecelerators.metaPaseRec | amountNumericFormatter }}</p>
            </div>
          </div>
        </div>
        <div class="row m-0 mt-3">
          <p class="t-12-400">* PE: Muestra el saldo de pase a recuperaciones del peor escenario a la fecha.</p>
        </div>
      </div>
      <!-- destock end -->

      <div class="d-flex flex-column flex-md-row">
        <!-- ******************************** -->
         <!-- ****************MOBILE CARD**************** -->
        <div class="multiplier card ml-md-3 d-block d-md-none">
          <p class="t-14-400">Multiplicador</p>
          <h2 class="t-24-700">{{ deceleratorForPassToRecoveries.toFixed(2) }}%</h2>
          <p class="t-12-400">% promedio de pase a recuperaciones: <strong class="t-14-700">{{ complienceAverage.toFixed(2)}}%</strong>
          </p>
        </div>
        <!-- *****************END MOBILE CARD*************** -->
        <div class="autonomy card">
          <p class="t-14-400">% Promedio del Pase a Recuperaciones (3 meses consecutivos)</p>
          <h2 class="t-24-700">{{ complienceAverage.toFixed(2) }}%</h2>
          <div class="row autonomy__grid">
            <div class="col-4 autonomy__box px-2">
              <div class="box-frame-2">
                <p class="autonomy__t-gray t-12-400">
                  {{ lastThreeMonthsData[0].monthName | titlecase }}. {{ lastThreeMonthsData[0].year }}
                </p>
                <p class="autonomy__t-dark t-14-700" [ngStyle]="{'color':complianceMesThree>120?'#F4323F':'#00b33c'}">{{ compliance_mes_3 }}</p>
              </div>

            </div>
            <div class="col-4 autonomy__box px-2">
              <div class="box-frame-2">
                <p class="autonomy__t-gray t-12-400">
                  {{ lastThreeMonthsData[1].monthName | titlecase }}. {{ lastThreeMonthsData[1].year }}
                </p>
                <p class="autonomy__t-dark t-14-700"[ngStyle]="{'color':complianceMesTwo>120?'#F4323F':'#00b33c'}" >{{ compliance_mes_2 }}</p>
              </div>
            </div>
            <div class="col-4 autonomy__box px-2">
              <div class="box-frame-2" style="background: '#ECF9F8'">
                <p class="t-12-700">
                  {{ lastThreeMonthsData[2].monthName | titlecase }}. {{ lastThreeMonthsData[2].year }}
                </p>
                <p class="autonomy__t-green t-14-700" [ngStyle]="{'color':compliance>120?'#F4323F':'#00b33c'}">{{ compliance.toFixed(2) }}%</p>
              </div>
            </div>
          </div>
        </div>
        <!-- ******************************** -->
        <!-- ******************************** -->
        <div class="multiplier card ml-md-3 d-none d-md-block" >
          <p class="t-14-400">Multiplicador</p>
          <h2 class="t-24-700">{{ deceleratorForPassToRecoveries.toFixed(2) }}%</h2>
          <p class="t-12-400">% promedio de pase a recuperaciones: <strong class="t-14-700">{{ complienceAverage.toFixed(2)}}%</strong>
          </p>
        </div>
        <!-- ******************************** -->
      </div>
      <!-- *************  MOBILE  ************ -->
      <div class="compliance d-block d-md-none">
        <div class="row compliance__header align-items-center m-0">
          <div class="col-6 t-12-700 text-center m-0 px-2">% NC Pase a Recuperaciones</div>
          <div class="col-6 t-12-700 text-center m-0 px-2">% Factor</div>
        </div>
        <div class="compliance__body">
          <div
            class="compliance__body-item row align-items-center m-0 p-2"
            *ngFor="let item of decceleratorsTable"
            [ngClass]="{ selected: item.selected }"
          >
            <div class="col-6 t-14-400 text-center m-0 px-0">
              {{ item.c11 }} <img src="assets/icons/adn/arrow-right-dark.svg" width="16" height="16" alt="" />
              {{ item.c12 }}
            </div>
            <div class="col-6 t-14-400 text-center m-0 px-0" [ngClass]="{ selectedChild: item.selected }">{{ item.c2 }}</div>
          </div>
        </div>
      </div>
      <!-- ******************************** -->
    </div>
    <!-- *********** DESKTOP ************ -->
    <div class="col-12 col-md-5 d-none d-md-block">
      <div class="compliance">
        <div class="row compliance__header align-items-center m-0">
          <div class="col-6 t-12-700 text-center m-0 px-2">% NC Pase a Recuperaciones</div>
          <div class="col-6 t-12-700 text-center m-0 px-2">% Factor</div>
        </div>
        <div class="compliance__body">
          <div
            class="compliance__body-item row align-items-center m-0 p-2"
            *ngFor="let item of decceleratorsTable"
            [ngClass]="{ selected: item.selected  }"
          >
            <div class="col-6 t-14-400 text-center m-0 px-0">
              {{ item.c11 }} <img src="assets/icons/adn/arrow-right-dark.svg" width="16" height="16" alt="" />
              {{ item.c12 }}
            </div>
            <div class="col-6 t-14-400 text-center m-0 px-0" [ngClass]="{ selectedChild: item.selected }">{{ item.c2 }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- ********************************** -->
  </div>
</section>
