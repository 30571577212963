<div class="md-informative">
    <div class="md-informative__header">
        <h2 class="md-informative__title">{{modalItem.titleModal}}</h2>
        <img 
          src="/assets/icons/adn/close-modal.svg" 
          alt="Cerrar" 
          class="md-informative__close-icon" 
          (click)="activeModal.close()" />
    </div>
    <div *ngIf="modalItem" class="md-informative__body">
      <ng-container *ngFor="let item of modalItem.content">
        <h2 class="md-informative__body_title">{{ item.title }}</h2>
        <p *ngFor="let paragraph of item.paragraphs" [innerHTML]="paragraph.text"></p>
      </ng-container>
      
      <p *ngIf="modalItem.isNote" class="note" [innerHTML]="modalItem.note"></p>
    </div>
    <div class="md-informative__footer">
      <button 
        type="button" 
        class="md-informative__footer__button t-16-700 font-lato"
        (click)="activeModal.close()">
        Entendido
      </button>
    </div>
  </div>