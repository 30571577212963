import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PayoffCurveCollection, PayoffCurveCommercial } from '@shared/constants/adn-simulator.constants';
import { AdnSetting, ComplianceMetrics } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calcularRvFronts, calculateWeightedCompliance, 
         complianceColorValid, 
         convertToNumeric, 
         formatNumber, 
         formatPercentageV2, 
         obtenerRango, 
         truncateToTwoDecimals} from '@shared/utils/adn-simulator.utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'milab-modal-summary',
  templateUrl: './modal-summary.component.html',
  styleUrls: ['./modal-summary.component.scss']
})
export class ModalSummaryComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  
  @Input() directVariableAmount: number = 0;
  @Input() acceleratorPercentage: number = 0;
  @Input() deceleratorPercentage: number = 0;
  @Input() finalRv: number = 0;
  @Input() finalTotal: string = '0';
  @Input() bonus20k: number = 0;

  @Input() ponderadoComercial: number = 0;
  @Input() ponderadoCobranza: number = 0;
  @Input() amountDistributed: number = 0;
  porcentageComercial: string =' 0';
  porcentageCobranza: string = '0';
  @Input() rvCobranza:  number = 0;
  @Input() rvComercial: number = 0;
  @Input() setting!:AdnSetting;

  amountDistributedTo60: string = '0';
  amountDistributedTo40: string = '0';

  metrics!: ComplianceMetrics;
  dataComercial:any;
  dataCobranza:any;
  weightedCompliance: number = 0;

  @Input() factorAucencia: string = '0';
  directVariableAmountString: string = '0';
  finalRvString: string = '0';
  finalTotalString: string = '0';
  imgComplianceWeightCollection: string = '/assets/icons/adn/x-close.svg';
  bgColorSummaryCollection: string = "bg-notok-summary";
  imgComplianceWeightCommercial: string = '/assets/icons/adn/x-close.svg';
  bgColorSummaryCommercial: string = "bg-notok-summary";

  increaseAmount:string = '';
  increaseAmountColor:string = 'color-numeric';
  increaseAmountIcon:string = '/assets/icons/adn/plus-blue.svg';

  valueString: string = '0';
  constructor(public activeModal: NgbActiveModal, private adnSimulatorService:AdnSimulatorService) { }

  ngOnInit(): void {
    this.valueString = truncateToTwoDecimals(this.bonus20k);
    this.subscribeSumary();
  }

  subscribeSumary(){
    this.subscription.add(
      this.adnSimulatorService.metrics$.subscribe((metrics) => {
        this.metrics = metrics;
        this.updateData(this.metrics);
      })
    );
  }

  updateData(metrics: ComplianceMetrics): void {
  

    this.dataComercial = [
      { name: 'Desembolso Neto', value: metrics.complianceNetDisbursement.compliance, weight: (metrics.complianceNetDisbursement.weight * 100).toString(),color:complianceColorValid(metrics.complianceNetDisbursement.compliance) },
      { name: 'Stock de Clientes Activos', value: metrics.complianceActiveClientStock.compliance, weight: (metrics.complianceActiveClientStock.weight * 100).toString(),color:complianceColorValid(metrics.complianceActiveClientStock.compliance) },
      { name: 'Captación de Pasivos', value: metrics.complianceLiabilitiesCapture.compliance, weight: (metrics.complianceLiabilitiesCapture.weight * 100).toString(),color:complianceColorValid(metrics.complianceLiabilitiesCapture.compliance)},
      { name: 'Gestión de Precios', value: metrics.compliancePriceManagement.compliance, weight: (metrics.compliancePriceManagement.weight * 100).toString(),color:complianceColorValid(metrics.compliancePriceManagement.compliance) }
    ];

    this.dataCobranza = [
      { name: 'Pise 9 (Mora > 8)', value: metrics.compliancePise9Mora.compliance, weight: (metrics.compliancePise9Mora.weight * 100).toString() ,color:complianceColorValid(metrics.compliancePise9Mora.compliance)},
      { name: 'Resolución 0 días', value: metrics.complianceResolution0Days.compliance, weight: (metrics.complianceResolution0Days.weight * 100).toString() ,color:complianceColorValid(metrics.complianceResolution0Days.compliance)}
    ];

    this.weightedCompliance = convertToNumeric(formatPercentageV2(this.calculateWeightedCompliance(metrics)));
    const activedCommercial = calculateWeightedCompliance(this.ponderadoComercial,this.setting.salesRate)
    activedCommercial ? (this.imgComplianceWeightCommercial = '/assets/icons/adn/check-circle.svg'): (this.imgComplianceWeightCommercial = '/assets/icons/adn/x-close.svg');
    activedCommercial ? (this.bgColorSummaryCommercial = "bg-ok-summary"):(this.bgColorSummaryCommercial = "bg-notok-summary");
    const amountComercial = this.amountDistributed*0.6*activedCommercial;
    this.amountDistributedTo60 = formatNumber(formatPercentageV2(amountComercial));
    this.ponderadoComercial =  convertToNumeric(formatPercentageV2(this.ponderadoComercial));
    this.porcentageComercial = formatPercentageV2(this.calculatePorcentageComercial(this.ponderadoComercial, this.amountDistributed, 0.6, 50));

    const activedCollection = calculateWeightedCompliance(this.ponderadoCobranza,this.setting.collectionRate);
    activedCollection ? (this.imgComplianceWeightCollection = '/assets/icons/adn/check-circle.svg'): (this.imgComplianceWeightCollection = '/assets/icons/adn/x-close.svg');
    activedCollection ? (this.bgColorSummaryCollection = "bg-ok-summary"):(this.bgColorSummaryCollection = "bg-notok-summary");
    const amountCobranza = this.amountDistributed*0.4*activedCollection;
    this.porcentageCobranza = formatPercentageV2(this.calculatePorcentageCobranza(this.ponderadoCobranza, this.amountDistributed, 0.4, 50));


    this.amountDistributedTo40 = formatNumber(formatPercentageV2(amountCobranza));
    this.ponderadoCobranza =  convertToNumeric(formatPercentageV2(this.ponderadoCobranza));

    this.directVariableAmountString = formatNumber(this.directVariableAmount.toString());
    this.finalRvString = formatNumber((this.finalRv).toFixed(2));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  calculateWeightedCompliance(metrics: ComplianceMetrics): number {
    return metrics.complianceNetDisbursement.compliance*(metrics.complianceNetDisbursement.weight )+
    metrics.complianceActiveClientStock.compliance*(metrics.complianceActiveClientStock.weight)+
    metrics.complianceLiabilitiesCapture.compliance*(metrics.complianceLiabilitiesCapture.weight )+
    metrics.compliancePriceManagement.compliance*(metrics.compliancePriceManagement.weight )+
    metrics.compliancePise9Mora.compliance*(metrics.compliancePise9Mora.weight)+
    metrics.complianceResolution0Days.compliance*(metrics.complianceResolution0Days.weight);
  }

      private calculatePorcentageComercial(ponderadoComercial: number, amountDistributed: number, weight: number, threshold: number): number {
        if (ponderadoComercial > threshold) {
          const rangeComercial = obtenerRango(ponderadoComercial, PayoffCurveCommercial);
          if (rangeComercial) {
            return calcularRvFronts(ponderadoComercial, rangeComercial);
          }
        }
        return 0;
      }
    
      private calculatePorcentageCobranza(ponderadoCobranza: number, amountDistributed: number, commercialWeight: number, threshold: number): number {
        if (ponderadoCobranza > threshold) {
          const rangeCobranza = obtenerRango(ponderadoCobranza, PayoffCurveCollection);
          if (rangeCobranza) {
            return calcularRvFronts(ponderadoCobranza, rangeCobranza);;
          }
        }
        return 0;
      }
}
