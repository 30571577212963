import { ComplianceCurveData } from "@shared/interfaces/frontend/adn";

export const INCREASE =  {
    iconArrowUp: '/assets/icons/adn/arrow-narrow-up-green.svg',
    iconArrowDown: '/assets/icons/adn/arrow-down-red.svg',
    colorIncrement:'c-increment',
    colorDecrement:'c-decrement'
  }

export const COMMERCIAL_VALUES =  {
    iconArrowUp: '/assets/icons/adn/arrow-narrow-up-green.svg',
    iconArrowDown: '/assets/icons/adn/arrow-down-red.svg',
    colorIncrement:'c-increment',
    colorDecrement:'c-decrement'
  }

  export const COLLECTION_VALUES =  {
    pise9Id: 'pise9',
    pise9Name: 'Pise 9 (Mora > 8)',
    pise9ActualArrears: 'Mora Real > 8 (S/)',
    pise9PortfolioBalance: 'Saldo de cartera 0-30 (S/)',

    resolutionId: 'resolucion',
    resolutionName: 'Resolución 0 días',
    resolutionResolvedBalance: 'Saldo resuelto 0 días (S/)',
    resolutionOla0dias: 'Ola 0 días (S/)',

  }

  export const RV_DATALAYER =  {
   actionSidebar: 'Barra Lateral',
   actionPrincipal: 'Opciones principales',
   actionFilterPrincipal: 'Filtros principales',
   actionSectionNews: 'Sección de Noticias',
   actionTabValues:'Variables directas',
   actionTabMulti:'Multiplicador',
   actionTabBonus:'Bonificación 0-20K',
   selectTabBonus:'Desembolsos 0-20 K',
   selectTabMulti:'Pase a Recuperaciones PE* (S/)',
   eventoCloseSession: 'Cerrar Sesión',
   eventReset:'Restablecer',
   eventSummary :'Ver resumen',
   eventInputGestionPrice: 'Gestión de precios',
   eventInputPiseNine: 'Pise 9 (Mora > 8)',
   eventInputResolution: 'Resolución 0 días',
   selectOptionName:'Seleccionar opción: ',
   selectFilterName:'Seleccionar filtro: ',
   selectName:'Seleccionar: ',
  }

  export const PayoffCurveCommercial:ComplianceCurveData[] = [
    { cumplimiento: 300, curva: 425 },
    { cumplimiento: 250, curva: 350 },
    { cumplimiento: 200, curva: 275 },
    { cumplimiento: 150, curva: 200 },
    { cumplimiento: 130, curva: 160 },
    { cumplimiento: 120, curva: 140 },
    { cumplimiento: 110, curva: 120 },
    { cumplimiento: 100, curva: 100 },
    { cumplimiento: 90, curva: 90 },
    { cumplimiento: 80, curva: 77 },
    { cumplimiento: 70, curva: 64 },
    { cumplimiento: 60, curva: 52 },
    { cumplimiento: 50, curva: 40 },
    { cumplimiento: 49.99, curva: 0 },
  ];

  export const PayoffCurveCollection:ComplianceCurveData[] = [
    { cumplimiento: 150, curva: 210 },
    { cumplimiento: 130, curva: 166 },
    { cumplimiento: 120, curva: 144 },
    { cumplimiento: 110, curva: 122 },
    { cumplimiento: 100, curva: 100 },
    { cumplimiento: 90, curva: 90 },
    { cumplimiento: 80, curva: 77 },
    { cumplimiento: 70, curva: 64 },
    { cumplimiento: 60, curva: 52 },
    { cumplimiento: 50, curva: 40 },
    { cumplimiento: 49.99, curva: 0 },
  ];