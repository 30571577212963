<section>

  <div class="blur">
    <div class="blur-box">
      <div class="blur-box__title">Créditos desenfocados y/o extornados</div>
      <a class="p-link" (click)="openModal()">¿Qué significa esto?</a>
    </div>
  </div>

  <div class="pd-0-20"  *ngIf="!loader">
    <ngx-skeleton-loader 
    *ngIf="!loader" 
    [appearance]="'line'" 
    count="1" 
    animation="pulse"
    [theme]="{
     width:'100%',
     height: '300px',
     'border-radius': '16px',
     'background-color': '#E6E9ED',
     'margin-top': isMobile ? '16px' : '0px'
    }">
 </ngx-skeleton-loader>
  </div>


  <div class="pd-0-20"  *ngIf="loader">
    <div class=" desktop table-container">
      <table class="table-blur">
        <thead>
          <tr>
            <th class="">Código de crédito</th>
            <th class="">Fecha de desem.</th>
            <th class="">Monto desembolsado</th>
            <th class="">Código de analista</th>
            <th class="">Código de AdN</th>
            <th class="">Motivo</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of listBlur">
            <td class="td-title color-dark">{{item.creditCode}}</td>
            <td class="">{{item.dateDisbursement}}</td>
            <td class="">S/ {{item.disbursedAmount}} </td>
            <td class="">{{item.analystCode}}</td>
            <td class="">{{item.adnCode? item.adnCode : 'No aplica' }}</td>
            <td class=""><span [ngStyle]="{'background':item.reason ==='Extornado'?'#FFCE00':'#E6E9ED'}">{{item.reason}}</span> </td>
          </tr>
          <tr *ngIf="!listBlur || listBlur.length === 0">
            <td colspan="6" class="text-center">
              <milab-empty-state></milab-empty-state>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mobile">
      <div *ngFor="let item of listBlur;let i = index ">
        <div class="card-mobile mt-3">
          <div class="card-mobile__header d-flex justify-content-between mt-1 mb-2">
            <div class="t-16-700 font-lato color-dark">Crédito: {{item.creditCode}}</div>
            <div class="t-14-400 font-lato color-dark"><span class="motive-card"
                [ngStyle]="{'background':item.reason ==='Extornado'?'#FFCE00':'#E6E9ED'}">{{item.reason}}</span></div>
          </div>
          <div class="card-mobile__body mt-4 mb-3">
            <div class="d-flex  justify-content-between  align-items-center mb-4">
              <div class="t-16-400 font-lato color-dark">Fecha de desem.:</div>
              <div class="t-14-400 font-lato color-dark">{{item.dateDisbursement}}</div>
            </div>

            <div class="d-flex  justify-content-between mb-2">
              <div class="t-16-400 font-lato color-dark">Monto desembolsado:</div>
              <div class="t-16-400 font-lato">S/ {{item.disbursedAmount}}</div>
            </div>

          </div>

          <div class="card-mobile__footer mb-4">
            <div class="d-flex  justify-content-between" (click)="activeTable(i)" (keypress)="activeTable(i)">
              <div class="t-16-700 font-lato color-dark">{{isTableActive(i) ? 'Ver menos' : 'Ver más'}}</div>
              <img src="/assets/icons/adn/chevron-down.svg" alt="chevron-down"
                [ngClass]="{'rotate-180': isTableActive(i)}">
            </div>
            <div *ngIf="isTableActive(i)">
              <div class="mt-3">
                <div class="goals">
                  <div class="row goals__cnt roundTop">
                    <div class="col-6 goals__cnt-first" style="color: #fff;">
                      <p class="t-14-700 font-lato">Cód. analista</p>
                    </div>
                    <div class="col-6 goals__cnt-second">
                      <p class="t-14-400">{{item.analystCode}}</p>
                    </div>
                  </div>
                  <div class="row goals__cnt roundBot">
                    <div class="col-6 goals__cnt-first" style="color: #fff;">
                      <p class="t-14-700 font-lato">Cod. AdN</p>
                    </div>
                    <div class="col-6 goals__cnt-second">
                      <p class="t-14-400">{{item.adnCode? item.adnCode : 'No aplica' }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!listBlur || listBlur.length === 0" class="content-empty-state ">
        <milab-empty-state></milab-empty-state>
      </div>
      <br>
    </div>

  </div>
</section>
