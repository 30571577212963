import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InputItem } from '@shared/interfaces/frontend/adn';
import { formatNumberValidations } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit, OnChanges {
  @Input() item: InputItem = { key: 'defaul', value: 0,name:'' };
  value:string='';
  @Output() valueChange = new EventEmitter<InputItem>();

  constructor() { }
  ngOnInit(): void {
    this.value = this.item.value ? formatNumberValidations(this.item.value.toString()): '0';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && changes.item.currentValue.value ) {
      const value = changes.item.currentValue.value;
      this.value = formatNumberValidations(value.toString());
    }
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    if (this.item.key === 'stocks') {
        value = value.replace(/[^0-9]/g, '');
    } else {
      value = value.replace(/[^0-9.,]/g, '');
      const parts = value.split('.');
      if (parts.length > 2) {
          value = `${parts[0]}.${parts.slice(1).join('')}`;
      }
    }

    inputElement.value = value;
    this.value = value;
}

  onBlur(): void {
    this.emitValue();
  }

  onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.emitValue();
    }
  }

  private emitValue(): void {
    const cleanedValue = this.value.replace(/,/g, '');
    const numericValue = parseFloat(cleanedValue);
    if (!isNaN(numericValue)) {
      this.valueChange.emit({ key: this.item.key, value: numericValue, name: this.item.name });
    }
  }

}
