import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdnAchievement, AdnTarget } from '@shared/interfaces/frontend/adn';
import { AdnUserProfile } from '@shared/models/UserProfileModel';
import { DataLayerService } from '@shared/services/data-layer.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
@Component({
  selector: 'milab-adn-dashboard',
  templateUrl: './adn-dashboard.component.html',
  styleUrls: ['./adn-dashboard.component.scss']
})
export class AdnDashboardComponent implements OnInit {
  isMobile: boolean = false;
  user!: AdnUserProfile;
  sidebarActive = false;
  actionSidebar = false;

  targets: AdnTarget[] = [];
  achievements: AdnAchievement[] = [];
  modalRef!: NgbModalRef;
  constructor(private localStorageService: LocalStorageService,
              private router: Router,              
              private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
    this.checkIfMobile();
    this.getUser();
  }

  getUser(){
    const adnUserProfile = this.localStorageService.get('adnUserProfile');
    if (adnUserProfile) {
      this.user = JSON.parse(adnUserProfile) as AdnUserProfile;
      this.dataLayerService.valueEvent('userID',this.user?.codeAdn);
    }else {
      this.router.navigate(['/ingresar-adn']);
    }

  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkIfMobile();
  }

  checkIfMobile(): void {
    this.isMobile = window.innerWidth < 768;
  }

  sidebar(active:boolean){
    this.sidebarActive = active;
    this.actionSidebar = true;
  }

  onScreenClick(event: MouseEvent): void {
    if (this.sidebarActive && this.actionSidebar) {
      this.sidebarActive = false;
    }
  }

  closeSidebar(): void {
    this.sidebarActive = false;
  }

}
