<div class="md-summary">
    <div class="md-summary__header">
        <div class="md-summary__header__content">
            <h2 class="md-summary__title">Resumen de tu RV</h2>
           <img src="/assets/icons/adn/close-modal.svg" alt="Cerrar" 
          class="md-summary__close-icon" (click)="activeModal.close()" />
        </div>
    </div>
    
    <div class="md-summary__body">
        <h2>Cálculo de RV según cumplimiento</h2>
        <div class="summary-card">
            <div class="row-h summary-card__header align-items-center">
              <div class="col-5 t-14-700 font-lato text-left m-0 px-2">Variables directas</div>
              <div class="col-4 t-14-700 font-lato text-center m-0 px-2">Cumplimiento</div>
              <div class="col-3 t-14-700 font-lato text-center m-0 px-2">Peso</div>
            </div>
            <div class="summary-card__body">
              <div class="summary-card__body-item row align-items-center m-0 pd-8-16" *ngFor="let item of dataComercial">
                <div class="col-5 t-14-700 font-lato text-left m-0 px-0">{{item.name}}</div>
                <div class="col-4 t-14-700 font-lato text-center m-0 px-0" [ngClass]="item.color">{{item.value}}%</div>
                <div class="col-3 t-14-400 font-lato text-center m-0 px-0">{{item.weight}}%</div>
              </div>
            </div>
            <div class="row summary-card__footer align-items-center m-0 " [ngClass]="bgColorSummaryCommercial">
                <div class="col-12 summary-card__footer__text m-0 px-2 text-center">% Cumplimiento Comercial 
                    <span class="" > {{ponderadoComercial}}%</span> 
                    <img [src]="imgComplianceWeightCommercial" alt="check">
                </div>
              </div>
          </div>
    </div>

    <!-- NEW    XXXXXXXXXX -->
    <div class="md-summary__body">
        <div class="summary-card">
            <div class="row-h summary-card__header align-items-center">
              <div class="col-5 t-14-700 font-lato text-left m-0 px-2">Var. Cobranza</div>
              <div class="col-4 t-14-700 font-lato text-center m-0 px-2">Cumplimiento</div>
              <div class="col-3 t-14-700 font-lato text-center m-0 px-2">Peso</div>
            </div>
            <div class="summary-card__body">
              <div class="summary-card__body-item row align-items-center m-0 pd-8-16" *ngFor="let item of dataCobranza">
                <div class="col-5 t-14-700 font-lato text-left m-0 px-0">{{item.name}}</div>
                <div class="col-4 t-14-700 font-lato text-center m-0 px-0" [ngClass]="item.color">{{item.value}}%</div>
                <div class="col-3 t-14-400 font-lato text-center m-0 px-0">{{item.weight}}%</div>
              </div>
            </div>
            <div class="row summary-card__footer align-items-center m-0 " [ngClass]="bgColorSummaryCollection">
                <div class="col-12 summary-card__footer__text m-0 px-2 text-center">% NCumplimiento de Cobranzas
                    <span class="" > {{ponderadoCobranza}}%</span> 
                    <img [src]="imgComplianceWeightCollection" alt="check">
                </div>
              </div>
          </div>
    </div>
     <!-- END XXX -->
    <div>
        <h2>Cálculo de RV + Bonificación 0-20K</h2>
    </div>

    <!-- neww segunda parte  -->
    <div class="total-card">
        <p class="sub-title">Frente comercial</p>
        <div class="total-card__content pb-16px">
            <div class="total-card__content__title font-lato color-dark">% Pago Comercial:</div>
            <div class="t-16-700 font-lato color-dark">x{{porcentageComercial}}%</div>
        </div>
        <div class="total-card__content pb-16px">
            <div class="total-card__content__title font-lato color-dark">60% del Monto a Repartir:</div>
            <div class="t-16-700 font-lato color-dark">S/ {{amountDistributedTo60}}</div>
        </div>
         <!-- parte celeste claro -->
         <div class="total-card text-amount">
            <div class="t-16-700 font-lato">RV Comercial</div>
            <div class="t-18-800 font-lato">S/ {{rvComercial}}</div>
        </div>
        <!-- ---------- -->
        <p class="sub-title pt-2">Frente de Cobranzas</p>
        <div class="total-card__content pb-16px">
            <div class="total-card__content__title font-lato color-dark">% Pago de Cobranzas:</div>
            <div class="t-16-700 font-lato color-dark">x{{porcentageCobranza}}%</div>
        </div>
        <div class="total-card__content pb-16px">
            <div class="total-card__content__title font-lato color-dark">% Factor por Pase a Recuperaciones:</div>
            <div class="t-16-700 font-lato color-dark">x{{deceleratorPercentage}}%</div>
        </div>
        <div class="total-card__content pb-16px">
            <div class="total-card__content__title font-lato color-dark">40% del Monto a Repartir</div>
            <div class="t-16-700 font-lato color-dark">S/ {{amountDistributedTo40}}</div>
        </div>
        <!-- parte celeste claro -->
        <div class="total-card text-amount">
            <div class="t-16-700 font-lato">RV de Cobranzas</div>
            <div class="t-18-800 font-lato">S/ {{rvCobranza}}</div>
        </div>

        <p class="sub-title pt-2">Factor de ausencia</p>
        <div class="total-card__content pb-16px">
            <div class="total-card__content__title font-lato color-dark">% Factor de ausencia de RV:</div>
            <div class="t-16-700 font-lato color-dark">x{{factorAucencia}}%</div>
        </div>

        <!-- parte celeste -->
        <div class="total-card__content br-top pt-16px">
            <div class="t-16-700 font-lato color-17749B ">RV Final actual:</div>
            <div class="t-16-700 font-lato color-17749B">S/ {{finalRvString}}</div>
        </div>
        <div class="br-button pb-3">
            <div class="t-16-400 font-lato color-17749B ">RV Comercial + RV Cobranzas</div>
        </div>
        <div class="total-card__content pb-16px pt-16px t-16-700 color-17749B">
            <div class="t-16-700 font-lato w-max-205 color-17749B">Bonificación por desembolsos 0 - 20 K:</div>
            <div class="t-16-700 font-lato color-17749B">S/ {{valueString}}</div>
        </div>
        <div class="total-card__footer">
            <div class="t-18-700 font-lato">Total:</div>
            <div class="t-20-800 font-lato">S/ {{finalTotal}}</div>
        </div>
    </div>
    <!-- fin -->
  </div>