import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdnModal } from '@shared/interfaces/frontend/adn';

@Component({
  selector: 'milab-modal-informative',
  templateUrl: './modal-informative.component.html',
  styleUrls: ['./modal-informative.component.scss']
})
export class ModalInformativeComponent implements OnInit {
  
  @Input() modalItem!: AdnModal;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
