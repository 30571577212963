import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INCREASE, PayoffCurveCollection, PayoffCurveCommercial } from '@shared/constants/adn-simulator.constants';
import { AdnSetting, ComplianceMetrics, DeceleratorsData } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import {
  calculateWeightedCompliance,
  convertToNumeric,
  formatNumberWithCommasNoDecimals,
  formatPercentage,
  formatPercentageV2,
  obtenerRango,
  calcularRvFronts,
  denominatorDecelerator,
  division,
  calculateMultiplier,
  calculateDivision
} from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-cumplirme-card',
  templateUrl: './cumplirme-card.component.html',
  styleUrls: ['./cumplirme-card.component.scss'],
})
export class CumplirmeCardComponent implements OnInit {
  @Input() title!: string;
  @Input() weightedComplianceInitialComercial!: number;
  @Input() weightedComplianceInitialCobranza!: number;
  @Input() rvCobranza!: number;
  @Input() rvComercial!: number;
  @Input() amountDistributed: number = 0;
  setting!: AdnSetting;
  @Output() flagActivateRV = new EventEmitter<boolean>();
  dataDecelerators!: DeceleratorsData;
  collectionMultiplier: number = 0;
  ponderadoCobranza: number = 0;
  ponderadoComercial: number = 0;

  increaseCompliance: string = '';
  increaseComplianceComercial: string = '';
  increaseComplianceCobranza: string = '';
  increaseDistributed: string = '';
  increaseDistributedComercial: string = '';
  increaseDistributedCobranza: string = '';
  complianceColor: string = INCREASE.colorIncrement;
  complianceColorComercial: string = INCREASE.colorIncrement;
  complianceColorCobranza: string = INCREASE.colorIncrement;
  distributedColor: string = INCREASE.colorIncrement;
  distributedColorComercial: string = INCREASE.colorIncrement;
  distributedColorCobranza: string = INCREASE.colorIncrement;
  iconCompliance: string = INCREASE.iconArrowUp;
  iconComplianceComercial: string = INCREASE.iconArrowUp;
  iconComplianceCobranza: string = INCREASE.iconArrowUp;
  iconDistributede: string = INCREASE.iconArrowUp;
  iconDistributedeComercial: string = INCREASE.iconArrowUp;
  iconDistributedeCobranza: string = INCREASE.iconArrowUp;

  directRemunerationValue: number = 0;
  directRemunerationValueComercial: number = 0;
  directRemunerationValueCobranza: number = 0;
  directRemunerationInitial: number = 0;
  directRemunerationInitialComercial: number = 0;
  directRemunerationInitialCobranza: number = 0;
  weightedCompliance: number = 0;
  weightedComplianceComercial: number = 0;
  weightedComplianceCobranza: number = 0;
  imgComplianceWeight: string = '/assets/icons/adn/x-close.svg';
  imgComplianceWeightComercial: string = '/assets/icons/adn/x-close.svg';
  imgComplianceWeightCobranza: string = '/assets/icons/adn/x-close.svg';

  constructor(private adnSimulatorService: AdnSimulatorService) {
    this.setting = this.adnSimulatorService.getSetting();
    this.adnSimulatorService.metrics$.subscribe((value) => {
      this.ponderadoCobranza =((this.calculateWeightCobranza(value)/40)*100);
      this.ponderadoComercial =((this.calculateWeightComercial(value)/60)*100);
      calculateWeightedCompliance(this.ponderadoComercial,this.setting.salesRate)
        ? (this.imgComplianceWeightComercial = '/assets/icons/adn/check-circle.svg')
        : (this.imgComplianceWeightComercial = '/assets/icons/adn/x-close.svg');
      calculateWeightedCompliance(this.ponderadoCobranza,this.setting.collectionRate)
        ? (this.imgComplianceWeightCobranza = '/assets/icons/adn/check-circle.svg')
        : (this.imgComplianceWeightCobranza = '/assets/icons/adn/x-close.svg');
      this.updateDirectComercialRemunerationValue( this.ponderadoComercial, this.ponderadoCobranza);
      this.updateDirectCobranzaRemunerationValue( this.ponderadoCobranza, this.ponderadoComercial);
    });
  }

  ngOnInit(): void {
    this.dataDecelerators = this.adnSimulatorService.getDataDecelerators();
    this.directRemunerationInitialComercial= this.rvComercial;
    this.directRemunerationInitialCobranza = this.rvCobranza;
    this.getAchievementPassBalance();
  }

  private updateDirectComercialRemunerationValue(ponderadoComercial: number,ponderadoCobranza: number): void {
    this.weightedComplianceComercial = convertToNumeric(formatPercentageV2(ponderadoComercial));

    const weight = 0.6;
    this.directRemunerationValueComercial = this.calculateRvComercial(ponderadoComercial, ponderadoCobranza, this.amountDistributed, weight, this.setting.salesRate);
    this.calculeIncrementedComercial(ponderadoComercial);
    this.validateFlagActivateRV();
    this.calculeIncrementedRemunerationComercial(this.directRemunerationValueComercial);
  }

  private updateDirectCobranzaRemunerationValue(ponderadoCobranza: number,ponderadoComercial: number): void {
    this.weightedComplianceCobranza = convertToNumeric(formatPercentageV2(ponderadoCobranza));
    
    const weight = 0.4;
    this.directRemunerationValueCobranza = this.calculateRvCobranza(ponderadoComercial, ponderadoCobranza, this.amountDistributed, weight, this.setting.collectionRate);
    this.calculeIncrementedCobranza(ponderadoCobranza);
    this.validateFlagActivateRV();
    this.calculeIncrementedRemunerationCobranza(this.directRemunerationValueCobranza);
  }

  calculeIncrementedComercial(weightedCompliance: number): void {
    const increaseTemp = weightedCompliance - this.weightedComplianceInitialComercial;
    if (increaseTemp > 0) {
      this.increaseComplianceComercial = formatPercentage(increaseTemp);
      this.complianceColorComercial = INCREASE.colorIncrement;
      this.iconComplianceComercial = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseComplianceComercial = formatPercentage(increaseTemp);
      this.complianceColorComercial = INCREASE.colorDecrement;
      this.iconComplianceComercial = INCREASE.iconArrowDown;
    } else {
      this.increaseComplianceComercial = '';
    }
  }
  calculeIncrementedCobranza(weightedCompliance: number): void {
    const increaseTemp = weightedCompliance - this.weightedComplianceInitialCobranza;
    if (increaseTemp > 0) {
      this.increaseComplianceCobranza = formatPercentage(increaseTemp);
      this.complianceColorCobranza = INCREASE.colorIncrement;
      this.iconComplianceCobranza = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseComplianceCobranza = formatPercentage(increaseTemp);
      this.complianceColorCobranza = INCREASE.colorDecrement;
      this.iconComplianceCobranza = INCREASE.iconArrowDown;
    } else {
      this.increaseComplianceCobranza = '';
    }
  }

  calculeIncrementedRemuneration(remunerationValue: number): void {
    const increaseTemp = remunerationValue - this.directRemunerationInitial;
    const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
    if (increaseTemp > 0) {
      this.increaseDistributed = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColor = INCREASE.colorIncrement;
      this.iconDistributede = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseDistributed = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColor = INCREASE.colorDecrement;
      this.iconDistributede = INCREASE.iconArrowDown;
    } else {
      this.increaseDistributed = '';
    }
  }

  calculeIncrementedRemunerationComercial(remunerationValue: number): void {
    const increaseTemp = remunerationValue - this.directRemunerationInitialComercial;
    const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
    if (increaseTemp > 0) {
      this.increaseDistributedComercial = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColorComercial = INCREASE.colorIncrement;
      this.iconDistributedeComercial = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseDistributedComercial = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColorComercial = INCREASE.colorDecrement;
      this.iconDistributedeComercial = INCREASE.iconArrowDown;
    } else {
      this.increaseDistributedComercial = '';
    }
  }

  calculeIncrementedRemunerationCobranza(remunerationValue: number): void {
    const increaseTemp = remunerationValue - this.directRemunerationInitialCobranza;
    const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
    if (increaseTemp > 0) {
      this.increaseDistributedCobranza = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColorCobranza = INCREASE.colorIncrement;
      this.iconDistributedeCobranza = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseDistributedCobranza = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColorCobranza = INCREASE.colorDecrement;
      this.iconDistributedeCobranza = INCREASE.iconArrowDown;
    } else {
      this.increaseDistributedCobranza = '';
    }
  }

  calculateWeightedComplianceSync(metrics: ComplianceMetrics): number {
    return (
      metrics.complianceNetDisbursement.compliance * metrics.complianceNetDisbursement.weight +
      metrics.complianceActiveClientStock.compliance * metrics.complianceActiveClientStock.weight +
      metrics.complianceLiabilitiesCapture.compliance * metrics.complianceLiabilitiesCapture.weight +
      metrics.compliancePriceManagement.compliance * metrics.compliancePriceManagement.weight +
      metrics.compliancePise9Mora.compliance * metrics.compliancePise9Mora.weight +
      metrics.complianceResolution0Days.compliance * metrics.complianceResolution0Days.weight
    );
  }

  calculateWeightComercial(metrics: ComplianceMetrics): number {
    return (
      metrics.complianceNetDisbursement.compliance * metrics.complianceNetDisbursement.weight +
      metrics.complianceActiveClientStock.compliance * metrics.complianceActiveClientStock.weight +
      metrics.complianceLiabilitiesCapture.compliance * metrics.complianceLiabilitiesCapture.weight +
      metrics.compliancePriceManagement.compliance * metrics.compliancePriceManagement.weight
    );
  }

  calculateWeightCobranza(metrics: ComplianceMetrics): number {
    return (
      metrics.compliancePise9Mora.compliance * metrics.compliancePise9Mora.weight +
      metrics.complianceResolution0Days.compliance * metrics.complianceResolution0Days.weight
    );
  }

  validateFlagActivateRV(): void {
    calculateWeightedCompliance(this.weightedComplianceComercial,this.setting.salesRate) && calculateWeightedCompliance(this.weightedComplianceCobranza,this.setting.collectionRate)
      ? this.flagActivateRV.emit(true)
      : this.flagActivateRV.emit(false);
  }

  private calculateRvComercial(ponderadoComercial: number, ponderadoCobranza: number, amountDistributed: number, weight: number, threshold: number): number {
    if (ponderadoComercial > threshold && ponderadoCobranza > threshold) {
      const rangeComercial = obtenerRango(ponderadoComercial, PayoffCurveCommercial);
      if (rangeComercial) {
        const porcentageComercial = calcularRvFronts(ponderadoComercial, rangeComercial);
        return convertToNumeric(formatPercentageV2((porcentageComercial / 100) * weight * amountDistributed));
      }
    }
    return 0;
  }

  private calculateRvCobranza(ponderadoComercial: number, ponderadoCobranza: number, amountDistributed: number, commercialWeight: number, threshold: number): number {
    if (ponderadoComercial > threshold && ponderadoCobranza > threshold) {
      const rangeCobranza = obtenerRango(ponderadoCobranza, PayoffCurveCollection);
      if (rangeCobranza) {
        const porcentageCobranza = calcularRvFronts(ponderadoCobranza, rangeCobranza);
        return convertToNumeric(formatPercentageV2((porcentageCobranza/100) * commercialWeight * amountDistributed*(this.collectionMultiplier/100)));
      }
    }
    return 0;
  }
  getAchievementPassBalance() {
    this.adnSimulatorService.achievementPassBalanceSubject$.subscribe((achievement) => {
      const passMonth1  = division(achievement,this.dataDecelerators.metaPaseRec);
      const passSummary = (this.dataDecelerators.passMonth2+this.dataDecelerators.passMonth3 +passMonth1);
      const baseAverage = calculateDivision(passSummary,denominatorDecelerator(this.dataDecelerators.passMonth2,this.dataDecelerators.passMonth3))*100;
      this.collectionMultiplier = calculateMultiplier(baseAverage);
      this.updateDirectComercialRemunerationValue( this.ponderadoComercial, this.ponderadoCobranza);
      this.updateDirectCobranzaRemunerationValue(this.ponderadoCobranza,this.ponderadoComercial);
    });
  }
}
