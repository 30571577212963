import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { INCREASE, RV_DATALAYER } from '@shared/constants/adn-simulator.constants';
import { decceleratorsTable } from '@shared/data/adn-intranet/deccelerators.json';
import { DeceleratorsData } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { extractNumber, formatPercentage, removePercentage } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-section-decelerators',
  templateUrl: './section-decelerators.component.html',
  styleUrls: ['./section-decelerators.component.scss']
})
export class SectionDeceleratorsComponent implements OnInit {
  achievement: number = 0;
  compliance: number = 0;
  decceleratorsTable = decceleratorsTable;
  lastThreeMonthsData: { monthName: string; year: string; }[] = [];
  compliance_mes_2: string = '0%';
  compliance_mes_3: string = '0%';
  complianceMesTwo: number = 0;
  complianceMesThree: number = 0;
  complienceAverage: number = 0;
  deceleratorForPassToRecoveries = 0;
  @Input() dataDecelerators: DeceleratorsData = {
    metaPaseRec: 0,
    passMonth2: 0,
    passMonth3: 0,
    achievementPassBalance: 0
  };
  variable = {
    cumplimientoInit: 0,
    increase: "",
    increaseColor: "",
    increaseIcon: ""
  };
  
  constructor(private readonly adnSimulatorService: AdnSimulatorService,
              private cdr: ChangeDetectorRef,
              private dataLayerService: DataLayerService) { 

    this.calculateLastThreeMonths();
  }

  ngOnInit(): void {
    this.complianceMesTwo = (this.dataDecelerators.passMonth2 ? this.dataDecelerators.passMonth2 * 100 : 0);
    this.compliance_mes_2 =  this.complianceMesTwo.toFixed(2) + '%';
    this.complianceMesThree = (this.dataDecelerators.passMonth3 ? this.dataDecelerators.passMonth3 * 100 : 0);
    this.compliance_mes_3 = this.complianceMesThree.toFixed(2) + '%';
    this.variable.cumplimientoInit = (this.dataDecelerators.achievementPassBalance / this.dataDecelerators.metaPaseRec) * 100;
    this.getAchievementPassBalance();
  }

  setAchievement(event: any,  fromDom: boolean = true) {
    if (!fromDom) {
      this.achievement = event;
    } else {
      const value = Number.parseInt(event.target.value.replace(/,/g, ''));
      this.achievement = isNaN(value) ? 0 : value;
    }
    this.compliance = (this.achievement / this.dataDecelerators.metaPaseRec) * 100;
    let divider = ((this.compliance > 0 ? 1 : 0) + (extractNumber(this.compliance_mes_2) > 0 ? 1 : 0) + (extractNumber(this.compliance_mes_3) > 0 ? 1 : 0));
    this.complienceAverage = (this.compliance + removePercentage(this.compliance_mes_2) + removePercentage(this.compliance_mes_3)) / divider || this.compliance;
        
    this.calculateRange();
    this.updateIncrease(this.compliance);
    this.cdr.detectChanges();
  }

  onBlurSetAchievementPassBalance(): void {
    this.dataLayerService.simulatorRVSelect(RV_DATALAYER.actionTabMulti, `${RV_DATALAYER.selectTabMulti}: ${this.achievement}`);
    this.emitValue();
  }

  onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.emitValue();
    }
  }

  private emitValue(): void {
    this.adnSimulatorService.setAchievementPassBalance(this.achievement);
  }

  getAchievementPassBalance() {
    this.adnSimulatorService.achievementPassBalanceSubject$.subscribe((logro) => {
      this.setAchievement(logro, false);
    });
  }
  calculateLastThreeMonths() {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    for (let i = 2; i >= 0; i--) {
      const date = new Date(currentYear, currentMonth - i);
      const monthName = date.toLocaleString('default', { month: 'short' });
      const yearShort = date.getFullYear().toString();
      
      this.lastThreeMonthsData.push({ monthName, year: yearShort });
    }
  }

  calculateRange() {
    this.decceleratorsTable.find((ele) => {
      ele.selected = false;
      if (this.complienceAverage > extractNumber(ele.c11) && this.complienceAverage < extractNumber(ele.c12)) {
        this.deceleratorForPassToRecoveries = extractNumber(ele.c2);
        ele.selected = true;
      }
    });
  }

  private updateIncrease(dataCumplimiento: number): void {
    const increase = dataCumplimiento - this.variable.cumplimientoInit;
    if (increase > 0) {
      this.variable.increase = formatPercentage(increase);
      this.variable.increaseColor = INCREASE.colorIncrement;
      this.variable.increaseIcon = INCREASE.iconArrowUp;
    } else if (increase < 0) {
      this.variable.increase = formatPercentage(increase);
      this.variable.increaseColor = INCREASE.colorDecrement;
      this.variable.increaseIcon = INCREASE.iconArrowDown;
    } else {
      this.variable.increase = '';
    }
  }
}
