
<ngx-skeleton-loader
*ngIf="loader"
[appearance]="'line'"
count="1"
animation="pulse"
[theme]="{ width: '100%', height: '56px', padding: '16px 0px 16px 10px','border-radius': '8px' }"
></ngx-skeleton-loader>

<div class="card-list" *ngIf="!loader">
    <div class="card-select" [ngClass]="{'selected': isSelected}" (click)="onCardClick(order, url,title)">
        <img [src]="isSelected ? selectedIcon : icon" [alt]="title">
        <div class="notification-circle" *ngIf="notification"></div>
        <div class="card-select_title" [ngStyle]="{'color': isSelected ? selectedColor : '#434A54'}">{{ title }}</div>
      </div>
</div>