import { Component, Input, OnInit } from '@angular/core';
import { acceleratorsTable } from '@shared/data/adn-intranet/accelerators.json';
import { extractNumber } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-section-accelerators',
  templateUrl: './section-accelerators.component.html',
  styleUrls: ['./section-accelerators.component.scss'],
})
export class SectionAcceleratorsComponent implements OnInit {
  /* Inputs */
  overcomplianceWeighted = '0%';
  autonomyType: string = 'Sin autonomía completa consecutiva';
  /* ****** */
  overcomplianceIndicator = '';
  imgComplianceWeight = '/assets/icons/adn/x-close.svg';
  acceleratorsTable = acceleratorsTable;
  lastThreeMonthsData: { monthName: string; year: string; autonomyStatus: string }[] = [];

  @Input() autonomyData = {
    finalAutonomy: '-',
    month_1_Autonomy: '-',
    month_2_Autonomy: '-',
    month_3_Autonomy: '-',
  };

  constructor() {}

  ngOnInit(): void {
    this.calculateLastThreeMonths();
  }

  updateOvercomplianceIndicator(): void {
    this.acceleratorsTable.forEach((ele) => {
      const overcomplianceValue = extractNumber(this.overcomplianceWeighted);
      const lowerBound = extractNumber(ele.c11);
      const upperBound = extractNumber(ele.c12);
      if (overcomplianceValue >= lowerBound && overcomplianceValue < upperBound) {
        this.overcomplianceIndicator = this.autonomyData.finalAutonomy.includes('Con') ? ele.c3 : ele.c2;
        ele.selected = true;
      } else {
        ele.selected = false;
      }
    });
  }

  calculateLastThreeMonths() {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    for (let i = 2; i >= 0; i--) {
      const date = new Date(currentYear, currentMonth - i);
      const monthName = date.toLocaleString('default', { month: 'short' });
      const yearShort = date.getFullYear().toString();

      let autonomyStatus = this.getAutonomyStatus(i);

      this.lastThreeMonthsData.push({ monthName, year: yearShort, autonomyStatus });
    }
  }

  getAutonomyStatus(index: number): string {
    switch (index) {
      case 2:
        return this.autonomyData.month_1_Autonomy;
      case 1:
        return this.autonomyData.month_2_Autonomy;
      case 0:
        return this.autonomyData.month_3_Autonomy;
      default:
        return '-';
    }
  }
}
