import { ValidatorFn, Validators, FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import {
  DNI_LENGTH_PATTERN,
  EMAIL_REGEX,
  NAME_REGEX,
  ONLY_NUMBER_REGEX,
  ONLY_NUMBER_WITH_TWO_DECIMALS_REGEX,
  PHONE_NUMBER_REGEX,
} from './regex';

/**
 * @description
 * Usesful to rename the key of the ValidatorError value
 *
 * @usageNotes
 *
 * ```typescript
 *  const validator = Validators.pattern(PHONE_NUMBER_REGEX) // any validator
 *  renameValidatorError(validator, 'newName')
 *    // will return
 *    { newName: any }
 *    // instead
 *    { pattern: any }
 * ```
 *
 */
export const renameValidatorError =
  (validator: ValidatorFn, name: string) =>
    (...s: Parameters<ValidatorFn>): ReturnType<ValidatorFn> => {
      const values = validator(...s);
      return values ? { [name]: values.pattern } : values;
    };

/**
 * Only to detect if the browser is Safari, to redirect to the course in Iphone
 * @returns boolean
 * Author: Leonardo Alvarado
 */
export const isSafariBrowser = () => {
  let is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
  let is_safari = navigator.userAgent.indexOf('Safari') > -1;
  if (is_safari) {
    if (is_chrome)
      // Chrome seems to have both Chrome and Safari userAgents
      return false;
    else return true;
  }
  return false;
};

/** Reactive forms validators*/
export const BASIC_STRING_VALIDATORS = (isRequired: boolean = true, maxLength: number = 100) => {
  let validators = [Validators.maxLength(maxLength)];
  if (isRequired) validators.push(Validators.required);
  return validators;
};

export const NAME_VALIDATORS = (maxLength: number = 50, isRequired: boolean = false) => {
  if (isRequired) {
    return [
      Validators.required,
      Validators.maxLength(maxLength),
      renameValidatorError(Validators.pattern(NAME_REGEX), 'onlyLetter'),
    ];
  }
  return [Validators.maxLength(maxLength), renameValidatorError(Validators.pattern(NAME_REGEX), 'onlyLetter')];
};

export const PHONE_VALIDATORS = (isRequired: boolean = true, minLength: number = 9, maxLength: number = 9) => {
  if (isRequired) {
    return [
      Validators.required,
      Validators.minLength(minLength),
      Validators.maxLength(maxLength),
      renameValidatorError(Validators.pattern(PHONE_NUMBER_REGEX), 'phoneNumber'),
      renameValidatorError(Validators.pattern(ONLY_NUMBER_REGEX), 'onlyNumber'),
    ];
  } else {
    return [
      Validators.minLength(minLength),
      Validators.maxLength(maxLength),
      renameValidatorError(Validators.pattern(PHONE_NUMBER_REGEX), 'phoneNumber'),
      renameValidatorError(Validators.pattern(ONLY_NUMBER_REGEX), 'onlyNumber'),
    ];
  }
};

export const EMAIL_VALIDATORS = (maxLength: number = 100) => [
  Validators.required,
  Validators.maxLength(maxLength),
  Validators.pattern(EMAIL_REGEX),
];

export const DOCUMENT_VALIDATORS = (
  isRequired: boolean = false,
  pattern: string = DNI_LENGTH_PATTERN,
  minLength: number = 8,
  maxLength: number = 8
) => {
  if (isRequired) {
    return [
      Validators.required,
      Validators.pattern(pattern),
      Validators.minLength(minLength),
      Validators.maxLength(maxLength),
    ];
  }
  return [Validators.pattern(pattern), Validators.minLength(minLength), Validators.maxLength(maxLength)];
};

export const RUC_VALIDATORS = (isRequired: boolean = true) => {
  let validators = [
    Validators.required,
    Validators.minLength(11),
    Validators.maxLength(11),
    renameValidatorError(Validators.pattern(ONLY_NUMBER_REGEX), 'onlyNumber'),
  ];
  if (isRequired) validators.push(Validators.required);
  return validators;
};

export const PHONE_CONFIRMATION_VALIDATOR = (group: FormGroup, inputControl: string, toCompareControl: string) => {
  let input = group.get(inputControl);
  let toCompare = group.get(toCompareControl);
  if (input?.value !== null && toCompare?.value !== input?.value && (input?.touched || input?.dirty)) {
    input?.setErrors({ mismatchedPhones: true });
    input?.markAsTouched();
    return { mismatchedPhones: true };
  }
  return null;
};

export const DATE_VALIDATOR = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value || '';
    if (value === '') return null;

    const date = new Date(value);

    if (value !== date.toISOString().slice(0, 10)) {
      return { invalidDate: true };
    }

    return null;
  };
}

export const PASSWORD_VALIDATORS = (minLength: number = 6, maxLength: number = 30) => [
  Validators.required,
  Validators.maxLength(maxLength),
  Validators.minLength(minLength),
  renameValidatorError(Validators.pattern(/[A-Z]/), 'oneUpper'),
  renameValidatorError(Validators.pattern(/[0-9]/), 'oneNumber'),
  renameValidatorError(Validators.pattern(/[a-zA-Z]/), 'oneLetter'),
];

export const AMOUNT_VALIDATORS = (isRequired: boolean = false, maxLength: number = 18) => {
  let validators = [
    Validators.maxLength(maxLength),
    renameValidatorError(Validators.pattern(ONLY_NUMBER_WITH_TWO_DECIMALS_REGEX), 'onlyNumber'),
  ];
  if (isRequired) validators.push(Validators.required);
  return validators;
};

export function setNullValidators(group: FormGroup, inputControl: string) {
  const control = group.get(inputControl);
  control?.setValidators([Validators.nullValidator]);
  control?.setValue('');
}

export function setNullFields(group: FormGroup, keys: string[]) {
  keys.forEach((field) => {
    setNullValidators(group, field);
  });
}

export function updateValidityFields(group: FormGroup, keys: string[]) {
  keys.forEach((field) => {
    group.get(field)?.updateValueAndValidity();
  });
}

export function isAValidNumber(valor: string) {
  const number = parseFloat(valor.replace(/,/g, ''));
  return !isNaN(number);
}

export function confirmField(group: FormGroup, field: string, confirmField: string) {
  if (group.get(field)?.value !== group.get(confirmField)?.value) {
    group.get(confirmField)?.setErrors({ mismatchedFields: true });
    group.get(confirmField)?.markAsTouched();
    return { mismatchedFields: true };
  } else {
    group.get(confirmField)?.setErrors(null);
    return null;
  }
}


export function birthdayValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = validateBirthday(control.value);
    return valid ? null : { 'invalidBirthday': { value: control.value } };
  };
}

export function validateBirthday(birthdayString: string | null, ageStart?: any): boolean {
  const startAge = ageStart;
  if (!birthdayString) {
    return false;
  }
  const birthday = new Date(birthdayString);
  const today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();
  if (
    today.getMonth() < birthday.getMonth() ||
    (today.getMonth() === birthday.getMonth() && today.getDate() < birthday.getDate())
  ) {
    age--;
  }
  return age >= startAge && age <= 71;
}

export function birthdayValidatorTypeBusiness(typeBusiness: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = validateBirthday(control.value, typeBusiness);
    return valid ? null : { 'invalidBirthday': { value: control.value } };
  };
}

export function passwordValidator(control: AbstractControl): { [key: string]: any } | null {
  const password = control.value;

  if (!password) {
    return null;
  }

  const errors: any = {};
  if (password.length < 10) {
    errors.minLength = true;
  }

  const hasNumber = /[0-9]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasSymbol = /[^a-zA-Z0-9]/.test(password);

  let charTypes = 0;
  if (hasNumber) charTypes++;
  if (hasUppercase) charTypes++;
  if (hasLowercase) charTypes++;
  if (hasSymbol) charTypes++;

  if (!hasNumber || !hasUppercase || !hasLowercase || !hasSymbol) {
    errors.missingCharTypes = true;
  }

  return Object.keys(errors).length ? errors : null;
}