import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SimulatorComponent } from "./pages/simulator/simulator.component";
import { AdnDashboardComponent } from "./adn-dashboard/adn-dashboard.component";
import { NewsComponent } from "./pages/news/news.component";
import { AdnSettingsResolver } from "@shared/resolvers/adn-settings.resolver";


const routes: Routes = [
    {  path: '',
       component: AdnDashboardComponent,
       resolve: { settings: AdnSettingsResolver },
      children: [
        {
          path: 'simulador',
          component: SimulatorComponent,
          data: {
            title: 'Simulador-RV - Mibanco Lab',
            pageName: 'Simulador-RV',
          },
        },
        {
          path: 'noticias',
          component: NewsComponent,
          data: {
            title: 'Noticias-RV - Mibanco Lab',
            pageName: 'Noticias-RV',
          },
        },
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'noticias',
        }]
    },

];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class AdnIntranetRoutingModule {}
  