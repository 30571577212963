import { Component, Input} from '@angular/core';

@Component({
  selector: 'milab-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {
  @Input() messageTitle: string = 'La lista está vacía';
  @Input() messageSubtitle: string = 'Si tienes créditos desenfocados o extornados, aparecerán en esta lista.';
  @Input() imageUrl: string = '/assets/icons/adn/pic-annotation-alert-off.svg';
  constructor() { }

}
