<div class="warning-time">
    <div class="warning-time__header">
        <img 
          src="/assets/icons/adn/close-modal.svg" 
          alt="Cerrar" 
          class="warning-time__close-icon" 
          (click)="activeModal.close()" />
    </div>
    <div class="warning-time__body">
        <div class="d-flex  justify-content-center ">  
            <img src="/assets/icons/adn/pic-annotation-alert.svg" alt="pic-annotation-alert">
        </div>
     <h2 class="warning-time__body_title mt-3">¿Aún estás aquí?</h2>
      <p>Para tu seguridad, tu sesión se cerrará por inactividad en:</p>
      <div class="title-count mt-3 mb-3">{{ countdown }}s</div>
      <p>Presiona “Continuar aquí” para quedarte en el simulador, o “Cerrar sesión” para finalizar tu sesión.</p>
    </div>

    <div class="warning-time__footer mt-3">
      <button type="button" 
              class="warning-time__footer__volver t-16-700 btn1"
              (click)="activeModal.close(true)">Cerrar sesión
      </button>
      <button type="button" 
              class="warning-time__footer__button t-16-700 btn2"
              (click)="activeModal.close()">Continuar aquí
      </button>
    </div>
  </div>