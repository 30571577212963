import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { INCREASE, RV_DATALAYER } from '@shared/constants/adn-simulator.constants';
import { InputItem, managementItem, VariableDirect } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { calculateDivisionInput, 
         calculateMoraCompliance, 
         calculatePriceManagementCompliance, 
         calculateResolutionCompliance, 
         complianceColorValid, 
         convertToNumeric, 
         convertToNumericWithCommas, 
         convertToPercentageV2, 
         divideByHundred, 
         division, 
         formatPercentage } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-variable-collection-card-mobile',
  templateUrl: './variable-collection-card-mobile.component.html',
  styleUrls: ['./variable-collection-card-mobile.component.scss']
})
export class VariableCollectionCardMobileComponent implements OnInit {
  @Input() id!: string;
  @Input() variable!:VariableDirect;
  @Input() managementItem!: managementItem;

  isRotated: boolean = false;
  isTable: boolean = false;

  constructor(private adnSimulatorService: AdnSimulatorService,
              private cdr: ChangeDetectorRef,
              private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
  }
  activeTable(){
    this.isTable = !this.isTable;
    this.isRotated = !this.isRotated;
  }


   onInputChange(event: InputItem): void {
    this.dataLayerService.simulatorRVSelect(RV_DATALAYER.actionTabValues,`${event.name}: ${event.value.toString()}`);
    const taza = calculateDivisionInput(event.value,convertToNumeric(this.managementItem.valueTwo));
    if (this.variable && this.variable.metas) {
        this.variable.logro = event.value;

      if (event.key === 'gestionPrecios') {
        this.managementItem.valueOne = event.value;
        this.variable.logro = taza;
        const [minPrices, prices100, prices125, prices150] = this.variable.metas.content[0].map(parseFloat);
        const dataCumplimientoGestionPrecios = calculatePriceManagementCompliance(
          taza,
          minPrices,
          prices100,
          prices125,
          prices150
        );
        this.updateIncrease(this.variable, dataCumplimientoGestionPrecios);
        this.adnSimulatorService.updateCompliancePriceManagement(convertToNumeric(dataCumplimientoGestionPrecios));
        this.variable.cumplimiento = dataCumplimientoGestionPrecios.toString().concat('%');
        this.variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimientoGestionPrecios));
      }

      if (event.key === 'pise9') {
        this.managementItem.valueOne = event.value;
        this.adnSimulatorService.setPise9Mora(event.value);
        const logro = division(event.value,convertToNumeric(convertToNumericWithCommas(this.managementItem.valueTwo)));
        this.variable.logro = parseFloat(convertToPercentageV2(logro));
        const [minPise9, pise9100, pise9125, pise9150] = this.variable.metas.content[0].map(parseFloat);

        const dataCumplimiento = calculateMoraCompliance(
          convertToNumeric(logro),
          divideByHundred(minPise9),
          divideByHundred(pise9100),
          divideByHundred(pise9125),
          divideByHundred(pise9150)
        );
        this.updateIncrease(this.variable, dataCumplimiento);
        this.adnSimulatorService.updateCompliancePise9Mora(convertToNumeric(dataCumplimiento));
        this.variable.cumplimiento = dataCumplimiento.toString().concat('%');
        this.variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimiento));
      }

      if (event.key === 'resolucion') {
        this.managementItem.valueOne = event.value;
        this.adnSimulatorService.setResolutionBalance(event.value);
        const logro = division(event.value,convertToNumeric(convertToNumericWithCommas(this.managementItem.valueTwo)));
        this.variable.logro = parseFloat(convertToPercentageV2(logro));
        const [minResolution0, resolution0100, resolution0125, resolution0150] = this.variable.metas.content[0].map(parseFloat);
        const dataCumplimiento = calculateResolutionCompliance(
          convertToNumeric(logro),
          divideByHundred(minResolution0),
          divideByHundred(resolution0100),
          divideByHundred(resolution0125),
          divideByHundred(resolution0150)
        );
        this.updateIncrease(this.variable, dataCumplimiento);
        this.adnSimulatorService.updateComplianceResolution0Days(convertToNumeric(dataCumplimiento));
        this.variable.cumplimiento = dataCumplimiento.toString().concat('%');
        this.variable.cumplimientoColor = complianceColorValid(convertToNumeric(dataCumplimiento));
      }
   }
   this.cdr.detectChanges();
  }

  private updateIncrease(variable: VariableDirect, dataCumplimiento: number): void {
    const increase = dataCumplimiento - variable.cumplimientoInit;
    if (increase > 0) {
      this.variable.increase = formatPercentage(increase);
      this.variable.increaseColor = INCREASE.colorIncrement;
      this.variable.increaseIcon = INCREASE.iconArrowUp;
    } else if (increase < 0) {
      this.variable.increase = formatPercentage(increase);
      this.variable.increaseColor = INCREASE.colorDecrement;
      this.variable.increaseIcon = INCREASE.iconArrowDown;
    } else {
      this.variable.increase = '';
    }
  }
}
