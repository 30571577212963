import { Component, OnDestroy, OnInit } from '@angular/core';
import { ITabItem } from '@shared/interfaces';
import { tabItemDashboard } from '@shared/data/adn/tab.json';
import { AdnSetting, CalculationInitial, ComplianceData, DeceleratorsData, managementItem, SimulationResult, VariableDirect } from '@shared/interfaces/frontend/adn';
import { Subscription } from 'rxjs';
import { AdnCentralizedDataService } from '@shared/services/adn-centralized-data.service';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calculateFactorAbsence,convertToNumeric} from '@shared/utils/adn-simulator.utils';


@Component({
  selector: 'milab-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit, OnDestroy {

  tabItems: ITabItem[] = tabItemDashboard;
  selectedTab!: ITabItem;

  private subscription: Subscription = new Subscription();
  managementVarCommercial!: managementItem;
  managementVarCollectionResolution!: managementItem;
  managementVarCollectionPise!: managementItem;

  listVariable: VariableDirect[] = [];
  listCollectionVariablesPise9: VariableDirect[] = [];
  listCollectionVariablesResolutions: VariableDirect[] = [];

  metaUpdatedDate!: string;
  achievementsDate!: string;
  workedDays: number = 0;

  amountDistributed: number= 0 ;
  amountDistributedString!: string ;
  weightedComplianceComercialInitial: number = 0;
  weightedComplianceCobranzaInitial: number = 0;

  acceleratorPercentInit:number = 0;
  deceleratorPercentInit:number = 0;
  cumpliminetoNeto:number = 0;

  dataDecelerators: DeceleratorsData= {
    metaPaseRec: 0,
    passMonth2: 0,
    passMonth3: 0,
    achievementPassBalance: 0
  };
  dataBonus = {
    amount20k: 0,
    bonus20k: 0
  };
  amountBonus: number = 0;
  gestionPreciosLogro: number = 0;

  pise9Achievement: number = 0;
  resolutionAchievement: number = 0;

  multipliersFinalAutonomy:'sin autonomia' | 'con autonomia' = 'sin autonomia';
  multipliersPassMonth2: number = 0;
  multipliersPassMonth3: number = 0;
  multipliersMetaPaseRec: number = 0;

  calculateBonusAmount20k: number = 0;
  calculateBonusMeta20k:  number = 0;
  calculateBonusAmount: number = 0;
  autonomyData = {
    finalAutonomy: '-',
    month_1_Autonomy: '-',
    month_2_Autonomy: '-',
    month_3_Autonomy: '-'
  }

  finalRvInitial: number = 0;
  factorAucencia: number = 1;
  pasivosMeta:{
    liabilities300: number,
    liabilities400: number
  } = {
    liabilities300: 0,
    liabilities400: 0
  }

  flagActivateRV: boolean = false;

  rvCobranza: number = 0;
  rvComercial: number = 0;
  setting!: AdnSetting;

  constructor(private centralizedDataService: AdnCentralizedDataService,
              private adnSimulatorService:AdnSimulatorService) { 
              this.setting = this.adnSimulatorService.getSetting();
              }

  ngOnInit(): void {
    this.selectedTab = this.tabItems[0];
    this.initSubscription();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleSelectedTab(value: ITabItem): void {
    this.selectedTab = value;
    this.tabItems = tabItemDashboard.map((item) => {
      if (item.label === value.label) {
        return { ...item, active: true };
      } else {
        return { ...item, active: false };
      }
    });
  }

  
  initSubscription(): void {
    this.subscription.add(
      this.centralizedDataService.simulatorData$.subscribe((data: SimulationResult | null) => {
        if (data) {
          this.workedDays = data.targets.workedDays;
          this.createCommercialVariables(data.calculations);
          this.dataDecelerators.metaPaseRec = data.targets.metaPaseRec;
          this.dataDecelerators.passMonth2 = data.targets.passMonth2;
          this.dataDecelerators.passMonth3 = data.targets.passMonth3;
          this.dataDecelerators.achievementPassBalance = data.achievements.achievementPassBalance;
          this.dataBonus.amount20k = data.achievements.amount20k;
          this.dataBonus.bonus20k = data.targets.bonus20k;
          this.autonomyData.finalAutonomy = data.targets.finalAutonomy;
          this.autonomyData.month_1_Autonomy = data.targets.flagAutonomyMonth01;
          this.autonomyData.month_2_Autonomy = data.targets.autonomyMonth02;
          this.autonomyData.month_3_Autonomy = data.targets.autonomyMonth03;
          this.setAchievement(data.achievements.achievementPassBalance);
          this.adnSimulatorService.setDataDecelerators(this.dataDecelerators);
          this.pasivosMeta.liabilities300 = data.targets.liabilities300;
          this.pasivosMeta.liabilities400 = data.targets.liabilities400;

          this.multipliersMetaPaseRec = data.targets.metaPaseRec;
          this.calculateBonusAmount20k= data.achievements.amount20k;
          this.calculateBonusMeta20k= data.targets.bonus20k;
        }
      })
    );
  } 

  createCommercialVariables(calculationInitial: CalculationInitial): void {
    this.amountDistributed = calculationInitial.amountDistributed;
    this.amountDistributedString = calculationInitial.amountDistributedString;
    this.metaUpdatedDate = calculationInitial.metaUpdatedDate;
    this.achievementsDate = calculationInitial.achievementsDate; 

    this.cumpliminetoNeto = convertToNumeric(calculationInitial.complianceData.complianceNetDisbursement);
    this.updateCompliance(calculationInitial.complianceData);

    this.weightedComplianceComercialInitial = calculationInitial.complianceComercial;
    this.weightedComplianceCobranzaInitial = calculationInitial.complianceCobranza;
    this.deceleratorPercentInit =calculationInitial.multipliersInit;
    this.calculateBonusAmount =calculationInitial.calculateBonusAmount;
    this.factorAucencia = calculationInitial.factorAucencia;

    this.rvComercial =calculationInitial.rvComercial;
    this.rvCobranza  =calculationInitial.rvCobranza;
    this.finalRvInitial = calculationInitial.finalRvInitial;


    this.managementVarCommercial = calculationInitial.managementVarCommercial;
    this.managementVarCollectionPise = calculationInitial.managementVarCollectionPise;
    this.managementVarCollectionResolution = calculationInitial.managementVarCollectionResolution;

    this.listVariable = calculationInitial.listVariable;
    this.listCollectionVariablesPise9 = calculationInitial.listCollectionVariablesPise9;
    this.listCollectionVariablesResolutions = calculationInitial.listCollectionVariablesResolutions;

  }

  updateCompliance(complianceData: ComplianceData): void {
    this.adnSimulatorService.setMetrics({
      complianceNetDisbursement: { compliance: complianceData.complianceNetDisbursement, weight: 0.2 },
      complianceActiveClientStock: { compliance: complianceData.complianceActiveClientStock, weight: 0.2 },
      complianceLiabilitiesCapture: { compliance: complianceData.complianceLiabilitiesCapture, weight: 0.1 },
      compliancePriceManagement: { compliance: complianceData.compliancePriceManagement, weight: 0.1 },
      compliancePise9Mora: { compliance: complianceData.compliancePise9Mora, weight: 0.2 },
      complianceResolution0Days: { compliance: complianceData.complianceResolution0Days, weight: 0.2 },
    });
  }

  setAchievement(achievementPassBalance: number) {
    this.adnSimulatorService.setAchievementPassBalance(achievementPassBalance);
  }

  calculateFactorAbsenceInit(){
    this.factorAucencia = calculateFactorAbsence(this.workedDays);
  }
  handleFlagActivateRV(value: boolean): void {
    this.flagActivateRV = value;
  }
}