<div class="section-variables">
    <table class="card-variables-table">
        <thead>
            <tr>
              <th class="w-14 text-left ">{{variableItem}}</th>
              <th class="w-10">Peso</th>
              <th class="w-13">Logro</th>
              <th class="w-13">Cumplimiento</th>
              <th class="w-50">Metas</th>
            </tr>
          </thead>
      <tbody>
        <tr *ngFor="let variable of listVariable" >
          <td class="w-14 text-left td-title color-dark">{{ variable.name }}</td>
          <td class="w-10" >{{ variable.price }}</td>
          <td class="w-13" >
            <div *ngIf="variable.isInput; else showLogro">
              <milab-number-input 
                   class=" w-120" 
                   [item]="{ key: variable.id, value: variable.logro,name:variable.name}"
                   (valueChange)="onLogroChange($event)">
               </milab-number-input>
            </div>
            <ng-template #showLogro>
              {{ variable.logro }}%
            </ng-template>
          </td>
          <td class="w-13" [ngClass]="variable.cumplimientoColor">
            <div class="vertical-stack">
              {{ variable.cumplimiento }}
              <div class="increase-wrapper" *ngIf="variable.increase && variable.increase.length > 0">
                <milab-increase-card 
                   [increase]="variable.increase"
                   [icon]="variable.increaseIcon"
                   [color]="variable.increaseColor">
                </milab-increase-card>
              </div>
            </div>
          </td>
          <td class="w-50">
            <milab-customer-table  
              class="section-table"
              [headers]="variable.metas.headers" 
              [content]="variable.metas.content" 
              headerBgColor="#17749B">
            </milab-customer-table>
          </td>
        </tr>
      </tbody>
    </table>

  <milab-management-collections-card
    [itemLabelOne]="managementItem.labelOne"
    [itemLabelTwo]="managementItem.labelTwo"
    [valueTwo]="managementItem.valueTwo" 
    [valueOne]="managementItem.valueOne"
    key="gestionPrecios"
    (managementValueChange)="onManagementValueChange($event)">
  </milab-management-collections-card>
  </div>