import { Injectable } from "@angular/core";
import { Resolve, Router } from "@angular/router";
import { PayoffCurveCollection, PayoffCurveCommercial } from "@shared/constants/adn-simulator.constants";
import { AdnAchievement, AdnSetting, AdnTarget, CalculationInitial, ComplianceData, managementItem, SimulationResult, VariableDirect } from "@shared/interfaces/frontend/adn";
import { AdnCentralizedDataService } from "@shared/services/adn-centralized-data.service";
import { AdnSimulatorService } from "@shared/services/adn-simulator.service";
import { calcularRvFronts, calculateBonus, calculateCompliance, calculateComplianceStok, calculateDivision, calculateDivisionInput, calculateFactorAbsence, calculateLiabilitiesManagementCompliance, calculateMoraCompliance, calculateMultiplier, calculatePriceManagementCompliance, calculateResolutionCompliance, complianceColor, convertIntegerToString, convertToNumeric, convertToPercentage, convertToPercentageDecimal, convertToPercentageV2, denominatorDecelerator, division, formatNumberWithCommas, formatNumberWithCommasNoDecimals, formatPercentageV2, obtenerRango } from "@shared/utils/adn-simulator.utils";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class AdnSettingsResolver implements Resolve<any> {
  constructor(private adnSimulatorService: AdnSimulatorService, 
              private router: Router,
              private adnCentralizedDataService:AdnCentralizedDataService) { }

  resolve(): Observable<any> {
    const adnUserProfile = JSON.parse(localStorage.getItem('adnUserProfile') || '{}');
    const code = adnUserProfile?.codeAdn;

    if (!code) {
      this.router.navigate(['/ingresar-adn']);
      return of(null);
    }

    return forkJoin({
      settings: this.adnSimulatorService.loadSetting(),
      initialData: this.adnSimulatorService.loadInitialData(code)
    }).pipe(
      map((result) => {
        const targets = result.initialData.targets?.[0];
        const achievements = result.initialData.achievements?.[0];

        if (!targets || !achievements) {
          console.error('Targets or Achievements data is missing');
          return null;
        }

        const calculations = this.createCommercialVariables(targets, achievements, result.settings);
        const resolverData:SimulationResult = {
          settings: result.settings,
          achievements: achievements,
          targets: targets,
          calculations
        };
        this.adnCentralizedDataService.setInitialSimulatorData(resolverData);
        return resolverData;
      }),
      catchError((error) => {
        console.error('Error loading settings or initial data:', error);
        this.router.navigate(['/ingresar-adn']);
        return of(null);
      })
    );
  }

  createCommercialVariables(targets: AdnTarget, achievements: AdnAchievement, settings: AdnSetting): CalculationInitial {
    const amountDistributed = targets.amountToDistribute;
    const amountDistributedString = formatNumberWithCommas(targets.amountToDistribute); //opcional
    const metaUpdatedDate = convertIntegerToString(targets.processId);
    const achievementsDate = convertIntegerToString(achievements.processId);
    const multipliersMetaPaseRec = targets.metaPaseRec;
    const bonusAmount20k = achievements.amount20k;
    const bonusMeta20k = targets.bonus20k;

    const gestionPreciosLogro = calculateDivisionInput(achievements.realRateScde, achievements.optimalRateScde);
    const pise9Achievement = division(achievements.pise9, achievements.balance0To30);
    const resolutionAchievement = division(achievements.balanceResolution0, achievements.balanceCap0);

    const complianceData: ComplianceData = {
      complianceNetDisbursement: this.convertAndCalculateComplianceNeto(achievements, targets),
      complianceActiveClientStock: this.convertAndCalculateComplianceStok(achievements, targets),
      complianceLiabilitiesCapture: this.convertAndCalculateCompliancePasivos(achievements, targets),
      compliancePriceManagement: this.calculatePriceManagement(targets, gestionPreciosLogro),
      compliancePise9Mora: this.calculatePise9Compliance(targets, pise9Achievement),
      complianceResolution0Days: this.calculateResolutionCompliance(targets, resolutionAchievement)
    }

    this.updateCompliance(complianceData);
    const complianceComercial = (this.calculateComplianceComercial(complianceData) / 60) * 100;
    const complianceCobranza = (this.calculateComplianceCobranza(complianceData) / 40) * 100;
    const multipliersInit = this.calculateMultipliers(targets, achievements);

    //Seting information
    const isComplianceActive = (complianceComercial > settings.salesRate && complianceCobranza > settings.collectionRate);
    const comercialWeight = 0.6;
    const collectionWeight = 0.4;

    const calculateBonusAmount = this.calculateBonus02K(achievements.amount20k, targets.bonus20k, isComplianceActive);
    const factorAucencia = calculateFactorAbsence(targets.workedDays);

    const rvComercial = this.calculateRvComercial(complianceComercial, amountDistributed, comercialWeight, isComplianceActive);
    const rvCobranza = this.calculateRvCobranza(complianceCobranza, amountDistributed, collectionWeight, isComplianceActive,multipliersInit);
    const finalRvInitial = isComplianceActive ? (rvComercial + rvCobranza) : 0;

    const managementVarCommercial = this.createManagementItem(
      'Tasa real', parseFloat(formatPercentageV2(achievements.realRateScde)),
      'Tasa óptima', formatNumberWithCommas(achievements.optimalRateScde)
    );

    const managementVarCollectionPise = this.createManagementItem(
      'Mora Real > 8 (S/)', achievements.pise9,
      'Saldo de cartera 0-30 (S/)', formatNumberWithCommas(achievements.balance0To30)
    );

    const managementVarCollectionResolution = this.createManagementItem(
      'Saldo resuelto 0 días (S/)', achievements.balanceResolution0,
      'Ola 0 días (S/)', formatNumberWithCommas(achievements.balanceCap0)
    );

    const listVariable = this.createCommercialVariablesList(targets, achievements, complianceData, gestionPreciosLogro);
    const listCollectionVariablesPise9 = this.createPise9Variables(targets, complianceData.compliancePise9Mora, pise9Achievement);
    const listCollectionVariablesResolutions = this.createResolutionVariables(targets, complianceData.complianceResolution0Days, resolutionAchievement);
    
    return {
      amountDistributed,
      amountDistributedString,
      metaUpdatedDate,
      achievementsDate,
      multipliersMetaPaseRec,
      bonusAmount20k,
      bonusMeta20k,
      complianceData,
      complianceComercial,
      complianceCobranza,
      multipliersInit,
      isComplianceActive,
      comercialWeight,
      collectionWeight,
      calculateBonusAmount,
      factorAucencia,
      rvComercial,
      rvCobranza,
      finalRvInitial,
      managementVarCommercial,
      managementVarCollectionPise,
      managementVarCollectionResolution,
      listVariable,
      listCollectionVariablesPise9,
      listCollectionVariablesResolutions
    };
  }

  private createResolutionVariables(targets: AdnTarget, dataComplianceResolution: number, resolutionAchievement: number): VariableDirect[] {
    return [
      {
        id: "resolucion",
        name: "Resolución 0 días",
        logro: parseFloat(convertToPercentageV2(resolutionAchievement)),
        price: convertToPercentage(targets.resolution0Weight),
        isInput: false,
        cumplimiento: `${dataComplianceResolution}%`,
        cumplimientoInit: dataComplianceResolution,
        cumplimientoColor: complianceColor(
          convertToNumeric(resolutionAchievement),
          convertToNumeric(targets.minResolution0),
          convertToNumeric(targets.resolution0100)),
        metas: {
          headers: ["Mínimo", "100%", "125%", "150%"],
          content: [
            [
              convertToPercentageDecimal(targets.minResolution0),
              convertToPercentageDecimal(targets.resolution0100),
              convertToPercentageDecimal(targets.resolution0125),
              convertToPercentageDecimal(targets.resolution0150)
            ]
          ]
        },
        increase: ''
      }
    ];
  }

  private createPise9Variables(targets: AdnTarget, dataCompliancePise9: number, pise9Achievement: number): VariableDirect[] {
    return [
      {
        id: "pise9",
        name: "Pise 9 (Mora > 8)",
        logro: parseFloat(convertToPercentageV2(pise9Achievement)),
        price: convertToPercentage(targets.pise9Weight),
        isInput: false,
        cumplimiento: `${dataCompliancePise9}%`,
        cumplimientoInit: dataCompliancePise9,
        cumplimientoColor: complianceColor(
          convertToNumeric(pise9Achievement),
          convertToNumeric(targets.minPise9),
          convertToNumeric(targets.pise9100)),
        metas: {
          headers: ["Mínimo", "100%", "125%", "150%"],
          content: [
            [
              convertToPercentageDecimal(targets.minPise9),
              convertToPercentageDecimal(targets.pise9100),
              convertToPercentageDecimal(targets.pise9125),
              convertToPercentageDecimal(targets.pise9150)
            ]
          ]
        },
        increase: ''
      }
    ];
  }

  private createCommercialVariablesList(targets: AdnTarget, achievements: AdnAchievement, complianceData: ComplianceData, gestionPreciosLogro: number): VariableDirect[] {
    return [
      {
        id: "neto",
        name: "Desembolso Neto (S/)",
        logro: achievements.netDisbursement,
        price: convertToPercentage(targets.disbursementWeight),
        isInput: true,
        cumplimiento: `${complianceData.complianceNetDisbursement}%`,
        cumplimientoInit: complianceData.complianceNetDisbursement,
        cumplimientoColor: complianceColor(
          convertToNumeric(achievements.netDisbursement),
          convertToNumeric(targets.minDisbursement),
          convertToNumeric(targets.disbursement100)
        ),
        metas: {
          headers: ["Mínimo", "100%", "150%", "200%"],
          content: [
            [
              formatNumberWithCommasNoDecimals(targets.minDisbursement),
              formatNumberWithCommasNoDecimals(targets.disbursement100),
              formatNumberWithCommasNoDecimals(targets.disbursement150),
              formatNumberWithCommasNoDecimals(targets.disbursement200)
            ]
          ]
        },
        increase: ''
      },
      {
        id: "stocks",
        name: "Stock de Clientes Activos",
        logro: achievements.stockClients,
        price: convertToPercentage(targets.clientWeight),
        isInput: true,
        cumplimiento: `${complianceData.complianceActiveClientStock}%`,
        cumplimientoInit: complianceData.complianceActiveClientStock,
        cumplimientoColor: complianceColor(achievements.stockClients, targets.minClients, targets.clients100),
        metas: {
          headers: ["Mínimo", "100%", "150%", "200%"],
          content: [
            [
              formatNumberWithCommasNoDecimals(targets.minClients),
              formatNumberWithCommasNoDecimals(targets.clients100),
              formatNumberWithCommasNoDecimals(targets.clients150),
              formatNumberWithCommasNoDecimals(targets.clients200)
            ]
          ]
        },
        increase: ''
      },
      {
        id: "pasivos",
        name: "Captación de Pasivos (S/)",
        logro: achievements.openedPasAmount,
        price: convertToPercentage(targets.liabilityWeight),
        isInput: true,
        cumplimiento: `${complianceData.complianceLiabilitiesCapture}%`,
        cumplimientoInit: complianceData.complianceLiabilitiesCapture,
        cumplimientoColor: complianceColor(achievements.openedPasAmount, targets.minLiabilities, targets.liabilities100),
        metas: {
          headers: ["Mínimo", "100%", "150%", "200%"],
          content: [
            [
              formatNumberWithCommasNoDecimals(targets.minLiabilities),
              formatNumberWithCommasNoDecimals(targets.liabilities100),
              formatNumberWithCommasNoDecimals(targets.liabilities150),
              formatNumberWithCommasNoDecimals(targets.liabilities200)
            ]
          ]
        },
        increase: ''
      },
      {
        id: "gestionPrecios",
        name: "Gestión de precios",
        logro: gestionPreciosLogro,
        price: convertToPercentage(targets.priceWeight),
        isInput: false,
        cumplimiento: `${complianceData.compliancePriceManagement}%`,
        cumplimientoInit: complianceData.compliancePriceManagement,
        cumplimientoColor: complianceColor(gestionPreciosLogro / 100, targets.minPrices, targets.prices100),
        metas: {
          headers: ["Mínimo", "100%", "125%", "150%"],
          content: [
            [
              convertToPercentageDecimal(targets.minPrices),
              convertToPercentageDecimal(targets.prices100),
              convertToPercentageDecimal(targets.prices125),
              convertToPercentageDecimal(targets.prices150)
            ]
          ]
        },
        increase: ''
      }
    ];
  }


  private createManagementItem(labelOne: string, valueOne: number, labelTwo: string, valueTwo: string): managementItem {
    return { labelOne, valueOne, labelTwo, valueTwo };
  }
  private calculateRvComercial(ponderadoComercial: number, amountDistributed: number, commercialWeight: number, isComplianceActive: boolean): number {
    if (isComplianceActive) {
      const rangeComercial = obtenerRango(ponderadoComercial, PayoffCurveCommercial);
      if (rangeComercial) {
        const porcentageComercial = calcularRvFronts(ponderadoComercial, rangeComercial);
        return convertToNumeric(formatPercentageV2((porcentageComercial / 100) * commercialWeight * amountDistributed));
      }
    }
    return 0;
  }

  private calculateRvCobranza(ponderadoCobranza: number, amountDistributed: number, commercialWeight: number, isComplianceActive: boolean,multipliersInit:number): number {
    if (isComplianceActive) {
      const rangeCobranza = obtenerRango(ponderadoCobranza, PayoffCurveCollection);
      if (rangeCobranza) {
        const porcentageCobranza = calcularRvFronts(ponderadoCobranza, rangeCobranza);
        return convertToNumeric(formatPercentageV2((porcentageCobranza / 100) * commercialWeight * amountDistributed*(multipliersInit/100)));
      }
    }
    return 0;
  }

  calculateBonus02K(amount20k: number, meta20k: number, isComplianceActive: boolean): number {
    this.adnSimulatorService.setAmount20k(amount20k);
    const complianceStatus = isComplianceActive ? 1 : 0;
    const calculateBonusExceeds90 = calculateDivisionInput(amount20k, meta20k) > 90 ? 1 : 0;
    return calculateBonus(complianceStatus, calculateBonusExceeds90, amount20k);
  }


  calculateMultipliers(targets: AdnTarget, achievements: AdnAchievement): number {
    const passMonth1 = division(achievements.achievementPassBalance, targets.metaPaseRec);
    const passSummary = (targets.passMonth3 + targets.passMonth2 + passMonth1);
    const baseAverage = calculateDivision(passSummary, denominatorDecelerator(targets.passMonth2, targets.passMonth3)) * 100;
    return calculateMultiplier(baseAverage);
  }

  calculateComplianceComercial(complianceData: ComplianceData): number {
    return (complianceData.complianceNetDisbursement * 0.2 +
      complianceData.complianceActiveClientStock * 0.2 +
      complianceData.complianceLiabilitiesCapture * 0.1 +
      complianceData.compliancePriceManagement * 0.1);
  }

  calculateComplianceCobranza(complianceData: ComplianceData): number {
    return (complianceData.compliancePise9Mora * 0.2 +
      complianceData.complianceResolution0Days * 0.2);
  }

  updateCompliance(complianceData: ComplianceData): void {
    this.adnSimulatorService.setMetrics({
      complianceNetDisbursement: { compliance: complianceData.complianceNetDisbursement, weight: 0.2 },
      complianceActiveClientStock: { compliance: complianceData.complianceActiveClientStock, weight: 0.2 },
      complianceLiabilitiesCapture: { compliance: complianceData.complianceLiabilitiesCapture, weight: 0.1 },
      compliancePriceManagement: { compliance: complianceData.compliancePriceManagement, weight: 0.1 },
      compliancePise9Mora: { compliance: complianceData.compliancePise9Mora, weight: 0.2 },
      complianceResolution0Days: { compliance: complianceData.complianceResolution0Days, weight: 0.2 },
    });
  }


  private convertAndCalculateComplianceNeto(achievements: any, targets: any): number {
    const netDisbursement = convertToNumeric(achievements.netDisbursement);
    const minDisbursement = convertToNumeric(targets.minDisbursement);
    const disbursement100 = convertToNumeric(targets.disbursement100);
    const disbursement150 = convertToNumeric(targets.disbursement150);
    const disbursement200 = convertToNumeric(targets.disbursement200);
    return calculateCompliance(netDisbursement, minDisbursement, disbursement100, disbursement150, disbursement200);
  }
  private convertAndCalculateComplianceStok(achievements: any, targets: any): number {
    const stockClients = convertToNumeric(achievements.stockClients);
    const minClients = convertToNumeric(targets.minClients);
    const clients100 = convertToNumeric(targets.clients100);
    const clients150 = convertToNumeric(targets.clients150);
    const clients200 = convertToNumeric(targets.clients200);
    return calculateComplianceStok(stockClients, minClients, clients100, clients150, clients200);
  }

  private convertAndCalculateCompliancePasivos(achievements: any, targets: any): number {
    const openedPasAmount = convertToNumeric(achievements.openedPasAmount);
    const minLiabilities = convertToNumeric(targets.minLiabilities);
    const liabilities100 = convertToNumeric(targets.liabilities100);
    const liabilities150 = convertToNumeric(targets.liabilities150);
    const liabilities200 = convertToNumeric(targets.liabilities200);
    const liabilities300 = convertToNumeric(targets.liabilities300);
    const liabilities400 = convertToNumeric(targets.liabilities400);
    return calculateLiabilitiesManagementCompliance(openedPasAmount, minLiabilities, liabilities100, liabilities150, liabilities200, liabilities300, liabilities400);
  }
  private calculatePriceManagement(targets: AdnTarget, gestionPreciosLogro: number): number {
    return calculatePriceManagementCompliance(
      gestionPreciosLogro / 100,
      targets.minPrices, targets.prices100, targets.prices125, targets.prices150);
  }

  private calculatePise9Compliance(targets: AdnTarget, pise9Achievement: number): number {
    return calculateMoraCompliance(pise9Achievement, targets.minPise9, targets.pise9100, targets.pise9125, targets.pise9150);
  }

  private calculateResolutionCompliance(targets: AdnTarget, resolutionAchievement: number): number {
    return calculateResolutionCompliance(resolutionAchievement, targets.minResolution0, targets.resolution0100, targets.resolution0125, targets.resolution0150);
  }
}