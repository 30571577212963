import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthAdnService } from '@shared/services/auth-adn.service';

@Component({
  selector: 'milab-modal-warning-time',
  templateUrl: './modal-warning-time.component.html',
  styleUrls: ['./modal-warning-time.component.scss']
})
export class ModalWarningTimeComponent implements OnInit {
  @Input() logoutTime!: number;
  showModal: boolean = false;
  countdown!: number;
  countdownInterval: any;
  constructor(public activeModal: NgbActiveModal,private authAdnService: AuthAdnService) { }

  ngOnInit(): void {
    this.startCountdown(this.logoutTime);
  }

  startCountdown(logoutTime: number) {
    const now = Date.now();
    let remainingTime = Math.ceil((logoutTime - now) / 1000);
    this.countdown = remainingTime;
    this.countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(this.countdownInterval);
        this.activeModal.close();
        this.authAdnService.adnCloseSessionTimeout();
      }
    }, 1000);
  }
}
