<div class="cumplirme_card">
  <div class="cumplirme_card__title">{{ title }}</div>
  <div class="d-flex">
    <div>
      <div class="cumplirme_card__amount">
        <div class="cumplirme_card__amount-value color-dark">{{ weightedComplianceComercial }}%</div>
        <img
          *ngIf="imgComplianceWeightComercial.length > 0"
          class="cumplirme_card__amount-icon"
          [src]="imgComplianceWeightComercial"
          alt="icon"
        />
      </div>
      <div class="cumplirme_card__increase-wrapper" *ngIf="increaseComplianceComercial.length > 0">
        <milab-increase-card
          [increase]="increaseComplianceComercial"
          [icon]="iconComplianceComercial"
          [color]="complianceColorComercial"
        ></milab-increase-card>
      </div>
    
      <div class="cumplirme_card__row">
        <p class="cumplirme_card__label">Var. Comercial ≥ {{setting.salesRate}}%</p>
        <div class="cumplirme_card__value">
          <div class="color-dark">S/{{ directRemunerationValueComercial | amountNumericFormatter }}</div>
          <div class="increase-distributed" *ngIf="increaseDistributedComercial.length > 0">
            <milab-increase-card 
              [increase]="increaseDistributedComercial"
              [icon]="iconDistributedeComercial"
              [color]="distributedColorComercial">
            </milab-increase-card>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="divider"></div>
      <div>
        <div class="cumplirme_card__amount">
          <div class="cumplirme_card__amount-value color-dark">{{ weightedComplianceCobranza }}%</div>
          <img
            *ngIf="imgComplianceWeightCobranza.length > 0"
            class="cumplirme_card__amount-icon"
            [src]="imgComplianceWeightCobranza"
            alt="icon"
          />
        </div>
        <div class="cumplirme_card__increase-wrapper" *ngIf="increaseComplianceCobranza.length > 0">
          <milab-increase-card
            [increase]="increaseComplianceCobranza"
            [icon]="iconComplianceCobranza"
            [color]="complianceColorCobranza"
          ></milab-increase-card>
        </div>
      
        <div class="cumplirme_card__row">
          <p class="cumplirme_card__label">Var. Cobranza ≥ {{setting.collectionRate}}%</p>
          <div class="cumplirme_card__value">
            <div class="color-dark">S/{{ directRemunerationValueCobranza | amountNumericFormatter }}</div>
            <div class="increase-distributed" *ngIf="increaseDistributedCobranza.length > 0">
              <milab-increase-card 
                [increase]="increaseDistributedCobranza"
                [icon]="iconDistributedeCobranza"
                [color]="distributedColorCobranza">
              </milab-increase-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
