import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RV_DATALAYER } from '@shared/constants/adn-simulator.constants';
import { ITabItem } from '@shared/interfaces';
import { DataLayerService } from '@shared/services/data-layer.service';

@Component({
  selector: 'milab-simulator-tabs',
  templateUrl: './simulator-tabs.component.html',
  styleUrls: ['./simulator-tabs.component.scss']
})
export class SimulatorTabsComponent implements OnInit {
  @Input() items: ITabItem[] = [];
  @Input() selectedItem!: ITabItem;
  @Output() selectedItemEmit = new EventEmitter<any>();
  constructor(private dataLayerService: DataLayerService) { }

  ngOnInit(): void {
    this.resetTabs();
  }

  ngOnDestroy(): void {
    this.resetTabs();
    this.selectedItemEmit.emit(this.selectedItem);
  }

  selectTab(tab: ITabItem): void {
    this.selectedItem = tab;
    this.setTabActive(this.selectedItem);
    this.dataLayerService.simulatorRVSelect(RV_DATALAYER.actionFilterPrincipal,RV_DATALAYER.selectFilterName+tab.label);
    this.selectedItemEmit.emit(this.selectedItem);
  }

  resetTabs() {
    this.selectedItem = this.items[0];
    this.setTabActive(this.selectedItem);
  }

  setTabActive(activeTab: ITabItem): void {
    this.items.forEach((x) => {
      x.active = x.label == activeTab.label;
    });
  }

}
